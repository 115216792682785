<script lang="ts" setup>
import { ref, Ref } from 'vue'
import { useDialogPluginComponent, Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseDialog from '@/components/base/BaseDialog.vue'
import { Project } from '@/models/projects/Project'
import ProjectGeneralParameters from './ProjectGeneralParameters.vue'
import ProjectDocumentsParameters from './ProjectDocumentsParameters.vue'
import SubProjectsParameters from './SubProjectsParameters.vue'
import PermissionsParameters from './PermissionsParameters.vue'

interface Props {
  project: Project
  tab?: string
}
const props = withDefaults(defineProps<Props>(), {
  tab: 'general',
})

const userApi: UserApi = useApi()
const isLoading: Ref<boolean> = ref(false)

const emit = defineEmits([...useDialogPluginComponent.emits])
const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const tab = ref(props.tab)
const splitterModel = ref(20)
</script>

<template>
  <q-dialog ref="dialogRef">
    <BaseDialog :is-full-content="true" class="" :show-cancel-button="false" title="Paramètres du dossier"
      :is-dialog-loading="isLoading" @on-dialog-cancel="onDialogCancel()" @hide="onDialogHide()">
      <!-- full-width full-height -->
      <template #full-content>
        <div>
          <q-splitter v-model="splitterModel" style="height: 100%">
            <template v-slot:before>
              <q-tabs v-model="tab" vertical no-caps active-color="primary">
                <q-tab class="tab-header" name="general" label="Général" />
                <q-tab class="tab-header" name="documents" label="Documents" />
                <q-tab class="tab-header" name="sub_projects" label="Projets juridiques" />
                <!-- <q-tab class="tab-header" name="permissions" label="Permissions" /> -->
              </q-tabs>
            </template>

            <template v-slot:after>
              <q-tab-panels v-model="tab" animated swipeable vertical transition-prev="jump-up" transition-next="jump-up">
                <q-tab-panel name="general">
                  <ProjectGeneralParameters :project="props.project" />
                </q-tab-panel>

                <q-tab-panel name="documents">
                  <ProjectDocumentsParameters :project="props.project" />
                </q-tab-panel>

                <q-tab-panel name="sub_projects">
                  <SubProjectsParameters :project="props.project" />
                </q-tab-panel>

                <q-tab-panel name="permissions">
                  <PermissionsParameters :project="props.project" />
                </q-tab-panel>
              </q-tab-panels>
            </template>
          </q-splitter>
        </div>
      </template>
      <template #actions> </template>
    </BaseDialog>
  </q-dialog>
</template>

<style lang="scss" scoped>
.tab-header {
  justify-content: left;
  padding-left: 16px;
  padding-right: 16px;
}
</style>
