<script setup lang="ts">
import { ref, Ref } from 'vue'
import { useDialogPluginComponent, Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import BaseDialog from '@/components/base/BaseDialog.vue'
import BaseInput from '@/components/base/BaseInput.vue'

interface Props {
  title: string
  buttonConfirmLabel: string
  mode: string
  clientId?: string | undefined
  selectedUser?: any
}
const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const userApi = useApi()

const email = ref('')
const emailTemp = ref('')
const password = ref('')
const firstName = ref('')
const lastName = ref('')

const clientId = ref(props.clientId)

const isLoading: Ref<boolean> = ref(false)

const creationModeOptions = [
  {
    label: "Envoi d'une invitation par mail",
    value: 'INVITATION',
  },
  {
    label: "Création d'un mot de passe",
    value: 'PASSWORD',
  },
]

const creationMode = ref(creationModeOptions[0])

function beforeShow() {
  if (props.mode == 'ADD') {
    email.value = ''
    password.value = ''
    firstName.value = ''
    lastName.value = ''
  } else {
    if (props.selectedUser === undefined) {
      return
    }
    email.value = props.selectedUser.email
    password.value = props.selectedUser.password
    firstName.value = props.selectedUser.firstName
    lastName.value = props.selectedUser.lastName
  }
}

function updateUser() {
  isLoading.value = true
  const selectedItem = props.selectedUser
  userApi
    .updatePortalUser(
      selectedItem.id,
      {
        username: email.value,
        first_name: firstName.value,
        last_name: lastName.value,
        email: email.value,
      },
      clientId.value,
    )
    .then((result) => {
      isLoading.value = false
      emit('ok')
    })
}

function onConfirmClick() {
  updateUser()
}
</script>
<template>
  <q-dialog ref="dialogRef" @before-show="beforeShow">
    <BaseDialog :title="title" :dialog-width="500" :is-dialog-loading="isLoading" @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()">
      <template #body>
        <BaseInput v-model="email" label="Email *" lazy-rules
          :rules="[(val) => (val && val.length > 0) || 'Le nom de l\'utilisateur est requis']">
        </BaseInput>
        <BaseInput v-model="firstName" label="Prénom *" lazy-rules
          :rules="[(val) => (val && val.length > 0) || 'Le prénom est requis']">
        </BaseInput>
        <BaseInput v-model="lastName" label="Nom *" lazy-rules
          :rules="[(val) => (val && val.length > 0) || 'Le nom est requis']">
        </BaseInput>
      </template>
      <template #actions>
        <q-btn flat :label="buttonConfirmLabel" v-close-popup @click="onConfirmClick" color="primary" />
      </template>
    </BaseDialog>
  </q-dialog>
</template>
