<script async lang="ts" setup>
import { inject, ref, watch } from 'vue'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { store } from '@/store/store'

import { Database } from '@/models/database/Database'

interface Props {
  database: Database
  node: Node
  // field: Field;
}

const props = withDefaults(defineProps<Props>(), {})

const userApi: UserApi = useApi()

console.log('store', store.state)

const treeRef = inject('treeRef')

let gdprProjectId = ref(undefined)

const rows = ref([])

const infractionColumns = [
  {
    name: 'date1',
    required: true,
    label: "Date de survenance de l'incident de sécurité à l'origine de la violation",
    align: 'left',
    field: 'date1',
    sortable: true,
    headerClasses: 'shrinkable-header',
  },
  {
    name: 'date2',
    align: 'left',
    label: "Date à laquelle l'organisation a eu connaissance de la violation",
    field: 'date2',
    sortable: true,
    headerClasses: 'shrinkable-header',
  },
  {
    name: 'text',
    align: 'left',
    label: 'Nature de la violation',
    field: 'text',
    sortable: true,
    headerClasses: 'shrinkable-header',
  },
  {
    name: 'taskLink',
    align: 'left',
    label: 'Tâche',
    field: 'taskLink',
    sortable: true,
  },
  {
    name: 'surveySummaryLink',
    align: 'left',
    label: 'Fiche',
    field: 'surveySummaryLink',
    sortable: true,
  },
]

let children = []
const loading = ref(false)

async function update() {
  console.log('props.node', props.node)
  console.log('treeRef', treeRef)
  const parentNode = treeRef.value.getNodeByKey(props.node.parentId)
  console.log('parentNode', parentNode)
  if (parentNode != null) {
    if (parentNode.fields.length > 0) {
      gdprProjectId.value = parentNode.fields[0].value
    }
  } else {
    console.log('parent node not found')
  }

  if (gdprProjectId.value == null || gdprProjectId.value == undefined) {
    return
  }

  loading.value = true

  let requests = undefined
  children = []

  try {
    requests = await userApi.getViolationRegister(gdprProjectId.value)
  } catch (error) {
    console.log(error)
    // gdprProjectId.value = undefined;
    loading.value = false
    rows.value = []
    return
  }

  requests.items.forEach((request) => {
    console.log('request', request)
    children.push({
      fields: [
        {
          name: 'date_1',
          value: request.date1,
        },
        {
          name: 'date_2',
          value: request.date2,
        },
        {
          name: 'text',
          value: request.text.value,
        },
        {
          name: 'taskLink',
          value: request.taskLink,
        },
        {
          name: 'surveySummaryLink',
          value: request.surveySummaryLink,
        },
      ],
    })
  })

  rows.value = []
  var id = 0
  children?.forEach((child) => {
    rows.value.push({
      id: id++,
      date1: child.fields[0].value,
      date2: child.fields[1].value,
      text: child.fields[2].value,
      taskLink: child.fields[3].value,
      surveySummaryLink: child.fields[4].value,
    })
  })

  loading.value = false
}

const pagination = ref({
  rowsPerPage: 0,
})

watch(
  () => props.node,
  () => {
    update()
  },
)

update()
</script>

<template>
  <suspense>
    <q-card flat bordered>
      <q-card-section class="row">
        <span class="text-h6">RGPD - Registre des violations</span>
        <q-space />
        <q-icon name="update" size="sm" class="cursor-pointer" clickable @click="update()" />
        &nbsp;&nbsp;&nbsp;
      </q-card-section>

      <q-card-section class="q-pt-none">
        <div v-if="gdprProjectId == null || gdprProjectId == undefined">
          Aucun projet juridique sélectionné dans le noeuds "Registres RGPD"
        </div>
        <q-table
          v-if="gdprProjectId != null && gdprProjectId != undefined"
          :rows="rows"
          :columns="infractionColumns"
          row-key="id"
          :loading="loading"
          :pagination="pagination"
          :rows-per-page-options="[0]"
        >
          <template v-slot:body-cell-text="props">
            <q-td :props="props">
              <div style="overflow-wrap: normal; word-break: normal; white-space: break-spaces">
                {{ props.row.text }}
              </div>
            </q-td>
          </template>
          <template v-slot:body-cell-taskLink="props">
            <q-td :props="props">
              <div>
                <a :href="props.row.taskLink" target="_blank">Lien</a>
              </div>
            </q-td>
          </template>
          <template v-slot:body-cell-surveySummaryLink="props">
            <q-td :props="props">
              <div>
                <a :href="props.row.surveySummaryLink" target="_blank">Lien</a>
              </div>
            </q-td>
          </template>
          <template v-slot:loading>
            <q-inner-loading showing color="primary" />
          </template>
        </q-table>
      </q-card-section>
    </q-card>

    <template #fallback> loading... </template>
  </suspense>
</template>

<style lang="scss" scoped>
:deep(.shrinkable-header) {
  overflow-wrap: normal;
  word-break: normal;
  white-space: break-spaces;
}
</style>
