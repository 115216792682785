<script setup lang="ts">
import { useRoute } from 'vue-router'
import { ref, inject, watch } from 'vue'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { Task } from '@/models/projects/Task'
import LogItem from './LogItem.vue'
import Editor from '@tinymce/tinymce-vue'
import { ProjectAccountableOptionsKey, ProjectKey } from '@/models/symbols'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import { UserAccountableOptions } from '@/models/projects/UserAccountableOptions'
import AddUserToProjectDialog from './AddUserToProjectDialog.vue'

import { Dialog, Notify, useQuasar } from 'quasar'


interface Props {
  task: Task | undefined
  isDisable?: boolean
  isReadonly?: boolean
  updateNb: any
}

const props = withDefaults(defineProps<Props>(), {
  isDisable: false,
  isReadonly: false,
})

const route = useRoute()
const userApi: UserApi = useApi()

const project = inject(ProjectKey)
const documentId = inject('documentId')
// const taskDetailsSelected = inject("taskDetailsSelected");

const authenticatedUser = useAuthenticatedUser()
const accountableOptions = inject(ProjectAccountableOptionsKey)
const users: Ref<Array<any>> = ref([])

const isLoading = ref(false)
const events = ref([])
const showAllProjectEvents = ref(false)
const addTaskLinkToMessage = ref(true)
const newChatMessage = ref('')

async function onChange(event) {
  console.log('onChange', props.task, documentId.value)

  await updateUsers()

  try {
    isLoading.value = true
    const eventsResponse = await userApi.getProjectEvents(project?.value.id, props.task?.id, documentId.value)
    console.log("eventsResponse", eventsResponse.results)

    if (!showAllProjectEvents.value) {
      // events.value = eventsResponse.results.filter(event => event.legacyTaskId.toString() == props.task?.id.toString());
      if (props.task?.id == undefined && documentId.value == undefined) {
        events.value = filterMessages(eventsResponse.results)
      } else if (documentId.value != undefined) {
        const newEvents = []
        eventsResponse.results.forEach((event) => {
          if (event.documentTreeNodeId == documentId.value) {
            newEvents.push(event)
          }
        })
        events.value = filterMessages(newEvents)
      } else {
        const newEvents = []
        eventsResponse.results.forEach((event) => {
          if (event.legacyTaskId == props.task?.id) {
            newEvents.push(event)
          }
        })
        events.value = filterMessages(newEvents)
      }
    } else {
      events.value = filterMessages(eventsResponse.results)
    }

    isLoading.value = false
  } catch (error) {
    isLoading.value = false
  }
}

watch(
  () => props.updateNb,
  () => {
    onChange(undefined)
  },
)

watch(
  () => props.task,
  () => {
    onChange(undefined)
  },
)

watch(
  () => documentId.value,
  () => {
    onChange(undefined)
  },
)

watch(
  () => showAllProjectEvents.value,
  () => {
    onChange(undefined)
  },
)

watch(
  () => accountableOptions.value,
  () => {
    updateUsers()
  },
)

onChange(undefined)




async function updateUsers() {
  console.log('LogPanel: updateUsers')
  users.value = await Promise.all(
    accountableOptions?.value.map(async (user) => {
      const notificationsEnabled = await userApi.getProjectNotification(project?.value.id, user.id)

      const userData = {
        id: user.id,
        fullName: user.fullName,
        notificationsEnabled,
        send: true,
      }

      if (authenticatedUser.id === user.id) {
        userData.send = false
      }

      return userData
    }),
  )

  // Set authenticated user on top of the list
  users.value = users.value.sort((a, b) => {
    if (a.id === authenticatedUser.id) {
      return -1
    }
    if (b.id === authenticatedUser.id) {
      return 1
    }
    return 0
  })

  console.log('LogPanel: updated users', users.value)
}

await updateUsers()



async function sendChatMessage() {
  const recipients = []
  users.value.forEach((user) => {
    if (user.send) {
      recipients.push(user.id)
    }
  })

  await userApi.createProjectEvent(project?.value.id, {
    type: 5,
    user_project_id: project?.value.id,
    legacy_task_id: props.task?.id,
    document_tree_node_id: documentId.value,
    chat_message_content: newChatMessage.value,
    recipients,
  })

  await userApi.sendNewChatMessageEmail(
    project?.value.id,
    props.task?.id,
    documentId.value,
    recipients,
    newChatMessage.value,
    addTaskLinkToMessage,
  )
  newChatMessage.value = ''
  onChange(undefined)
}

function selectAllRecipients() {
  users.value.forEach((user) => {
    user.send = true
  })
}

function deselectAllRecipients() {
  users.value.forEach((user) => {
    user.send = false
  })
}

function addUser() {
  console.log('addUser')
  const dialog = Dialog.create({
    component: AddUserToProjectDialog,
    componentProps: {
      project: project,
    },
  })
    .onOk(() => {
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

const discussionElementsToShowOptions = [
  { label: 'Tous les messages de la tâche ou du document', value: 'all' },
  { label: 'Boîte de réception', value: 'inbox' },
  { label: 'Boîte d\'envoi', value: 'outbox' },
]

const discussionElementsToShow = ref(discussionElementsToShowOptions[0])

function filterMessages(events) {
  console.log("authenticatedUser", authenticatedUser)
  return events.filter((event) => {

    if (authenticatedUser.subscriptionModules.length == 0) {
      console.log("authenticatedUser.subscriptionModules == []", authenticatedUser)
      let isUserInRecipients = false
      event.recipients.forEach((recipient) => {
        if (recipient.recipient.id == authenticatedUser.id) {
          isUserInRecipients = true
        }
      })
      if (!isUserInRecipients && event.user.id != authenticatedUser.id) {
        return false
      }
    }

    if (discussionElementsToShow.value.value == 'all') {
      return true
    } else if (discussionElementsToShow.value.value == 'inbox') {
      let result = false
      event.recipients.forEach((recipient) => {
        if (recipient.recipient.id == authenticatedUser.id) {
          result = true
        }
      })
      return result
    } else if (discussionElementsToShow.value.value == 'outbox') {
      return event.user.id == authenticatedUser.id
    }
  })
}


watch(discussionElementsToShow, () => {
  onChange(undefined)
})
</script>

<template>
  <div class="" style="">
    <q-toggle dense v-model="showAllProjectEvents" label="Voir tous les messages"
      style="margin-top: 8px; margin-bottom: 8px" class="col" />

    <q-select dense outlined v-model="discussionElementsToShow" :options="discussionElementsToShowOptions"
      style="margin-bottom: 8px;"></q-select>

    <div>Destinataires :</div>
    <div class="row">
      <div class="col">
        <q-list dense style="margin-top: 0px; margin-bottom: 20px">
          <q-item dense clickable v-ripple v-for="recipient in users">
            <q-item-section dense>
              <q-checkbox dense :label="recipient.fullName" v-model="recipient.send"></q-checkbox>
            </q-item-section>
          </q-item>
        </q-list>
      </div>
      <div class="col">
        <!-- <q-btn dense label="Sélectionner tout" style="width: 150px;" @click="selectAllRecipients"></q-btn><br /> -->
        <!-- <q-btn dense label="Désélectionner tout" style="width: 150px; margin-top: 10px; margin-bottom: 10px;"
          @click="deselectAllRecipients"></q-btn> -->
        <a href="#" class="button-link" @click="selectAllRecipients">Sélectionner tout</a>
        <br />
        <a href="#" class="button-link" @click="deselectAllRecipients">Désélectionner tout</a>
        <!-- <br />
        <a href="#" class="button-link" @click="addUser">Ajouter un utilisateur</a> -->
      </div>
    </div>

    <editor api-key="2kfvzi8jvomz43fi5wztrp79sp6j2odq3i3lud6boywjbq44" :init="{
      height: 250,
      menubar: false,

      statusbar: true,
      elementpath: false,
      branding: false,
      resize: true,

      plugins: 'lists advlist link searchreplace',
      toolbar: 'undo redo | bold italic underline | align | bullist numlist | removeformat',
    }" v-model="newChatMessage" />

    <div class="row no-wrap items-center" style="margin-top: 8px">
      <q-btn dense rounded color="primary" style="
          height: 24px;
          min-height: 24px;
          padding-left: 12px;
          padding-right: 12px;
          padding-top: 0px;
          margin-right: 8px;
        " label="Envoyer" @click="sendChatMessage()"></q-btn>
      <q-toggle dense v-model="addTaskLinkToMessage" label="Inclure un lien vers la discussion"
        style="margin-bottom: 0px" />
    </div>

    <div v-if="isLoading">
      <q-inner-loading showing color="primary" />
    </div>
    <q-list dense separator style="margin-top: 25px">
      <LogItem v-for="event in events" :event="event" />
    </q-list>
  </div>
</template>

<style lang="scss" scoped></style>
