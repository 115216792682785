<script lang="ts" setup>
import { ref, inject } from 'vue'
import { Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { ProjectKey } from '@/models/symbols'

import { refreshFiles, getNode, canMoveTo, moveNode, refreshFlatTree, } from './DocumentsCommon'

interface Props {
  element: any
}
const props = withDefaults(defineProps<Props>(), {})

const userApi: UserApi = useApi()

const project = inject(ProjectKey)
const ticked = inject('ticked')
const documentTreeNodes = inject('documentTreeNodes')
const expandedNodes = inject('expandedNodes')
const viewType = inject('viewType')
const flatViewNodes = inject('flatViewNodes')
const currentDocumentTreeNodes = inject('currentDocumentTreeNodes')

const dropZone = ref(null)

async function onDrop(evt) {
  console.log('BetweenNodeDropZone - onDrop')
  dropZone.value.classList.remove('between-node-drop-zone-hover')

  console.log('viewType', viewType)
  if (viewType.value.value == 'flat') {
    console.log('drop in flat view is not authorized')
    Notify.create({
      message: "Le glisser-déposer de documents n'est pas autorisé dans la vue à plat",
      type: 'negative',
    })
    return
  }

  const itemId = evt.dataTransfer.getData('itemId')
  const item = getNode(documentTreeNodes.value, itemId)

  console.log('dropping', itemId, ticked.value)

  let destination
  if (props.element.type == 0) {
    destination = undefined
  } else if (props.element.type == 1) {
    destination = props.element.id
  } else if (props.element.type == 2) {
    destination = props.element.parentId
  }
  // TODO: manage fileversion nodes

  if (!canMoveTo(item, getNode(documentTreeNodes.value, destination))) {
    return
  }

  console.log('can move')

  if (ticked.value.includes(itemId)) {
    console.log('dropping multiple files')
    await userApi.moveDocumentTreeNodes(project?.value.id, ticked.value, destination)
    refreshFiles(userApi, project, documentTreeNodes)
  } else {
    console.log('dropping single file')
    await moveNode(project, userApi, documentTreeNodes, item, destination, expandedNodes)
  }

  refreshFlatTree(documentTreeNodes, flatViewNodes, viewType, currentDocumentTreeNodes)

  ticked.value = []
}

function onDragEnterRootDropZone(event) {
  // console.log("onDragEnterRootDropZone", event);
  event.preventDefault()
  dropZone.value.classList.add('between-node-drop-zone-hover')
}

function onDragLeaveRootDropZone(event) {
  // console.log("onDragLeaveRootDropZone", event);
  dropZone.value.classList.remove('between-node-drop-zone-hover')
}
</script>

<template>
  <!-- need to prevent default on dragenter and dragover to allow drop -->
  <div class="drop-zone" @drop="onDrop" @dragenter="onDragEnterRootDropZone" @dragleave="onDragLeaveRootDropZone"
    @dragover.prevent ref="dropZone">
    <!-- &nbsp; -->
  </div>
</template>

<style lang="scss" scoped>
.drop-zone {
  height: 8px;
  // background-color: red;
}

.drop-zone-hover {
  background-color: grey;
}

.between-node-drop-zone-hover {
  background-color: grey;
}
</style>
