export const platformPermissionGroups = [
  {
    name: 'platform_project_catalog',
    label: 'Catalogue de projets juridiques',
    permissions: [
      {
        name: 'manage_platformprojectcatalog',
        label: 'Gérer',
        description: 'Gérer le catalogue de projets juridiques',
      },
    ],
  },

  {
    name: 'platform_basic_models_catalog',
    label: 'Catalogue de modèles juridiques',
    permissions: [
      {
        name: 'manage_environmentdocumentcatalog',
        label: 'Gérer',
        description: 'Gérer le catalogue de modèles juridiques',
      },
    ],
  },

  {
    name: 'database',
    label: 'Dossiers permanents',
    permissions: [
      {
        name: 'manage_database_entities',
        label: 'Gérer',
        description: 'Gérer les entités des dossiers permanents',
      },
    ],
  },

  {
    name: 'environments',
    label: 'Marques blanches',
    permissions: [
      {
        name: 'manage_environments',
        label: 'Gérer',
        description: 'Gérer les marques blanches',
      },
    ],
  },

  {
    name: 'platform_basic_models_catalog',
    label: 'Impersonate',
    permissions: [
      {
        name: 'impersonate_users',
        label: 'Prendre l\'identité d\'un utilisateur',
        description: 'Prendre l\'identité d\'un utilisateur',
      },
    ],
  },

]
