<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import { Notify, Dialog, useQuasar } from 'quasar'
import { ref, Ref, provide, onMounted, watch, inject } from 'vue'
import { ProjectKey, ProjectAccountableOptionsKey } from '@/models/symbols'
import { useStore } from '@/store/store'
import { Task } from '@/models/projects/Task'
import { TaskFilters } from '@/models/projects/TaskFilters'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import { TaskTypeEnum } from '@/models/projects/TaskType.enum.ts'
import { UserAccountableOptions } from '@/models/projects/UserAccountableOptions'
import ProjectTaskProgression from '../ProjectTaskProgression.vue'
import ProjectAssigneeSelector from '../ProjectAssigneeSelector.vue'
import ProjectDeadlineInput from '../ProjectDeadlineInput.vue'
import SubProjectParametersDialog from './SubProjectParametersDialog.vue'
import SubProjectParametersExportDialog from './SubProjectParametersExportDialog.vue'
import SurveyDocumentGenerationDialog from '@/components/survey/dialog/SurveyDocumentGenerationDialog.vue'
import TaskLine from '../TaskLine.vue'
import AddSubProjectTreeDialog from '@/components/projects/dialog/project_parameters/AddSubProjectsTreeDialog.vue'

interface Props {
  projectId: string
  inComposite?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  inComposite: false,
})

const $q = useQuasar()

const taskId = inject('taskId')

const emit = defineEmits(['subProjectTaskModified'])

const store = useStore()
const route = useRoute()
const router = useRouter()
const userApi: UserApi = useApi()

const project = ref({
  // From API
  name: '',
  sections: [],
  legacyId: undefined,

  // Foom API unused fields
  id: undefined,
  pkid: undefined,

  createdAt: undefined,
  updatedAt: undefined,
  subProjects: [],

  // Added in front
  isReadOnly: false,
})
const projectNodes: Ref<Array<any>> = ref([])
const treeRef = ref(null)

const filters: Ref<TaskFilters> = ref({
  hasFiles: false,
  isDeadlineOnly: false,
  showInformationTasks: false,
  assignees: [],
  status: [],
})
const filter = ref('')

const globalFilters = inject('globalFilters')
watch(globalFilters.value, () => {
  filters.value = globalFilters.value
})

const usersFilter: Ref<Array<UserAccountableOptions>> = ref([])
const progressionsFilter: Ref<Array<any>> = ref([])

const collapseTrees = inject('collapseTrees')

const authenticatedUser = useAuthenticatedUser()

const completeProject = inject(ProjectKey)

const subProjectPersonalModels = inject("subProjectPersonalModels")

completeProject.value.subProjects.forEach((subProject) => {
  if (subProject.id === props.projectId) {
    console.log("copying project", subProject)
    project.value.id = subProject.id
    project.value.name = subProject.name
    project.value.sections = subProject.sections
    project.value.legacyId = subProject.legacyId
    project.value.isPersonalModel = subProjectPersonalModels.some((model) => model.legacyProjectId === subProject.legacyId)
    if (subProject.legacyProject) {
      project.value.composite = subProject.legacyProject.composite
      project.value.composable = subProject.legacyProject.composable
      project.value.composableProjectId = subProject.legacyProject.composableProjectId
    }
    project.value.subProjects = subProject.subProjects
    project.value.disabled = subProject.disabled
  }
})

project.value.isReadOnly = false

console.log("subproject", project.value)

// Set read only for ayming users
if (authenticatedUser.email.includes('ayming')) {
  project.value.isReadOnly = true
}

function isSectionHidden(section) {
  return !section.enabled
}

function isTaskHidden(task) {
  return !task.enabled
}

function isTaskReadOnly(task) {
  return !task.enabled
}

const taskDetailsSelected = inject('taskDetailsSelected')

const tasksHashMap = {}
const sectionsHashMap = {}

createSectionsAndTasksHashMaps()

if (taskId.value) {
  onTaskSelected(taskId.value.toString())
}

watch(
  () => taskId.value,
  () => {
    console.log('subrpoject - taskId changed')
    if (taskId.value == undefined) {
      taskDetailsSelected.value = undefined
      onTaskSelected(null)
    } else {
      onTaskSelected(taskId.value.toString())
    }
  },
)

async function onTaskSelected(taskId: string) {
  console.log('onTaskSelected', taskId)
  const task = findTask(taskId)

  if (task === undefined) {
    return
  }

  const newRouteQuery = {
    taskId: task?.id,
  }

  if (route.query.lateralTab != undefined) {
    newRouteQuery.lateralTab = route.query.lateralTab
  }

  taskDetailsSelected.value = task
  if (store.state.user.portal === 'CLIENT') {
    router.push({
      name: 'projects/project',
      params: {
        projectId: route.params.projectId.toString(),
      },
      query: newRouteQuery,
    })
  } else {
    router.push({
      name: 'clients/client/projects/project',
      params: {
        clientId: route.params.clientId.toString(),
        projectId: route.params.projectId.toString(),
      },
      query: newRouteQuery,
    })
  }
}

const tasksPermissions = await userApi.getTasksPermissionsForUser(Object.keys(tasksHashMap), authenticatedUser.id)

const sectionsPermissions = await userApi.getSectionsPermissionsForUser(
  Object.keys(sectionsHashMap),
  authenticatedUser.id,
)

addPermissionsToProject()

let legacyProjectPermissions = {
  permissions: [],
}
console.log("subProject - getting legacyProjectPermissions", project.value.id)
if (project.value.id.length === 36) {
  legacyProjectPermissions = await userApi.getObjectPermissions(project.value.id, "UserProjectLegacyProject", authenticatedUser.id)
}
console.log("legacyProjectPermissions", legacyProjectPermissions)

await createTree()

// console.log("project initialized", project.value)

function createSectionsAndTasksHashMaps() {
  project.value.sections.forEach((section, sectionKey) => {
    sectionsHashMap[section.id] = section

    section?.sections?.forEach((childSection, childSectionKey) => {
      sectionsHashMap[childSection.id] = childSection
      childSection.tasks.forEach((childTask, childTaskKey) => {
        tasksHashMap[childTask.id] = childTask
        childTask.project = project.value
      })
    })

    section.tasks.forEach((task, taskKey) => {
      tasksHashMap[task.id] = task
      task.project = project.value
    })
  })
}

function addPermissionsToProject() {
  sectionsPermissions.results.forEach((sectionPermissions) => {
    const section = sectionsHashMap[sectionPermissions.sectionId]
    section.permissions = sectionPermissions.permissions
  })
  tasksPermissions.results.forEach((taskPermissions) => {
    const task = tasksHashMap[taskPermissions.taskId]
    task.permissions = taskPermissions.permissions
  })
}

function getTaskPermissions(tasksPermissions, taskId) {
  const permissions = tasksPermissions.permissions.filter((permission) => {
    return permission.objectId === taskId
  })
  if (permissions.length > 0) {
    return permissions[0].permissions
  }
  return null
}

async function createTree() {
  for (let i = 0; i < project.value.sections.length; i++) {
    const section = project.value.sections[i]
    const children: any[] = []
    section?.sections?.forEach((childSection, childSectionKey) => {
      let child: any = {
        label: `${childSection.name}`,
        header: 'section',
        disabled: !childSection.enabled,
        children: [],
        object: childSection,
        show: true,
      }
      childSection.tasks.sort((a, b) => (a.order < b.order ? -1 : 1))
      childSection.tasks.forEach((childTask, childTaskKey) => {
        if (!isTaskHidden(childTask)) {
          // console.log("adding childTask", childTask)
          child.children.push({
            label: `${childTask.name}`,
            header: 'task',
            nodeKey: childTask.id,
            disabled: !childTask.enabled,
            object: childTask,
            icon: getIcon(childTask),
            readOnly: isTaskReadOnly(childTask),
            show: true,
          })
        }
      })
      console.log("subsection", !isSectionHidden(childSection), childSection, child, child.children)
      if (!isSectionHidden(childSection) && child.children.length > 0) {
        children.push(child)
      }
    })

    section.tasks.sort((a, b) => (a.order < b.order ? -1 : 1))

    const taskIds = section.tasks.map((task) => task.id)
    const tasksPermissions = await userApi.getObjectsPermissions(taskIds, "LegacyTask", authenticatedUser.id)
    console.log("tasksPermissions", tasksPermissions)

    section.tasks.forEach((task, taskKey) => {
      const taskPermission = getTaskPermissions(tasksPermissions, task.id)

      let taskVisible = true
      if (legacyProjectPermissions.permissions.includes("legacy_project_view_deep_rights")) {
        if (!taskPermission.includes("view_legacytask")) {
          taskVisible = false
        }
      }

      if (!isTaskHidden(task) && taskVisible) {
        children.push({
          label: `${task.name}`,
          header: 'task',
          nodeKey: task.id,
          disabled: !task.enabled,
          icon: getIcon(task),
          object: task,
          readOnly: isTaskReadOnly(task),
          show: true,
        })
      }
    })

    console.log("section", !isSectionHidden(section), section, children)
    if (!isSectionHidden(section) && children.length > 0) {
      projectNodes.value.push({
        label: `${section.name}`,
        header: 'section',
        nodeKey: section.id,
        disabled: !section.enabled,
        children: children,
        object: section,
        show: true,
      })
    }
  }
}

function getIcon(task: Task): string {
  let icon: string = 'task'
  switch (task.type) {
    case TaskTypeEnum.Information:
      icon = 'info_outline'
      break
    case TaskTypeEnum.Form:
      icon = 'list_alt'
      break
    case TaskTypeEnum.DocumentGeneration:
      icon = 'o_description'
      break
    case TaskTypeEnum.DocumentUpload:
      icon = 'upload_file'
      break
    case TaskTypeEnum.DocumentSignature:
      icon = 'drive_file_rename_outline'
      break
    case TaskTypeEnum.OnlinePayment:
      icon = 'payment'
      break
    case TaskTypeEnum.MailSending:
      icon = 'mail_outline'
      break
    case TaskTypeEnum.AppointmentMaking:
      icon = 'date_range'
      break

    default:
      break
  }
  return icon
}

function findTask(taskId: string): Task | undefined {
  let task: Task | undefined
  project.value?.sections.forEach((section) => {
    const taskTemp = section.tasks.find((t) => {
      return t.id === taskId
    })
    if (taskTemp) {
      task = taskTemp
    }
  })

  if (task) {
    return task
  }

  project.value?.sections.forEach((section) => {
    section?.sections?.forEach((childSection) => {
      if (!task) {
        task = childSection.tasks.find((t) => {
          return t.id === taskId
        })
      }
    })
  })

  if (task) {
    return task
  }

  return undefined
}

function findSection(sectionId: string): Section | undefined {
  let section: Section | undefined
  project.value?.sections.forEach((sectionTemp) => {
    if (sectionTemp.id === sectionId) {
      section = sectionTemp
    }
  })

  if (section) {
    return section
  }

  project.value?.sections.forEach((sectionTemp) => {
    sectionTemp?.sections?.forEach((childSection) => {
      if (childSection.id === sectionId) {
        section = childSection
      }
    })
  })

  return section
}

function isActive(taskId: string): boolean {
  return taskDetailsSelected.value?.id === taskId
}

watch(collapseTrees, () => {
  onToggleCollapse(collapseTrees.value)
})

function onToggleCollapse(collapse) {
  collapse ? treeRef.value.collapseAll() : treeRef.value.expandAll()
}

function applyTreeFilter(node, filter) {
  // console.log("applyTreeFilter", node, filter)
  if (node.show === false) {
    return false
  }

  if (!filters.value.showInformationTasks) {
    if (node.header === 'task' && node.object.type === TaskTypeEnum.Information) {
      return false
    }

    if (node.header === 'section') {
      const allChildrenAreInformationTasks = node.children.every((child) => {
        return child.object.type === TaskTypeEnum.Information
      })
      if (allChildrenAreInformationTasks) {
        return false
      }
    }
  }

  if (node.header !== 'task') {
    return true
  }

  if (filters.value.hasFiles && !filters.value.isDeadlineOnly) {
    if (filters.value.assignees.length === 0 && filters.value.status.length === 0) {
      return node.object.hasFiles
    }
    if (filters.value.assignees.length > 0 && filters.value.status.length > 0) {
      return (
        node.object.hasFiles &&
        filters.value.assignees.some((assignee) => assignee === node.object.assignee?.fullName) &&
        filters.value.status.some((status) => status === node.object.status)
      )
    }
    if (filters.value.assignees.length > 0 && filters.value.status.length === 0) {
      return (
        node.object.hasFiles && filters.value.assignees.some((assignee) => assignee === node.object.assignee?.fullName)
      )
    }
    if (filters.value.assignees.length === 0 && filters.value.status.length > 0) {
      return node.object.hasFiles && filters.value.status.some((status) => status === node.object.status)
    }
  }
  if (filters.value.hasFiles && filters.value.isDeadlineOnly) {
    if (filters.value.assignees.length === 0 && filters.value.status.length === 0) {
      return node.object.hasFiles && node.object.deadline !== null
    }
    if (filters.value.assignees.length > 0 && filters.value.status.length > 0) {
      return (
        node.object.hasFiles &&
        node.object.deadline !== null &&
        filters.value.assignees.some((assignee) => assignee === node.object.assignee?.fullName) &&
        filters.value.status.some((status) => status === node.object.status)
      )
    }
    if (filters.value.assignees.length > 0 && filters.value.status.length === 0) {
      return (
        node.object.hasFiles &&
        node.object.deadline !== null &&
        filters.value.assignees.some((assignee) => assignee === node.object.assignee?.fullName)
      )
    }
    if (filters.value.assignees.length === 0 && filters.value.status.length > 0) {
      return (
        node.object.hasFiles &&
        node.object.deadline !== null &&
        filters.value.status.some((status) => status === node.object.status)
      )
    }
  }
  if (!filters.value.hasFiles && filters.value.isDeadlineOnly) {
    if (filters.value.assignees.length === 0 && filters.value.status.length === 0) {
      return node.object.deadline !== null
    }
    if (filters.value.assignees.length > 0 && filters.value.status.length > 0) {
      return (
        node.object.deadline !== null &&
        filters.value.assignees.some((assignee) => assignee === node.object.assignee?.fullName) &&
        filters.value.status.some((status) => status === node.object.status)
      )
    }
    if (filters.value.assignees.length > 0 && filters.value.status.length === 0) {
      return (
        node.object.deadline !== null &&
        filters.value.assignees.some((assignee) => assignee === node.object.assignee?.fullName)
      )
    }
    if (filters.value.assignees.length === 0 && filters.value.status.length > 0) {
      return node.object.deadline !== null && filters.value.status.some((status) => status === node.object.status)
    }
  }

  if (!filters.value.hasFiles && !filters.value.isDeadlineOnly) {
    if (filters.value.assignees.length === 0 && filters.value.status.length === 0) {
      return true
    }
    if (filters.value.assignees.length > 0 && filters.value.status.length > 0) {
      return (
        filters.value.assignees.some((assignee) => assignee === node.object.assignee?.fullName) &&
        filters.value.status.some((status) => status === node.object.status)
      )
    }
    if (filters.value.assignees.length > 0 && filters.value.status.length === 0) {
      return filters.value.assignees.some((assignee) => assignee === node.object.assignee?.fullName)
    }
    if (filters.value.assignees.length === 0 && filters.value.status.length > 0) {
      return filters.value.status.some((status) => status === node.object.status)
    }
  }
}

watch(usersFilter, () => {
  if (usersFilter.value === null) {
    usersFilter.value = []
  }
  if (Array.isArray(usersFilter.value)) {
    filters.value.assignees = usersFilter.value.map((user) => {
      return user.value?.fullName
    })
  }
})

watch(progressionsFilter, () => {
  if (progressionsFilter.value === null) {
    progressionsFilter.value = []
  }
  filters.value.status = progressionsFilter.value.map((progression) => {
    return progression.value
  })
})

watch(filters.value, () => {
  filter.value = Math.random().toString()
})

filter.value = Math.random().toString()

const updateNb = ref(0)

function onUpdateEvents(event) {
  updateNb.value = updateNb.value + 1
  emit('subProjectTaskModified', event)
}

function filterNodesToExport(inputNodes, outputNodes) {
  console.log("inputNodes", inputNodes)
  inputNodes.forEach((node) => {
    console.log("node", node)
    const outputNode = {
      label: node.label,
      children: []
    }
    // outputNode.children = []
    node.children.forEach((task) => {
      console.log("task", task)
      if (task.object.hasFiles) {
        outputNode.children.push({
          id: task.object.id,
          label: task.label,
          type: task.object.type,
        })
      }
    })
    if (outputNode.children.length > 0) {
      outputNodes.push(outputNode)
    }
  })
}

async function exportToDocuments() {
  console.log("exporting to documents")
  // Notify.create({
  //   message: `L'export du projet juridique dans les documents du dossier sera bientôt disponible.`,
  //   type: 'primary',
  // })

  const nodesToExport = []

  filterNodesToExport(projectNodes.value, nodesToExport)

  console.log("nodesToExport", nodesToExport)
  await userApi.exportProjectToDocuments(completeProject?.value.id, completeProject?.value.name, props.projectId, project.value.name, nodesToExport)
}

function showParametersDialog() {
  const dialog = Dialog.create({
    component: SubProjectParametersExportDialog,
    componentProps: {
      subProject: project,
      projectNodes: projectNodes,
    },
  })
    .onOk(async (data) => {
      dialog.hide()

      $q.loading.show({
        message: 'Veuillez patienter pendant l\'export du projet juridique dans les documents du dossier...',
        boxClass: 'bg-grey-2 text-grey-9',
        spinnerColor: 'primary',
        delay: 200, // ms
      })

      await exportToDocuments()

      $q.loading.hide()
      Notify.create({
        message: `L'export du projet juridique dans les documents du dossier a été effectué avec succès`,
        type: 'primary',
      })
      window.history.go()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function addComposableProject() {
  console.log("addComposableProject", project.value)
  const dialog = Dialog.create({
    component: AddSubProjectTreeDialog,
    componentProps: {
      onlyComposables: true,
    },
  })
    .onOk((subProjectTemplateIds) => {
      dialog.hide()
      createSubProjects(subProjectTemplateIds)
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function createSubProjects(subProjectTemplateIds) {
  // console.log("project.value", project.value)
  $q.loading.show({
    message: "Nous préparons votre dossier.\nMerci de patienter, cela peut prendre jusqu'à 1 minute.",
    boxClass: 'bg-grey-2 text-grey-9',
    spinnerColor: 'primary',
  })
  userApi
    .createSubProjects({
      userProjectId: completeProject.value.id,
      projectTemplateIds: subProjectTemplateIds.split(','),
      compositeProjectId: project.value.legacyId,
    })
    .then((project) => {
      $q.loading.hide()
      window.history.go()
    })
    .catch((e) => {
      $q.loading.hide()
    })
}

function isAllowed(permission) {
  console.log("isAllowed", permission, authenticatedUser)
  // return true

  let result = false
  authenticatedUser.subscriptionModules?.forEach((module) => {
    if (module.environmentSubscriptionModule.environmentId == authenticatedUser.environment.id && module.environmentSubscriptionModule.subscriptionModule.permissionsJson.includes(permission)) {
      result = true
    }
  })

  return result
}
</script>

<template>
  <div v-if="(!inComposite && !project.disabled) || inComposite">
    <div class="sticky-content">
      <div style="font-size: 16px; font-weight: 500; margin-left: 8px; padding-top: 8px; padding-bottom: 8px">
        <span style="color: rgb(64, 104, 200)">{{ project.name }}</span>
        <q-btn v-if="project.composite" icon="o_add" round flat size="sm" style="margin-left: 4px"
          @click="addComposableProject()">
          <q-tooltip>Ajouter un composé</q-tooltip>
        </q-btn>
        <q-btn v-if="isAllowed('edit_sub_project_parameters')" icon="settings" round flat size="sm"
          style="margin-left: 4px" @click="showParametersDialog()" />
      </div>
    </div>

    <q-tree dense ref="treeRef" node-key="nodeKey" default-expand-all :nodes="projectNodes" :filter="filter"
      :filter-method="applyTreeFilter" style="margin-left: 8px; margin-right: 8px">
      <template v-slot:header-section="prop">
        <div class="row items-center">
          <div class="section-title text-weight-medium">
            {{ prop.node.label }}
            <!-- <q-badge
                                                    outline
                                                    color="primary"
                                                    align="middle"
                                                    v-if="findSection(prop.node.nodeKey)?.optional"
                                                    class="q-ml-xs"
                                                    >Optionnelle</q-badge
                                                  > -->
          </div>
        </div>
      </template>
      <template v-slot:header-task="prop">
        <div class="row items-center full-width">
          <q-list dense class="bg-white col items-center">
            <q-item clickable class="task-item" :active="isActive(prop.node.nodeKey)" active-class="task-selected">
              <q-item-section no-wrap @click="onTaskSelected(prop.node.nodeKey)" :id="prop.node.nodeKey"
                class="row no-wrap items-left" style="padding-top: 8px">
                <q-item-label lines="1" class="col">
                  <q-icon :name="prop.node.icon" color="primary" size="1.4rem" class="q-mr-sm col-auto" />
                  <span class="task">
                    {{ prop.node.label }}
                    <q-tooltip>{{ prop.node.label }}</q-tooltip>
                  </span>
                  <q-icon class="col-auto" name="attach_file" v-if="prop.node.object.hasFiles" />
                </q-item-label>
              </q-item-section>

              <q-item-section no-wrap center side>
                <div class="row items-center no-wrap">
                  <ProjectTaskProgression :task="prop.node.object" class="q-mr-sm"
                    :isDisable="project.isReadOnly || isTaskReadOnly(prop.node.object)"
                    @updateEvents="onUpdateEvents($event)">
                  </ProjectTaskProgression>
                  <ProjectAssigneeSelector style="width: 64px; text-align: center; align: center" :task="prop.node.object"
                    class="q-mr-sm" :isDisable="project.isReadOnly || isTaskReadOnly(prop.node.object)"
                    @updateEvents="onUpdateEvents($event)" :borderless="true" :displayInitials="true">
                  </ProjectAssigneeSelector>
                  <ProjectDeadlineInput :borderless="true" style="width: 130px" :task="prop.node.object" class="q-mr-xs"
                    :isDisable="project.isReadOnly || isTaskReadOnly(prop.node.object)"
                    @updateEvents="onUpdateEvents($event)">
                  </ProjectDeadlineInput>
                </div>
              </q-item-section>
            </q-item>
          </q-list>
        </div>
      </template>
    </q-tree>

    <div v-if="project.composite && project.subProjects.length > 0">
      <div>
        <div
          style="color: rgb(64, 104, 200); font-weight: 500; margin-top: 16px; margin-left: 16px; margin-bottom: -8px;">
          Sous-projets
        </div>
        <div style="border: 0.5px solid grey; margin: 32px; margin-top: 16px; padding: 16px;">
          <!-- {{ project.subProjects.length }} -->
          <SubProject v-for="subProjectId in project.subProjects" :projectId="subProjectId.id"
            @subProjectTaskModified="onSubProjectTaskModified($event)" :inComposite="true">
          </SubProject>
        </div>
      </div>
    </div>

    <q-separator style="margin-top: 20px" />


  </div>
</template>

<style lang="scss" scoped>
@import '@/styles/quasar.variables.scss';

.section-title {
  font-size: 15px;
}

.task-item {
  padding-left: 0px;
  padding-right: 8px;
  width: 100% !important;
  margin-left: 0px !important;
  margin-right: 16px !important;
}

.task-selected {
  color: $primary;
  font-weight: 500;
  background-color: rgb(223, 223, 223);
}

:deep(.q-item) {
  border-radius: 6px;
}

.sticky-content {
  position: sticky;
  position: -webkit-sticky;
  top: 93px;
  z-index: 1;
  background-color: white;
}
</style>
