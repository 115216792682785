<script lang="ts" setup>
import { ref, Ref } from 'vue'
import { useDialogPluginComponent, QTableProps } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseDialog from '@/components/base/BaseDialog.vue'
import BaseTable from '@/components/common/Table/BaseTable.vue'
import ClientsPermissions from '@/components/common/permissions/ClientsPermissions.vue'
import ProjectsPermissions from '@/components/common/permissions/ProjectsPermissions.vue'
import DocumentsPermissions from './DocumentsPermissions.vue'
import { isNoUnitNumericStyleProp } from '@vue/shared'

interface Props {
  clientId?: string
}
const props = defineProps<Props>()

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const userApi: UserApi = useApi()
const userSelected = ref([])
const groupSelected = ref([])

const curentSelectionType = ref('none')
const currentUser = ref()
const currentGroup = ref()

const usersColumns: QTableProps['columns'] = [
  {
    name: 'fullName',
    label: 'Utilisateur',
    field: 'fullName',
    sortable: true,
    align: 'left',
  },
]
const usersRows: Ref<Array<any>> = ref([])

const groupsColumns: QTableProps['columns'] = [
  {
    name: 'name',
    label: 'Groupe',
    field: 'name',
    sortable: true,
    align: 'left',
  },
]
const groupsRows: Ref<Array<any>> = ref([])

const isLoading: Ref<boolean> = ref(false)
const usersSelected: Ref<Array<any>> = ref([])
const groupsSelected: Ref<Array<any>> = ref([])
const projectSelected: Ref<any> = ref(undefined)

// projectSelected.value = "2eabc068-fcf3-4119-82b3-44fc069c3e3e"

const clientSelected: Ref<any> = ref(undefined)

function onProjectSelected(event) {
  console.log('onProjectSelected', event)
  projectSelected.value = event
}

function onClientSelected(event) {
  clientSelected.value = event
}

async function beforeShow() {
  try {
    isLoading.value = true
    const portalUsers = await userApi.getPortalUsers(null)
    const clientUsers = await userApi.getClientUsersForce(props.clientId)
    const users = clientUsers.results.concat(portalUsers.results)
    const portalGroups = await userApi.getPortalGroups(null)
    const clientGroups = await userApi.getClientGroupsForce(props.clientId)
    const groups = portalGroups.concat(clientGroups.results)
    usersRows.value = users
    groupsRows.value = groups
    isLoading.value = false
  } catch (e) {
    isLoading.value = false
  }
}

function onUserSelected(rows) {
  groupSelected.value = []
  projectSelected.value = undefined
  if (rows[0] != undefined) {
    curentSelectionType.value = 'user'
    currentUser.value = rows[0]
  } else {
    curentSelectionType.value = 'none'
  }
  updateClientRights()
}

function onGroupSelected(rows) {
  userSelected.value = []
  projectSelected.value = undefined
  if (rows[0] != undefined) {
    curentSelectionType.value = 'group'
    currentGroup.value = rows[0]
  } else {
    curentSelectionType.value = 'none'
  }
  updateClientRights()
}

function updateClientRights() { }
</script>
<template>
  <q-dialog ref="dialogRef" @before-show="beforeShow" full-width>
    <BaseDialog title="Gestion des droits" :showActions="false" @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()">
      <template #body>
        <q-card-section horizontal>
          <q-card-section>
            <h5 class="q-my-none q-mb-sm">Utilisateurs & Groupes</h5>
            Sélectionner un utilisateur ou un groupe pour gérer ses droits
            <BaseTable dense selection="single" class="q-mb-sm" row-key="id" hide-selected-banner :is-loading="isLoading"
              :columns="usersColumns" :rows="usersRows" :enable-actions="false" :enable-add="false"
              v-model:selected="userSelected" @on-row-selected="onUserSelected">
            </BaseTable>
            <BaseTable dense selection="single" row-key="id" hide-selected-banner :is-loading="isLoading"
              :columns="groupsColumns" :rows="groupsRows" :enable-actions="false" :enable-add="false"
              v-model:selected="groupSelected" @on-row-selected="onGroupSelected">
            </BaseTable>
          </q-card-section>

          <q-separator vertical />

          <q-card-section v-if="!projectSelected">
            <h5 class="q-my-none q-mb-sm">Clients</h5>
            <ClientsPermissions @client-selected="onClientSelected" :clientId="clientId"> </ClientsPermissions>
          </q-card-section>
          <q-separator vertical v-if="!projectSelected" />
          <q-card-section v-if="!projectSelected">
            <h5 class="q-my-none q-mb-sm">Dossiers</h5>
            <ProjectsPermissions :client="clientSelected" @project-selected="onProjectSelected"> </ProjectsPermissions>
          </q-card-section>

          <q-card-section v-if="projectSelected">
            <div class="row items-baseline q-gutter-md">
              <q-btn dense outline icon="arrow_back" class="q-mt-md" style="width: fit-content" label="Retour"
                @click="projectSelected = undefined" />
              <h5 class="q-my-none q-mb-sm">{{ projectSelected.name }}</h5>
            </div>

            <Suspense>
              <!-- component with nested async dependencies -->
              <DocumentsPermissions :project-id="projectSelected.id" :client-id="clientSelected.id" :user="currentUser"
                :group="currentGroup" :entity-type="curentSelectionType"></DocumentsPermissions>

              <!-- loading state via #fallback slot -->
              <template #fallback>
                <q-inner-loading showing color="primary" />
              </template>
            </Suspense>
          </q-card-section>
        </q-card-section>
      </template>
      <template #actions> </template>
    </BaseDialog>
  </q-dialog>
</template>
<style lang="scss" scoped></style>
