<script lang="ts" setup>
import { ref, Ref } from 'vue'
import { Notify, Dialog, useQuasar } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { Project } from '@/models/projects/Project'
import AddSubProjectTreeDialog from '@/components/projects/dialog/project_parameters/AddSubProjectsTreeDialog.vue'
import ConfirmDeleteDialog from '@/components/common/dialog/ConfirmDeleteDialog.vue'

interface Props {
  project: Project
}
const props = defineProps<Props>()

let subProjects = props.project.value.subProjects.sort((a, b) => (a.order > b.order ? 1 : -1))

const $q = useQuasar()

const userApi: UserApi = useApi()
const isLoading: Ref<boolean> = ref(false)

function showAddSubProjectDialog() {
  const dialog = Dialog.create({
    component: AddSubProjectTreeDialog,
    componentProps: {},
  })
    .onOk((subProjectTemplateIds) => {
      dialog.hide()
      createSubProjects(subProjectTemplateIds)
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function deleteSubProject(event, subProject) {
  event.preventDefault()
  event.stopPropagation()
  const dialog = Dialog.create({
    component: ConfirmDeleteDialog,
    componentProps: {
      message: 'Voulez-vous vraiment supprimer le projet ' + subProject.name + ' ?',
    },
  })
    .onOk(async () => {
      await userApi.deleteSubProject({
        subProjectId: subProject.id,
      })

      dialog.hide()
      $q.loading.hide()
      window.history.go()
      Notify.create({
        message: 'Projet supprimé',
        type: 'primary',
      })
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function createSubProjects(subProjectTemplateIds) {
  $q.loading.show({
    message: "Nous préparons votre dossier.\nMerci de patienter, cela peut prendre jusqu'à 1 minute.",
    boxClass: 'bg-grey-2 text-grey-9',
    spinnerColor: 'primary',
  })
  userApi
    .createSubProjects({
      userProjectId: props.project.value.id,
      projectTemplateIds: subProjectTemplateIds.split(','),
    })
    .then((project) => {
      $q.loading.hide()
      window.history.go()
    })
    .catch((e) => {
      $q.loading.hide()
    })
}
</script>

<template>
  <div v-if="subProjects.length == 0">Aucun projet pour le moment</div>

  <div v-if="subProjects.length != 0">
    <div style="margin-bottom: 10px; font-weight: 500">Projets juridiques du dossier</div>
    <q-list dense>
      <div v-for="(subProject, index) in subProjects" :key="subProject.id">
        <q-item dense style="padding: 0">
          <q-item-section>
            <q-item-label>{{ subProject.name }}</q-item-label>
          </q-item-section>

          <q-item-section side>
            <div class="row justify-between items-center">
              <q-btn
                flat
                color="primary"
                label="Supprimer"
                :disable="isLoading"
                @click="deleteSubProject($event, subProject)"
              >
                <!-- <q-tooltip class="tooltip">Supprimer le projet</q-tooltip> -->
              </q-btn>
            </div>
          </q-item-section>
        </q-item>
        <q-separator v-if="index != subProjects.length - 1" style="" />
      </div>
    </q-list>
  </div>

  <div class="row">
    <div class="col"></div>
    <div class="col-auto">
      <q-btn
        style="margin-top: 50px; margin-right: 10px"
        color="primary"
        label="Ajouter"
        @click="showAddSubProjectDialog"
      ></q-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
