<script setup lang="ts">
import { useRoute } from 'vue-router'
import { ref, inject } from 'vue'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { Task } from '@/models/projects/Task'
import { ProjectKey } from '@/models/symbols'
import { format } from 'date-fns'

interface Props {
  event: any
}

const props = withDefaults(defineProps<Props>(), {
  event: undefined,
})

function formatDate(date_str) {
  const dateFormat = new Date(date_str)
  return format(dateFormat, 'dd/MM/yyyy')
}

function formatDateTime(date_str) {
  const dateFormat = new Date(date_str)
  return format(dateFormat, 'dd/MM/yyyy HH:mm:ss')
}
</script>

<template>
  <div>
    <!-- to get log-item class from parent -->
    <q-item class="log-item">
      <q-item-section>
        <div class="log-item-header">
          <div class="row no-wrap items-center">
            <div class="col-auto">Responsable de tâche modifié</div>
            <div class="col"></div>
            <div class="col-auto log-item-date">
              {{ formatDate(event.createdAt) }}
              <q-tooltip class="tooltip">{{ formatDateTime(event.createdAt) }}</q-tooltip>
            </div>
          </div>
        </div>

        <div class="log-item-content"></div>
      </q-item-section>
    </q-item>
  </div>
</template>

<style lang="scss" scoped></style>
