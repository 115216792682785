<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import { ref, inject, watch, provide } from 'vue'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { useStore } from '@/store/store'
import { ProjectKey } from '@/models/symbols'
import { Dialog, Notify, useQuasar } from 'quasar'
import FileSaver from 'file-saver'
// import NewDirectoryDialog from "./NewDirectoryDialog.vue";
// import NewFileWithTemplateDialog from "./NewFileWithTemplateDialog.vue";
// import ImportFilesDialog from "./ImportFilesDialog.vue";
// import FileNode from "./FileNode.vue";
// import FileVersionNode from "./FileVersionNode.vue";
// import DirectoryNode from "./DirectoryNode.vue";
// import ImportFromLOPDialog from "./ImportFromLOPDialog.vue";
import { refreshFiles, createLabels } from '@/components/projects/execution/documents/DocumentsCommon'
// import DeleteNodesDialog from "./DeleteNodesDialog.vue";
// import MoveDocumentsToUploadTaskDialog from "./MoveDocumentsToUploadTaskDialog.vue";
// import MoveDocumentsToProjectDialog from "./MoveDocumentsToProjectDialog.vue";

const route = useRoute()
const router = useRouter()

interface Props {
  projectId?: string
  documentId?: string
}

const props = withDefaults(defineProps<Props>(), {})

const $q = useQuasar()

const store = useStore()
const userApi: UserApi = useApi()

const project = inject(ProjectKey)
const taskDetailsSelected = inject('taskDetailsSelected')
const lastEvent = inject('lastEvent')

const filter = ref('')
const treeRef = ref()
const expandedNodes = ref([0])
const selectedNode = inject('documentTreeSelectedNode')

const currentDirectoryNode = ref(null)

const collapseDocumentTree = ref(true)

const ticked = ref([])
provide('ticked', ticked)

const documentTree = await userApi.getUserProjectDocumentTree(project?.value.id)

// watch(taskDetailsSelected, () => {
//     console.log("task selected changed", taskDetailsSelected)
//     if (taskDetailsSelected.value !== undefined) {
//         console.log("task selected: need to deselect document");
//         selectedNode.value = null;
//     }
// });

const documentTreeUpdateCounter = inject('documentTreeUpdateCounter')
watch(documentTreeUpdateCounter, () => {
  refreshFiles(userApi, project, documentTreeNodes)
})

createLabels(documentTree.children)

const documentTreeNodes = inject('documentTreeNodes')
documentTreeNodes.value[0].children = documentTree.children

currentDirectoryNode.value = documentTreeNodes.value[0]

if (route.query.documentId) {
  console.log('have a document to select', route.query.documentId)
  selectedNode.value = route.query.documentId
  expandToNode(route.query.documentId)
}

function expandToNode(nodeId) {
  let currentNode = getNode(documentTreeNodes.value, nodeId)
  console.log(currentNode)
  while (currentNode != undefined) {
    expandedNodes.value.push(currentNode.id)
    currentNode = currentNode.parent
  }
}

function getNode(nodes, nodeId) {
  for (let i = 0; i < nodes.length; ++i) {
    const currentNode = nodes[i]
    if (currentNode.id === nodeId) {
      return currentNode
    }
    const childNode = getNode(currentNode.children, nodeId)
    if (childNode !== undefined) {
      return childNode
    }
  }
  return undefined
}

function onToggleCollapse() {
  collapseDocumentTree.value = !collapseDocumentTree.value
  if (collapseDocumentTree.value) {
    expandedNodes.value = [0]
  } else {
    treeRef.value.expandAll()
  }
}

function getCurrentNode() {
  if (selectedNode.value === null) {
    return null
  }
  return getNode(documentTreeNodes.value, selectedNode.value)
}

function getCurrentDirectory() {
  console.log('selectedNode', selectedNode.value)

  const currentNode = getCurrentNode()

  if (currentNode === null) {
    return null
  }

  if (currentNode.type === 1) {
    return currentNode
  }

  return currentNode.parent
}

async function createDirectoryReal(directoryName) {
  let newOrder = 1
  if (documentTreeNodes.value.length !== 0) {
    newOrder = documentTreeNodes.value[documentTreeNodes.value.length - 1].order + 1
  }

  let currentDirectory = getCurrentDirectory()

  console.log('creating directory', directoryName, currentDirectory)

  let newDirectory = await userApi.createDocumentTreeDirectory(project?.value.id, {
    name: directoryName,
    order: newOrder,
    parentId: currentDirectory?.id,
  })

  console.log('new directory', newDirectory)
  if (currentDirectory == null) {
    currentDirectory = documentTreeNodes.value[0]
  }

  createLabels([newDirectory], currentDirectory)
  currentDirectory.children.push(newDirectory)

  // refreshFiles(userApi, project, documentTreeNodes);
}

async function createDirectory() {
  const dialog = Dialog.create({
    component: NewDirectoryDialog,
    componentProps: {},
  })
    .onOk((directoryName) => {
      createDirectoryReal(directoryName)
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function showUploadFilesDialog() {
  const dialog = Dialog.create({
    component: ImportFilesDialog,
    componentProps: {
      project: project,
      currentDirectory: getCurrentDirectory(),
    },
  })
    .onOk((filesToUpload) => {
      refreshFiles(userApi, project, documentTreeNodes)
      // window.history.go();
      // filesToUpload.forEach((fileToUpload) => {
      //   documentTreeNodes.value.push({
      //     id: newId,
      //     type: "2",
      //     label: fileToUpload.name,
      //   });
      //   newId = newId + 1;
      // });

      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function applyTreeFilter(node, filter) {}

async function onDrop(evt) {
  const itemId = evt.dataTransfer.getData('itemId')
  await userApi.moveDocumentTreeNode(project?.value.id, itemId, undefined)

  refreshFiles(userApi, project, documentTreeNodes)
}

async function createNewDocument(fileType, fileName) {
  if (fileType === 'Word') {
    fileName = fileName + '.docx'
  } else if (fileType === 'Excel') {
    fileName = fileName + '.xlsx'
  } else if (fileType === 'PowerPoint') {
    fileName = fileName + '.pptx'
  }

  let currentDirectory = getCurrentDirectory()

  const newDocument = await userApi.createDocumentTreeFileWithTemplate(project?.value.id, {
    fileType: fileType,
    name: fileName,
    order: 1,
    parentId: currentDirectory?.id,
  })

  lastEvent.value = {
    type: 4,
    documentTreeNode: {
      name: fileName,
    },
  }

  console.log('new document', newDocument)
  if (currentDirectory == null) {
    currentDirectory = documentTreeNodes.value[0]
  }

  createLabels([newDocument], currentDirectory)
  currentDirectory.children.push(newDocument)

  // refreshFiles(userApi, project, documentTreeNodes);
}

function showNewDocumentModal(documentType: string) {
  const dialog = Dialog.create({
    component: NewFileWithTemplateDialog,
    componentProps: {
      fileType: documentType,
    },
  })
    .onOk((infos) => {
      createNewDocument(infos.fileType, infos.fileName)
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function showImportFromLOPDialog() {
  const dialog = Dialog.create({
    component: ImportFromLOPDialog,
    componentProps: {
      project: project,
    },
  })
    .onOk((infos) => {
      console.log('Imported', infos)
      dialog.hide()
      project.value.lopMatterId = infos.matterId
      refreshFiles(userApi, project, documentTreeNodes)
    })
    .onCancel(() => {
      dialog.hide()
    })
}

watch(selectedNode, () => {
  console.log('watch selected node')
  onNodeSelected(selectedNode.value)
})

function onNodeSelected(selectedNodeId) {
  console.log('new node selected', selectedNodeId)

  if (selectedNodeId === null) {
    // route.query.documentId = ""
  } else {
    taskDetailsSelected.value = undefined
  }

  if (store.state.user.portal === 'CLIENT') {
    router.push({
      name: 'projects/project',
      params: {
        projectId: route.params.projectId.toString(),
      },
      query: { documentId: selectedNodeId },
    })
  } else {
    router.push({
      name: 'clients/client/projects/project',
      params: {
        clientId: route.params.clientId.toString(),
        projectId: route.params.projectId.toString(),
      },
      query: { documentId: selectedNodeId },
    })
  }
}

window.addEventListener('online', function () {
  console.log('Connected')
})

window.addEventListener('offline', function () {
  console.log('Disconnected')
})

function tickChildren(nodeId) {
  const node = getNode(documentTreeNodes.value, nodeId)
  if (node.type === 1) {
    node.children.forEach((childNode) => {
      if (!ticked.value.includes(childNode.id)) {
        ticked.value.push(childNode.id)
      }
      tickChildren(childNode.id)
    })
  }
}

function untickChildren(nodeId) {
  const node = getNode(documentTreeNodes.value, nodeId)

  if (node == undefined) {
    return
  }

  if (node.type === 1) {
    node.children.forEach((childNode) => {
      if (ticked.value.includes(childNode.id)) {
        ticked.value = ticked.value.filter((n) => n !== childNode.id)
      }
      untickChildren(childNode.id)
    })
  }
}

watch(ticked, (newValue, oldValue) => {
  console.log('ticked', ticked.value, newValue, oldValue)
  newValue.forEach((nodeId) => {
    if (oldValue.includes(nodeId)) {
      console.log('node already ticked', nodeId)
    } else {
      console.log('node ticked', nodeId)
      tickChildren(nodeId)
    }
  })

  oldValue.forEach((nodeId) => {
    if (newValue.includes(nodeId)) {
      console.log('node still ticked', nodeId)
    } else {
      console.log('node unticked', nodeId)
      untickChildren(nodeId)
    }
  })
})

console.log('documentTreeNodes', documentTreeNodes.value)

async function onFileClick(node) {
  console.log('download node', node)
  downloadFile(node)
}

async function downloadFile(node) {
  try {
    const doc = await userApi.downloadDocumentTreeFile(project?.value.id, node.id)

    var reader = new FileReader()
    reader.readAsArrayBuffer(new Blob([doc]))
    reader.onload = function () {
      const newFile = new File([new Blob([reader.result])], 'file', {
        type: 'application/octet-stream',
      })

      FileSaver(newFile, node.name)
    }
    reader.onerror = function (error) {
      // setloadingSaveDoc("error");
      // setTimeout(() => {
      //   setloadingSaveDoc(false);
      // }, 1500);
    }
  } catch (e) {
    console.error(e)
    Notify.create({
      message: 'Erreur lors du téléchargement du document',
      type: 'negative',
    })
  }
}

const rootDirectory = {
  node: currentDirectoryNode.value,
  label: '/',
}

const directories = ref([rootDirectory])
const selectedDirectory = ref(rootDirectory)

function onDirectoryClick(node) {
  console.log('directory clicked')
  currentDirectoryNode.value = node
  const newDirectory = {
    node: node,
    label: selectedDirectory.value.label == '/' ? '/' + node.label : selectedDirectory.value.label + '/' + node.label,
  }
  directories.value.push(newDirectory)
  selectedDirectory.value = newDirectory
}

function changeDirectory(newDirectory) {
  console.log('change directory', newDirectory)
  currentDirectoryNode.value = newDirectory.node
  while (directories.value[directories.value.length - 1] !== newDirectory) {
    directories.value.pop()
  }
}
</script>

<template>
  <div style="margin-bottom: 20px">
    <div v-if="documentTreeNodes.length != 0" style="padding-top: 8px">
      <h5>Documents</h5>
      <div
        v-if="documentTreeNodes[0].children.length == 0"
        style="background-color: white; height: 50px; padding-top: 8px"
      >
        <span class="absolute-center-" style="font-size: 14px; padding-left: 50px">Aucun document</span>
      </div>
      <div v-if="documentTreeNodes[0].children.length != 0">
        <q-select
          options-cover
          dense
          outlined
          v-model="selectedDirectory"
          :options="directories"
          option-value="node"
          option-label="label"
          class="directory-select"
          @update:model-value="changeDirectory"
        ></q-select>
        <q-list v-for="node in currentDirectoryNode.children">
          <q-item
            v-if="node.type === 1"
            style="margin-left: 0px; padding-left: 8px"
            clickable
            @click="onDirectoryClick(node)"
          >
            <q-icon :name="node.icon" size="md" color="grey"></q-icon>
            <span style="margin-left: 8px">
              {{ node.label }}
            </span>
            <q-separator />
          </q-item>
          <q-item
            v-if="node.type === 2"
            style="margin-left: 0px; padding-left: 8px"
            clickable
            @click="onFileClick(node)"
          >
            <q-icon :name="node.icon" size="md" color="grey"></q-icon>
            <span style="margin-left: 8px">
              {{ node.label }}
            </span>
            <q-separator />
          </q-item>
        </q-list>
      </div>
    </div>
    <!-- <q-tree ref="treeRef" node-key="id" :nodes="documentTreeNodes" :filter="filter" :filter-method="applyTreeFilter"
            style="width: 100%; margin-left: 16px;" dense v-model:expanded="expandedNodes" v-model:selected="selectedNode"
            selected-color="primary" tick-strategy="strict" v-model:ticked="ticked">         <template v-slot:default-header="prop">
            <div style="width: 100%; padding: 0px;">
                <div v-if="prop.node.type === 0"></div>
                <DirectoryNode v-if="prop.node.type === 1" :element="prop.node" :treeRef="treeRef"></DirectoryNode>
                <FileNode v-if="prop.node.type === 2" :element="prop.node"></FileNode>
                <FileVersionNode v-if="prop.node.type === 3" :element="prop.node"></FileVersionNode>
            </div>
        </template>
        </q-tree> -->
  </div>
</template>

<style lang="scss" scoped>
h5 {
  color: rgb(64, 104, 200);
  margin-top: 0px;
  padding-top: 0px;
  font-size: 20px;
}

.directory-select {
  margin-left: 8px;
  margin-right: 8px;
}
</style>
