<script setup lang="ts">
import { ref, inject, Ref } from 'vue'
import { useApi } from '@/store/useAppStore'
import { useDialogPluginComponent, Notify } from 'quasar'
import BaseSkeletonTable from '@/components/base/BaseSkeletonTable.vue'
import UserApi from '@/services/api/core/UserApi'
import BaseDialog from '@/components/base/BaseDialog.vue'
import BaseTable from '@/components/common/Table/BaseTable.vue'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import { AuthenticatedUser } from '@/models/core/AuthenticatedUser'

interface Props {
  userProject: any
}
const props = withDefaults(defineProps<Props>(), {
})

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const userApi: UserApi = useApi()
const authenticatedUser = useAuthenticatedUser()

const password = ref('')
const confirmPassword = ref('')
const isPwd = ref(true)
const isConfirmPwd = ref(true)
const isLoading = ref(false)

async function setPassword() {
  await userApi.setProjectPassword(props.userProject.id, authenticatedUser.id, password.value)
  Notify.create({
    message: `Projet verrouillé avec succès`,
    type: 'primary',
  })
  emit("ok")
}
</script>

<template>
  <q-dialog ref="dialogRef">
    <BaseDialog title="Protéger le dossier par mot de passe" :show-cancel-button="true"
      @on-dialog-cancel="onDialogCancel()" @hide="onDialogHide()">
      <template #body>
        <div class="columns">
          <q-input outlined id="password" :type="isPwd ? 'password' : 'text'" label="Mot de passe" v-model="password"
            required bottom-slots>
            <template #append>
              <q-icon :name="isPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer" @click="isPwd = !isPwd" />
            </template>
          </q-input>
          <q-input outlined id="password" :type="isConfirmPwd ? 'password' : 'text'" label="Confirmer votre mot de passe"
            v-model="confirmPassword" required bottom-slots>
            <template #append>
              <q-icon :name="isConfirmPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer"
                @click="isConfirmPwd = !isConfirmPwd" />
            </template>
          </q-input>
        </div>
      </template>
      <template #actions>
        <q-btn :disable="password !== confirmPassword || password === '' || confirmPassword === '' || isLoading"
          :loading="isLoading" flat label="Valider" color="primary" @click="setPassword"> </q-btn>
      </template>
    </BaseDialog>
  </q-dialog>
</template>

<style lang="scss" scoped>
.permission-table {
  .q-table__bottom--nodata {
    display: none;
  }
}

h6 {
  text-align: center;
  display: block;
  font-weight: 700;
}

.button_container {
  text-align: center;
  display: block;
}

.skeleton-table {
  box-shadow: none;
}
</style>
