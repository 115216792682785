import { http } from 'msw'
import { GeneratedDocument } from '@/models/files/GeneratedDocument'

interface PostFileBody {
  files: File[]
}
interface PutFileBody {
  file: GeneratedDocument
}

interface FileResponse {
  files: GeneratedDocument[]
}

export default [
  // TODO: Migrate (https://mswjs.io/docs/migrations/1.x-to-2.x)
  // http.get('/projects/subsections', (req, res, ctx) => {
  //   return res(
  //     ctx.json({
  //       id: '4cf632da-ffa9-4435-a005-9a455b2970d1',
  //       pkid: 131,
  //       name: 'Création de la SAS Metaverse',
  //       legacyId: 902,
  //       createdAt: '2021-12-06T10:28:53.826198Z',
  //       updatedAt: '2021-12-06T10:28:53.826223Z',
  //       sections: [
  //         {
  //           id: '2083',
  //           name: "Ouverture d'un compte bancaire",
  //           order: 2,
  //           optional: false,
  //           sections: [
  //             {
  //               id: '2082',
  //               name: "Nomination d'un Commissaire aux comptes",
  //               order: 1,
  //               optional: true,
  //               tasks: [
  //                 {
  //                   id: '6255',
  //                   name: 'Proposition de nomination adressée au CAC',
  //                   order: 0,
  //                   description:
  //                     "<p>Si vous avez prévu dans vos statuts la nomination d'un commissaire aux comptes (un titulaire et un suppléant), il convient de formaliser auprès d'eux une demande d'exercer les fonctions de CAC&nbsp;pour votre société.</p><p>Vous devez <span style=\"color: inherit;\">faire cette demande vous-même au CAC&nbsp;titulaire et au CAC&nbsp;suppléant (les deux sont souvent gérés par la même société).</span></p><p>Certains documents doivent accompagner la demande :</p><ul><li>Projet de statuts ;</li><li>Justificatif de domiciliation signé ;</li><li>Liste des souscripteurs signée ;</li><li>Pour chaque dirigeant : personne physique (copie ID ou carte de séjour + déclaration de non-condamnation et de filiation signée), personne morale (extrait Kbis de moins de 3 mois).</li></ul>",
  //                   status: 'not_started',
  //                   type: 'information',
  //                   deadline: null,
  //                   assignee: null,
  //                   optional: false,
  //                   documentName: null,
  //                 },
  //                 {
  //                   id: '6256',
  //                   name: 'Réponse du CAC',
  //                   order: 1,
  //                   description:
  //                     "<p>Lorsque le CAC accepte ses fonctions, il doit vous communiquer les documents suivants :</p><ul><li>Lettre d'acceptation des fonctions de CAC ;</li><li>Si c'est une société : un extrait Kbis datant de moins de&nbsp;3&nbsp;mois ;</li><li>Attestation d'inscription sur la liste des CAC (document à en-tête de la Compagnie Nationale des&nbsp;Commissaires aux Comptes).</li></ul><p>Vous devez obtenir ces documents pour le CAC&nbsp;titulaire et pour le CAC&nbsp;suppléant.</p><p>Une fois ces documents reçus, merci de les joindre à cette tâche.</p>",
  //                   status: 'not_started',
  //                   type: 'document_upload',
  //                   deadline: null,
  //                   assignee: null,
  //                   optional: false,
  //                   documentName: null,
  //                 },
  //               ],
  //             },
  //           ],
  //           tasks: [
  //             {
  //               id: '6257',
  //               name: "Demande d'ouverture d'un compte bancaire professionnel",
  //               order: 0,
  //               description:
  //                 "<p>Vous devez désormais déposer le montant du capital social sur un compte bancaire dédié, afin d'obtenir un certificat de dépôt indispensable pour votre dossier de création d’entreprise.</p><p>Vous devez <span style=\"color: inherit;\">vous charger vous-même de l'ouverture d'un compte de dépôt de capital auprès d'une banque (votre banque ou une banque en ligne pour les professionnels).</span></p><p>Certains documents doivent accompagner la demande :</p><ul><li>Projet de statuts ;</li><li>Justificatif de domiciliation signé ;</li><li>Liste des souscripteurs signée ;</li><li>Pour chaque souscripteur : personne physique (copie ID ou carte de séjour + justificatif de domicile de moins de 3 mois), personne morale (extrait Kbis de moins de 3 mois).</li></ul>",
  //               status: 'not_started',
  //               type: 'information',
  //               deadline: null,
  //               assignee: null,
  //               optional: false,
  //               documentName: null,
  //             },
  //             {
  //               id: '6258',
  //               name: 'Dépôt du capital social',
  //               order: 1,
  //               description:
  //                 '<p>Une fois que le compte de dépôt a été créé, vous pouvez y déposer le capital social par un simple virement bancaire. Pour cela, utilisez le RIB&nbsp;qui vous a été fourni lors de la création du compte.</p><p>Chacun des associés peut déposer sa propre part du capital en faisant un virement directement depuis un compte bancaire personnel.</p>',
  //               status: 'not_started',
  //               type: 'information',
  //               deadline: null,
  //               assignee: null,
  //               optional: false,
  //               documentName: null,
  //             },
  //             {
  //               id: '6259',
  //               name: 'Réception du certificat de dépôt des fonds',
  //               order: 2,
  //               description:
  //                 "<p>Peu de temps après viré le capital social (72 heures en moyenne), vous recevrez un certificat de dépôt des fonds de votre banque (si nécessaire, insistez pour recevoir un scan par email du document en plus de l'envoi par courrier en version papier, cela vous fera gagner du temps).</p><p>Dès que vous recevez ce certificat, merci de le joindre à cette tâche.</p>",
  //               status: 'not_started',
  //               type: 'document_upload',
  //               deadline: null,
  //               assignee: null,
  //               optional: false,
  //               documentName: null,
  //             },
  //           ],
  //         },
  //         {
  //           id: '2084',
  //           name: 'Finalisation des documents juridiques',
  //           order: 3,
  //           optional: false,
  //           tasks: [
  //             {
  //               id: '6261',
  //               name: 'Pouvoir pour formalités',
  //               order: 0,
  //               description:
  //                 '<p>Répondez à ce questionnaire afin de générer le document suivant :</p><p><br></p><p><br></p><ul><li>Pouvoir pour formalités</li></ul><p><br></p>',
  //               status: 'not_started',
  //               type: 'document_generation',
  //               deadline: null,
  //               assignee: null,
  //               optional: false,
  //               documentName: 'Création de SAS - Pouvoir pour formalités',
  //             },
  //             {
  //               id: '6260',
  //               name: 'Signature du pouvoir pour formalités',
  //               order: 1,
  //               description:
  //                 '<p>Dès que vous avez reçu le certificat de dépôt des fonds, vous pouvez signer les derniers documents pour créer votre SAS&nbsp;:</p><p><br></p><ul><li>Le pouvoir pour formalités.</li></ul>',
  //               status: 'not_started',
  //               type: 'document_signature',
  //               deadline: null,
  //               assignee: null,
  //               optional: false,
  //               documentName: null,
  //             },
  //           ],
  //         },
  //         {
  //           id: '2085',
  //           name: 'Activité réglementée',
  //           order: 4,
  //           optional: true,
  //           tasks: [
  //             {
  //               id: '6262',
  //               name: "Déclaration ou demande d'autorisation administrative",
  //               order: 0,
  //               description:
  //                 '<p>En amont de la création de votre entreprise, vérifiez si l\'activité que vous souhaitez exercer est&nbsp;<a href="https://bpifrance-creation.fr/moment-de-vie/mon-activite-est-elle-reglementee" rel="noopener noreferrer" target="_blank" style="color: rgb(84, 105, 212);">réglementée</a>.&nbsp;En France, certaines activités sont soumises à des conditions d\'accès et/ou des conditions d\'exploitation. Par exemple, pour créer une société de transport de marchandises, il est obligatoire de s\'inscrire au préalable au registre des transporteurs routiers de marchandises, ce qui nécessite la présentation d\'un diplôme particulier.</p><p>Si votre activité est réglementée et nécessite une démarche administrative préalable à la création de la société, cette démarche pourra la plupart du temps être effectuée en ligne sur le site public&nbsp;<a href="https://www.demarches-simplifiees.fr/" rel="noopener noreferrer" target="_blank" style="color: rgb(84, 105, 212);">démarches-simplifiées.fr</a>.</p><p>Il n\'existe pas de liste officielle exhaustive des activités réglementées en France, mais vous trouverez&nbsp;<a href="https://bpifrance-creation.fr/entrepreneur/activites-reglementees" rel="noopener noreferrer" target="_blank" style="color: rgb(84, 105, 212);">ici</a>&nbsp;une liste relativement fournie.</p><p>Si vous avez un doute, afin d\'éviter toute erreur qui pourrait entraîner l\'interdiction de votre entreprise d\'exercer son activité, consultez un conseiller par visioconférence afin que celui-ci vous apporte une réponse claire.</p>',
  //               status: 'not_started',
  //               type: 'appointment_making',
  //               deadline: null,
  //               assignee: null,
  //               optional: false,
  //               documentName: null,
  //             },
  //             {
  //               id: '6263',
  //               name: "Réponse de l'autorité administrative",
  //               order: 1,
  //               description:
  //                 "<p>Après avoir effectué votre démarche auprès de l'autorité administrative, vous recevez un document provisoire attestant de votre conformité aux conditions d'accès à l'activité réglementée. Ce document est indispensable à votre dossier de création d'entreprise.</p><p>La réception de ce document peut prendre entre quelques jours et plusieurs semaines, selon l'autorité administrative.</p><p>Dès que vous avez reçu ce document, merci de le joindre à cette tâche.</p>",
  //               status: 'not_started',
  //               type: 'document_upload',
  //               deadline: null,
  //               assignee: null,
  //               optional: false,
  //               documentName: null,
  //             },
  //           ],
  //         },
  //         {
  //           id: '2080',
  //           name: 'Immatriculation de la société',
  //           order: 5,
  //           optional: false,
  //           tasks: [
  //             {
  //               id: '6242',
  //               name: "Paiement des frais d'annonce légale et d'immatriculation",
  //               order: 0,
  //               description:
  //                 "<p>La publication d'une annonce légale dans un journal spécialisé est obligatoire pour la création d'une société.</p><p>À ce montant s'ajoutent les frais facturés par le greffe pour l'enregistrement de la société : frais pour la constitution de la société + frais pour la déclaration des bénéficiaires effectifs.</p><p>Afin que nous puissions procéder à ces formalités, veuillez régler ces frais en utilisant le module ci-dessous.</p>",
  //               status: 'not_started',
  //               type: 'online_payment',
  //               deadline: null,
  //               assignee: null,
  //               optional: false,
  //               documentName: null,
  //             },
  //             {
  //               id: '6243',
  //               name: "Réception des justificatifs d'immatriculation",
  //               order: 1,
  //               description:
  //                 "<p>Une fois la société immatriculée au RCS, vous recevez les pièces justificatives :</p><ul><li>Une attestation de parution de l'annonce légale ;</li><li>Un récépissé de dépôt du dossier d'immatriculation ;</li><li>Un récépissé de dépôt de la déclaration des bénéficiaires effectifs ;</li><li>Un extrait Kbis.</li></ul><p>Toutes ces pièces sont accessibles ci-dessous.</p><p>La société étant officiellement enregistrée auprès des administrations, vous recevrez différents courriers au cours des prochaines semaines (de l'URSSAF, de l'administration fiscale, etc.).</p>",
  //               status: 'not_started',
  //               type: 'document_upload',
  //               deadline: null,
  //               assignee: null,
  //               optional: false,
  //               documentName: null,
  //             },
  //           ],
  //         },
  //       ],
  //     }),
  //   )
  // }),
  // rest.get('/projects/:projectId/tasks/:taskId', (req, res, ctx) => {
  //   return res(ctx.json({}))
  // }),
  // rest.post<PostFileBody, FileResponse>('/projects/:projectId/tasks/:taskId/files', (req, res, ctx) => {
  //   const { files } = req.body
  //   let filesRes: any = []
  //   if (Array.isArray(files)) {
  //     files.forEach((file, index) => {
  //       filesRes.push({
  //         id: index.toString(),
  //         name: file.name ?? 'myfile.json',
  //         order: index,
  //         mimeType: file.type ?? '.json',
  //         url: 'http://test.test/',
  //         remark: 'Mauvais document',
  //       })
  //     })
  //   } else {
  //     filesRes.push({
  //       id: 1,
  //       name: files.name ?? 'monFichierGeneré.json',
  //       order: 1,
  //       mimeType: files.type ?? '.json',
  //       url: 'http://test.test/',
  //       remark: 'Mauvais document',
  //     })
  //   }
  //   return res(ctx.json(filesRes))
  // }),
  // rest.put<PutFileBody, FileResponse>('/projects/:projectId/tasks/:taskId/files/:fileId', (req, res, ctx) => {
  //   const { file } = req.body
  //   let filesRes: any = []
  //   filesRes.push({
  //     id: 9,
  //     name: file.name,
  //     order: 1,
  //     mimeType: '.json',
  //     url: 'http://test.test/',
  //     remark: file.remark,
  //   })
  //   return res(ctx.json(filesRes))
  // }),
  // rest.delete<FileResponse>('/projects/:projectId/tasks/:taskId/files/:fileId', (req, res, ctx) => {
  //   let filesRes: any = []
  //   filesRes.push({
  //     id: 9,
  //     name: 'myfile2.json',
  //     order: 1,
  //     mimeType: '.json',
  //     url: 'http://test.test/',
  //     remark: 'Commentaire de test un peu plus long que les autres',
  //   })
  //   return res(ctx.json(filesRes))
  // }),
  // rest.get('/mock/questions', (req, res, ctx) => {
  //   return res(
  //     ctx.json({
  //       pages: [
  //         {
  //           id: 1,
  //           name: 'Nom de la page',
  //           description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  //           questions: [
  //             {
  //               id: 1,
  //               order: 1,
  //               label: 'Variable nombre',
  //               description:
  //                 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum rutrum elit id nunc egestas, et tempus erat molestie. Ut porta enim id arcu lobortis, at aliquam ligula rutrum. Morbi eget pulvinar ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla facilisi. In vitae massa mauris.',
  //               type: 'number',
  //               dependantQuestions: [],
  //               answer: {
  //                 value: 3,
  //               },
  //             },
  //             {
  //               id: 2,
  //               order: 2,
  //               label: 'Variable texte',
  //               description: '',
  //               type: 'text',
  //               dependantQuestions: [],
  //               answer: {
  //                 value: '',
  //               },
  //             },
  //             {
  //               id: 3,
  //               order: 3,
  //               label: 'Variable oui / non',
  //               description:
  //                 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum rutrum elit id nunc egestas, et tempus erat molestie. Ut porta enim id arcu lobortis, at aliquam ligula rutrum. Morbi eget pulvinar ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla facilisi. In vitae massa mauris.',
  //               type: 'bool',
  //               dependantQuestions: [],
  //               answer: {
  //                 value: true,
  //               },
  //             },
  //             {
  //               id: 4,
  //               order: 4,
  //               label: 'Variable date / heure',
  //               description: '',
  //               type: 'date',
  //               dependantQuestions: [
  //                 {
  //                   id: 3,
  //                   value: true,
  //                 },
  //               ],
  //               answer: {
  //                 value: '',
  //               },
  //             },
  //             {
  //               id: 5,
  //               order: 5,
  //               label: 'Variable choix multiple',
  //               description: '',
  //               type: 'multi',
  //               dependantQuestions: [],
  //               options: ['Choix multiple 1', 'Choix multiple 2', 'Choix multiple 3', 'Choix multiple 4'],
  //               answer: {
  //                 value: [],
  //               },
  //             },
  //             {
  //               id: 6,
  //               order: 6,
  //               label: 'Variable objet',
  //               description: '',
  //               type: 'object',
  //               dependantQuestions: [],
  //               options: [
  //                 {
  //                   id: '3A1mLmZY',
  //                   type: 'text',
  //                   label: 'Texte',
  //                   answer: {
  //                     value: 'Test',
  //                   },
  //                 },
  //                 {
  //                   id: 'v4SrcMzP',
  //                   type: 'number',
  //                   label: 'Nombre',
  //                   answer: {
  //                     value: 3,
  //                   },
  //                 },
  //                 {
  //                   id: 'b2RkcsoB',
  //                   type: 'bool',
  //                   label: 'Oui / non',
  //                   answer: {
  //                     value: false,
  //                   },
  //                 },
  //                 {
  //                   id: 'Cg5PsC6w',
  //                   type: 'date',
  //                   label: 'Date / heure',
  //                   answer: {
  //                     value: '12/02/1999',
  //                   },
  //                 },
  //               ],
  //             },
  //             {
  //               id: 7,
  //               order: 7,
  //               label: 'Variable choix',
  //               description: '',
  //               type: 'choice',
  //               dependantQuestions: [],
  //               options: ['Choix 1', 'Choix 2', 'Choix 3', 'Choix 4'],
  //               answer: {
  //                 value: 'Choix 2',
  //               },
  //             },
  //             {
  //               id: 8,
  //               order: 8,
  //               label: 'Variable tableau',
  //               description: '',
  //               type: 'array',
  //               dependantQuestions: [],
  //               options: [
  //                 {
  //                   id: 'AN2rBwb8',
  //                   type: 'text',
  //                   label: 'Texte',
  //                 },
  //                 {
  //                   id: '9JR5ObxD',
  //                   type: 'number',
  //                   label: 'Nombre',
  //                 },
  //                 {
  //                   id: '8TpGG7g3',
  //                   type: 'bool',
  //                   label: 'Personne physique',
  //                 },
  //                 {
  //                   id: 't2oXQ6qc',
  //                   type: 'date',
  //                   label: 'Date / heure',
  //                 },
  //               ],
  //               answer: {
  //                 value: [
  //                   {
  //                     AN2rBwb8: 'Lorem ipsum',
  //                     '9JR5ObxD': 2,
  //                     '8TpGG7g3': true,
  //                     t2oXQ6qc: '19/12/2022',
  //                   },
  //                   {
  //                     AN2rBwb8: 'Test 12345',
  //                     '9JR5ObxD': 21,
  //                     '8TpGG7g3': false,
  //                     t2oXQ6qc: '20/01/2023',
  //                   },
  //                 ],
  //               },
  //             },
  //             {
  //               id: 9,
  //               order: 9,
  //               label: 'Variable signature',
  //               description: '',
  //               type: 'unisign',
  //               dependantQuestions: [],
  //               answer: {
  //                 value: {
  //                   email: 'comeonlaw@comeonlaw.com',
  //                   firstName: '',
  //                   lastName: 'Law',
  //                 },
  //               },
  //             },
  //             {
  //               id: 10,
  //               order: 10,
  //               label: 'Variable signatures multiples',
  //               description: '',
  //               type: 'multisign',
  //               dependantQuestions: [],
  //               answer: {
  //                 value: [
  //                   {
  //                     email: 'comeonlaw@comeonlaw.com',
  //                     firstName: 'Comeon',
  //                     lastName: 'Law',
  //                   },
  //                   {
  //                     email: 'cedricdinont@comeonlaw.com',
  //                     firstName: 'Cédric',
  //                     lastName: 'Dinont',
  //                   },
  //                   {
  //                     email: 'cedricdinont@comeonlaw.com',
  //                     firstName: 'Cédric',
  //                     lastName: 'Dinont',
  //                   },
  //                   {
  //                     email: 'cedricdinont@comeonlaw.com',
  //                     firstName: 'Cédric',
  //                     lastName: 'Dinont',
  //                   },
  //                   {
  //                     email: 'cedricdinont@comeonlaw.com',
  //                     firstName: 'Cédric',
  //                     lastName: 'Dinont',
  //                   },
  //                   {
  //                     email: 'cedricdinont@comeonlaw.com',
  //                     firstName: 'Cédric',
  //                     lastName: 'Dinont',
  //                   },
  //                 ],
  //               },
  //             },
  //             {
  //               id: 11,
  //               order: 11,
  //               label: 'Variable multi-array',
  //               description: '',
  //               type: 'multiarray',
  //               dependantQuestions: [],
  //               options: [
  //                 {
  //                   id: 1,
  //                   order: 1,
  //                   type: 'Personne physique',
  //                   fields: [
  //                     {
  //                       id: '1',
  //                       type: 'text',
  //                       label: 'Nom',
  //                     },
  //                     {
  //                       id: '2',
  //                       type: 'text',
  //                       label: 'Prenom',
  //                     },
  //                   ],
  //                 },
  //                 {
  //                   id: 2,
  //                   order: 2,
  //                   type: 'Personne morale',
  //                   fields: [
  //                     {
  //                       id: '1',
  //                       type: 'text',
  //                       label: 'Dénomination sociale',
  //                     },
  //                     {
  //                       id: '2',
  //                       type: 'number',
  //                       label: 'Capital social',
  //                     },
  //                   ],
  //                 },
  //               ],
  //               answer: {
  //                 value: [
  //                   {
  //                     id: 1,
  //                     typeId: 1,
  //                     type: 'Personne physique',
  //                     fields: [
  //                       {
  //                         id: '1',
  //                         label: 'Prenom',
  //                         value: 'Bastien',
  //                       },
  //                       {
  //                         id: '2',
  //                         label: 'Nom',
  //                         value: 'Pemeja',
  //                       },
  //                     ],
  //                   },
  //                   {
  //                     id: 2,
  //                     typeId: 2,
  //                     type: 'Personne morale',
  //                     fields: [
  //                       {
  //                         id: '1',
  //                         label: 'Dénomination sociale',
  //                         value: 'ComeOnLaw',
  //                       },
  //                       {
  //                         id: '2',
  //                         label: 'Capital social',
  //                         value: '123456',
  //                       },
  //                     ],
  //                   },
  //                   {
  //                     id: 3,
  //                     typeId: 3,
  //                     type: 'Personne morale',
  //                     fields: [
  //                       {
  //                         id: '1',
  //                         label: 'Dénomination sociale',
  //                         value: 'Marvell',
  //                       },
  //                       {
  //                         id: '2',
  //                         label: 'Capital social',
  //                         value: '654321',
  //                       },
  //                     ],
  //                   },
  //                 ],
  //               },
  //             },
  //           ],
  //         },
  //         {
  //           id: 2,
  //           name: 'Nom de la page 2',
  //           description: '',
  //           questions: [
  //             {
  //               id: 12,
  //               order: 1,
  //               label: 'Variable nombre',
  //               description:
  //                 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum rutrum elit id nunc egestas, et tempus erat molestie. Ut porta enim id arcu lobortis, at aliquam ligula rutrum. Morbi eget pulvinar ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla facilisi. In vitae massa mauris.',
  //               type: 'number',
  //               dependantQuestions: [
  //                 {
  //                   id: 7,
  //                   value: 'Choix 1',
  //                 },
  //               ],
  //               answer: {
  //                 value: 3,
  //               },
  //             },
  //             {
  //               id: 13,
  //               order: 2,
  //               label: 'Variable texte',
  //               description: '',
  //               type: 'text',
  //               dependantQuestions: [
  //                 {
  //                   id: 7,
  //                   value: 'Choix 1',
  //                 },
  //               ],
  //               answer: {
  //                 value: '',
  //               },
  //             },
  //           ],
  //         },
  //         {
  //           id: 3,
  //           name: 'Nom de la page 3',
  //           questions: [
  //             {
  //               id: 14,
  //               order: 1,
  //               label: 'Variable nombre',
  //               description:
  //                 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum rutrum elit id nunc egestas, et tempus erat molestie. Ut porta enim id arcu lobortis, at aliquam ligula rutrum. Morbi eget pulvinar ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla facilisi. In vitae massa mauris.',
  //               type: 'number',
  //               dependantQuestions: [],
  //               answer: {
  //                 value: 3,
  //               },
  //             },
  //             {
  //               id: 15,
  //               order: 2,
  //               label: 'Variable oui / non',
  //               description:
  //                 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum rutrum elit id nunc egestas, et tempus erat molestie. Ut porta enim id arcu lobortis, at aliquam ligula rutrum. Morbi eget pulvinar ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla facilisi. In vitae massa mauris.',
  //               type: 'bool',
  //               dependantQuestions: [],
  //               answer: {
  //                 value: false,
  //               },
  //             },
  //             {
  //               id: 16,
  //               order: 3,
  //               label: 'Variable oui / non',
  //               type: 'bool',
  //               dependantQuestions: [],
  //               answer: {
  //                 value: true,
  //               },
  //             },
  //             {
  //               id: 17,
  //               order: 4,
  //               label: 'Variable texte',
  //               type: 'text',
  //               dependantQuestions: [
  //                 {
  //                   id: 15,
  //                   value: true,
  //                 },
  //                 {
  //                   id: 16,
  //                   value: true,
  //                 },
  //               ],
  //               answer: {
  //                 value: '',
  //               },
  //             },
  //             {
  //               id: 18,
  //               order: 18,
  //               label: 'Variable signatures multiples',
  //               description: '',
  //               type: 'multisign',
  //               dependantQuestions: [],
  //               answer: {
  //                 value: [],
  //               },
  //             },
  //           ],
  //         },
  //       ],
  //     }),
  //   )
  // }),
]
