<script lang="ts" setup>
import { ref, Ref } from 'vue'
import { Notify, Dialog, useQuasar } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { Project } from '@/models/projects/Project'
import AddSubProjectTreeDialog from '@/components/projects/dialog/project_parameters/AddSubProjectsTreeDialog.vue'
import ConfirmDeleteDialog from '@/components/common/dialog/ConfirmDeleteDialog.vue'
import ProjectDeepRights from '@/components/projects/execution/ProjectDeepRights.vue'
import DocumentsDeepRights from '@/components/projects/execution/DocumentsDeepRights.vue'

interface Props {
  project: Project
}
const props = defineProps<Props>()

const $q = useQuasar()

const userApi: UserApi = useApi()
const isLoading: Ref<boolean> = ref(false)

function onManageDeepRightsClick() {
  const dialog = Dialog.create({
    component: ProjectDeepRights,
    componentProps: {
      clientId: "3034ab86-fe2a-4068-b49b-c14d6b02fe00"
    },
  })
    .onOk(() => {
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function onManageDocumentsDeepRightsClick() {
  const dialog = Dialog.create({
    component: DocumentsDeepRights,
    componentProps: {
      clientId: "3034ab86-fe2a-4068-b49b-c14d6b02fe00"
    },
  })
    .onOk(() => {
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}
</script>

<template>
  <div style="margin-bottom: 10px; font-weight: 500">Permissions</div>
  <a href="#" @click="onManageDocumentsDeepRightsClick()" class="col-auto button-link import-from-lop-button-link">Gérer
    les
    permissions des documents</a>
  <br />
  <a href="#" @click="onManageDeepRightsClick()" class="col-auto button-link import-from-lop-button-link">Gérer les
    permissions des projets juridiques</a>
</template>

<style lang="scss" scoped></style>
