<script setup lang="ts">
import { useRoute } from 'vue-router'
import { ref, inject } from 'vue'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { Task } from '@/models/projects/Task'
import { ProjectKey } from '@/models/symbols'

interface Props {
  task: Task | undefined
  isDisable?: boolean
  isReadonly?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isDisable: false,
  isReadonly: false,
})

const route = useRoute()
const userApi: UserApi = useApi()

const isLoading = ref(false)

const project = inject(ProjectKey)

const emit = defineEmits(['updateEvents'])

const progressionOptions = [
  { value: 'not_started', label: 'Non commencée' },
  { value: 'started', label: 'En cours' },
  { value: 'finished', label: 'Terminée' },
]

function getProgressionLabel(status) {
  if (status == 'not_started') {
    return 'Non commencée'
  }
  if (status == 'started') {
    return 'En cours'
  }
  if (status == 'finished') {
    return 'Terminée'
  }
  return ''
}

function getProgressionColor(task: Task): string {
  let color = 'indigo-2'
  switch (task.status) {
    case 'finished':
      color = 'green-2'
      break
    case 'not_started':
      color = 'grey-4'
      break
    case 'started':
      color = 'indigo-2'
      break
    default:
      break
  }
  return color
}

async function onChange(event) {
  try {
    isLoading.value = true
    await userApi.putProjectTask(project?.value.id, props.task?.id, {
      status: event,
      client: route.params.clientId,
    })

    const new_event = await userApi.createProjectEvent(project?.value.id, {
      type: 1,
      user_project_id: project?.value.id,
      legacy_task_id: props.task?.id,
    })
    emit('updateEvents', new_event)

    isLoading.value = false
  } catch (error) {
    isLoading.value = false
  }
}
</script>
<template>
  <q-chip
    v-if="task"
    :clickable="!isDisable"
    :color="getProgressionColor(task)"
    text-color="white_color"
    style="margin-left: 0px"
  >
    {{ isDisable ? getProgressionLabel(task.status) : '' }}
    <q-select
      v-if="!isDisable || isReadonly"
      :loading="isLoading"
      :disable="isLoading || isDisable"
      :readonly="isReadonly"
      borderless
      options-dense
      v-model="task.status"
      :options="progressionOptions"
      emit-value
      map-options
      @update:model-value="onChange"
      class="progression-select"
    />
  </q-chip>
</template>

<style lang="scss" scoped>
.progression-select {
  font-size: 13px;
}

:deep(.q-field__append) {
  padding-left: 4px;
}
</style>
