<script setup lang="ts">
import { ref, Ref } from 'vue'
import { useDialogPluginComponent, Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseDialog from '@/components/base/BaseDialog.vue'
import BaseInput from '@/components/base/BaseInput.vue'

interface Props {
  selectedProject: any
  mode?: string
}
const props = withDefaults(defineProps<Props>(), {
  mode: 'ADD',
})

const emit = defineEmits([...useDialogPluginComponent.emits, 'editItemClick'])

const userApi: UserApi = useApi()

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const name: Ref<string> = ref(props.selectedProject.baseName)
const isLoading: Ref<boolean> = ref(false)

async function updateItem() {
  try {
    isLoading.value = true
    await userApi.updateProject(props.selectedProject.id, {
      name: name.value,
    })
    props.selectedProject.baseName = name.value
    isLoading.value = false
    Notify.create({
      message: 'Dossier renommé',
      type: 'primary',
    })
    emit('ok', name.value)
  } catch (e) {
    dialogRef.value?.hide()
    Notify.create({
      message: 'Erreur lors du renommage du dossier',
      type: 'negative',
    })
    isLoading.value = false
  }
}
</script>

<template>
  <q-dialog ref="dialogRef">
    <BaseDialog
      title="Renommer le dossier"
      :is-dialog-loading="isLoading"
      @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()"
    >
      <template #body>
        <BaseInput
          v-model="name"
          label="Nom *"
          lazy-rules
          :rules="[(val) => (val && val.length > 0) || 'Le nom du projet est requis']"
        >
        </BaseInput>
      </template>
      <template #actions>
        <q-btn flat color="primary" @click="updateItem()" label="OK" />
      </template>
    </BaseDialog>
  </q-dialog>
</template>
