<script async setup lang="ts">
import { useRouter } from 'vue-router'
import { ref, computed, watch, nextTick } from 'vue'
import { Notify } from 'quasar'
import { removeAccents } from '@/utils/stringUtils'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import { getNode } from '@/components/projects/execution/documents/DocumentsCommon'
import projectCatalog from './ProjectCatalog'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'

interface Props {
  mode?: string
  onlyComposables?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  mode: 'addSubProjects',
  onlyComposables: false,
})

const router = useRouter()

const emit = defineEmits(['createProjectWithSubprojects', 'addSubProjects', 'createBasicDocuments'])

const userApi: UserApi = useApi()

const authenticatedUser = useAuthenticatedUser()
const environmentId = authenticatedUser.environment.pkid

const ticked = ref([])
const expandedNodes = ref([])
const searchText = ref('')
const treeFilter = ref('')
const treeRef = ref(null)
const projectsToCreate = ref(null)

const nodes = ref([])

let personalModels = await userApi.getSubProjectPersonalModels()

function parsePersonalModels(personalModels) {
  const result = []

  personalModels.forEach((personalModel) => {
    console.log("personalModel", personalModel)
    const personalModelNode = {
      id: personalModel.id,
      label: personalModel.name,
      type: 'personalProject',
      legacyProjectId: personalModel.legacyProjectId,
      url: personalModel.url,
      // children: [],
    }

    result.push(personalModelNode)
  })

  console.log("personalModels select sub projects", result)
  return result
}

const platformCatalogResponse = await userApi.getPlatformProjectCatalog()
const environmentCatalogResponse = await userApi.getEnvironmentProjectCatalog(environmentId)

const catalog = platformCatalogResponse.catalogJson[0].children[0]

function filterEnvironmentCatalogNodes(nodes, parent) {
  if (nodes == undefined) {
    return
  }

  const childrenToRemove = []

  nodes.forEach((node) => {
    if (node.type == 'project' && !environmentCatalogResponse.catalogJson.includes(node.id)) {
      childrenToRemove.push(node)
    }

    if (props.onlyComposables) {
      // console.log("only composables")
      if (node.type == 'project' && !node.label.startsWith('Démo AG composé')) {
        childrenToRemove.push(node)
      }
    }

    filterEnvironmentCatalogNodes(node.children, node)
  })

  if (parent != undefined) {
    console.log('childrenToRemove', childrenToRemove)
    parent.children = nodes.filter((node) => {
      return !childrenToRemove.includes(node)
    })
  }
}

filterEnvironmentCatalogNodes(catalog.children, undefined)

nodes.value = [
  {
    id: 'root',
    label: '',
    type: 'root',
    children: [
      {
        id: 'personalModels',
        label: 'Modèles personnels',
        type: 'subject',
        children: parsePersonalModels(personalModels),
      },
      catalog,
    ]
  }
]

function addSearchText(node) {
  let currentNode = node

  if (node.searchText == undefined) {
    node.searchText = ''
  }
  node.searchText = node.searchText + ' ' + node.label

  while (currentNode != undefined) {
    node.searchText = node.searchText + ' ' + currentNode.label
    currentNode = currentNode.parent
  }
}

function parseNodes(nodes, parent) {
  if (nodes == undefined) {
    return
  }
  nodes.forEach((node) => {
    node.parent = parent
    if (node.type === 'root') {
      node.tickable = false
      node.noTick = true
    } else if (node.type === 'subject') {
      node.tickable = false
      node.noTick = true
      node.textStyle = 'font-size: 16px; font-weight: 700;'
    } else if (node.type === 'category') {
      node.tickable = false
      node.noTick = true
      node.textStyle = 'font-size: 16px; font-weight: 600;'
    } else if (node.type === 'subCategory') {
      node.tickable = false
      node.noTick = true
      node.textStyle = 'font-size: 16px; font-weight: 500;' // font-style: italic;"
    } else if ((node.type === 'project') || (node.type === 'personalProject')) {
      node.tickable = true
      node.noTick = false
      node.icon = 'o_source'
      node.iconColor = 'primary'
      node.iconStyle = 'margin-top: -4px; margin-right: 5px; color: #4068c8;'
      node.textStyle = 'color: #4068c8;'
    } else if (node.type === 'basicModels') {
      node.tickable = true
      node.noTick = true
      node.textStyle = 'font-size: 16px; font-weight: 500;'
    } else if (node.type === 'basicModel') {
      node.tickable = true
      node.noTick = false
      node.icon = 'o_description'
      node.iconStyle = 'margin-top: -4px; margin-right: 5px; color: #22633f;'
      node.textStyle = 'color: #22633f;'
    }
    addSearchText(node)
    parseNodes(node.children, node)
  })
}

parseNodes(nodes.value, undefined)

function filterNodes(nodes, parent) {
  if (nodes == undefined) {
    return
  }

  const childrenToRemove = []

  nodes.forEach((node) => {
    if (props.mode == 'addSubProjects' || props.mode == 'createProjectWithSubprojects') {
      if (node.type == 'basicModels' || node.type == 'basicModel') {
        childrenToRemove.push(node)
      }
    } else if (props.mode == 'createBasicDocuments') {
      if (node.type == 'project') {
        childrenToRemove.push(node)
      }
    }

    filterNodes(node.children, node)
  })

  if (parent != undefined) {
    console.log('childrenToRemove', childrenToRemove)
    parent.children = nodes.filter((node) => {
      return !childrenToRemove.includes(node)
    })
  }
}

filterNodes(nodes.value, undefined)

function removeEmptySections(nodes, parent) {
  if (nodes == undefined) {
    return
  }

  nodes.forEach((node) => {
    removeEmptySections(node.children, node)
  })

  console.log(parent, nodes.length)
  if (parent && parent.children.length == 0) {
    if (parent.parent) {
      parent.parent.children = parent.parent.children.filter((node) => {
        return node !== parent
      })
    }
  }
}

removeEmptySections(nodes.value, undefined)

function searchProjects() {
  console.log('searchProjects', searchText.value)

  if (searchText.value === '') {
    // treeRef.value.collapseAll();
    expandedNodes.value = ['root']
    treeRef.value.expandAll()
  } else {
    treeRef.value.expandAll()
  }

  treeFilter.value = searchText.value
}

function resetSearch() {
  searchText.value = ''
  searchProjects()
}

function applyTreeFilter(node, filter) {
  // console.log("applyTreeFilter", node, filter);

  const nodeNameLowerCase = removeAccents(node.searchText.toLowerCase())
  const filterLowerCase = removeAccents(filter.toLowerCase())

  const filterWords = filterLowerCase.split(' ')
  const foundAllWords = filterWords.every((filterWord) => {
    return removeAccents(nodeNameLowerCase).includes(filterWord)
  })

  if (foundAllWords && filter !== '') {
    // expandToNode2(documentTreeNodes, node.id, expandedNodes);
  }

  return foundAllWords
}

function createElements() {
  if (props.mode == 'addSubProjects') {
    addSubProjects()
  } else if (props.mode == 'createProjectWithSubprojects') {
    createProjectWithSubprojects()
  } else if (props.mode == 'createBasicDocuments') {
    createBasicDocuments()
  }
}

function createProjectWithSubprojects() {
  let projectsTemplateIds = [0]

  if (ticked.value.length !== 0) {
    projectsTemplateIds = ticked.value
  }
  const projectsTemplateIdsString = projectsTemplateIds.join(',')
  emit('createProjectWithSubprojects', projectsTemplateIdsString)
}

function createBasicDocuments() {
  const basicModels = []

  ticked.value.forEach((basicModelId) => {
    const node = getNode(nodes.value[0].children, basicModelId)

    basicModels.push({
      id: basicModelId,
      fileName: node.fileName,
    })
  })

  emit('createBasicDocuments', basicModels)
}

function addSubProjects() {
  console.log('addSubProjects', ticked.value)
  let projectsTemplateIds = [0]

  if (ticked.value.length !== 0) {
    projectsTemplateIds = ticked.value
  }
  const projectsTemplateIdsString = projectsTemplateIds.join(',')
  emit('addSubProjects', projectsTemplateIdsString)
}

watch(ticked, () => {
  nextTick(() => {
    setScrollAreaHeight()
  })
})

const scrollAreaOffset = ref(0)

function setScrollAreaHeight() {
  scrollAreaOffset.value = 0
  console.log(
    'projectsToCreate.value',
    projectsToCreate.value,
    projectsToCreate.value.$el,
    projectsToCreate.value.clientHeight,
  )
  if (projectsToCreate.value && projectsToCreate.value) {
    scrollAreaOffset.value = projectsToCreate.value.clientHeight + 24
  }
  // scrollAreaOffset.value += 1
  console.log(scrollAreaOffset.value)
}

const scrollAreaStyle = computed(() => {
  console.log('computed', scrollAreaOffset.value)
  let width = '100%'
  if (treeRef.value != null) {
    console.log('treeRef', treeRef.value.$el, treeRef.value.$el.clientWidth)
    width = treeRef.value.$el.clientWidth + 'px'
  }
  return {
    width: width,
    height: 'calc(100vh - 320px - ' + scrollAreaOffset.value + 'px)',
  }
})

const thumbStyle = {
  right: '1px',
  borderRadius: '5px',
  width: '5px',
  color: 'rgb(71, 71, 71)',
  opacity: '0.2',
  zIndex: 10,
  // backgroundColor: '#027be3',
}

function getNode(nodes, nodeId) {
  for (let i = 0; i < nodes.length; ++i) {
    const currentNode = nodes[i]
    if (currentNode.id === nodeId) {
      return currentNode
    }
    if (!currentNode.children) {
      continue
    }
    const childNode = getNode(currentNode.children, nodeId)
    if (childNode !== undefined) {
      return childNode
    }
  }
  return undefined
}

function getCompleteProjectPath(nodeId) {
  const node = getNode(nodes.value[0].children, nodeId)
  let completePath = node.label
  let currentNode = node.parent
  while (currentNode.type != 'root') {
    completePath = currentNode.label + ' / ' + completePath
    currentNode = currentNode.parent
  }
  return completePath
}

function removeProject(project) {
  ticked.value = ticked.value.filter((tickedProject) => {
    return tickedProject !== project
  })
}


function isPersonalModel(element) {
  return element.type === 'personalProject'
}

async function removeFromPersonalModels(node) {
  console.log("removing from personal models", node)
  await userApi.removeSubProjectFromPersonalModels(node.legacyProjectId)
  personalModels = await userApi.getSubProjectPersonalModels()
  nodes.value[0].children[0].children = parsePersonalModels(personalModels)
  filterNodes(nodes.value, undefined)
  Notify.create({
    message: `Dossier supprimé des modèles personnels`,
    type: 'primary',
  })
}

function onMenuClick(event) {
  console.log('on menu click', event)
  event.preventDefault()
}

function goToPersonalModelProject(node) {
  console.log("goToPersonalModelProject", node)
  window.open(node.url, '_blank')
}
</script>

<template>
  <div>
    <div class="row no-wrap" style="width: 100%">
      <div class="col" style="background-color: white; width: 150px"></div>
      <div class="col-auto" style="background-color: white">
        <div class="col">
          <div class="row">
            <div v-if="mode != 'createBasicDocuments'" class="page-content-header">
              Sélectionnez les projets juridiques à ajouter :
            </div>
            <div v-if="mode == 'createBasicDocuments'" class="page-content-header">
              Sélectionnez les modèles de documents à ajouter :
            </div>
          </div>

          <div class="row">
            <q-input dense outlined v-model="searchText" @keydown.enter.prevent="searchProjects" class="search-input"
              @update:model-value="searchProjects">
              <template v-slot:append>
                <q-icon v-if="searchText === ''" name="search" size="" />
                <q-icon v-else name="clear" class="cursor-pointer" @click="resetSearch" />
              </template>
            </q-input>
          </div>

          <div class="row">
            <q-scroll-area :style="scrollAreaStyle" :thumb-style="thumbStyle">
              <!-- 230 -->
              <!-- <div class="row no-wrap" style="width: 100%;"> -->
              <!-- <div class="col" style="background-color: white;"></div> -->
              <!-- <div class="col-auto" style="background-color: white;"> -->
              <q-tree dense :nodes="nodes" node-key="id" v-model:ticked="ticked" v-model:expanded="expandedNodes"
                default-expand-all tick-strategy="strict" :filter="treeFilter" :filter-method="applyTreeFilter"
                ref="treeRef" no-results-label="Aucun modèle trouvé">
                <template v-slot:default-header="prop">
                  <div style="padding: 0px; overflow: hidden"
                    class="row no-wrap justify-start items-start content-start full-width items-center">
                    <div v-if="prop.node.type === 'root'">&nbsp;</div>
                    <div v-if="prop.node.type !== 'root'"
                      class="row  no-wrap justify-start items-start content-start full-width items-center">
                      <div class="col-auto">
                        <q-icon v-if="prop.node.icon" :name="prop.node.icon" :style="prop.node.iconStyle"></q-icon>
                        <span :style="prop.node.textStyle">{{ prop.node.label }}</span>
                      </div>

                      <!-- space filler -->
                      <div class="col" style="overflow: hidden"></div>

                      <div class="col-auto" style="overflow: hidden">
                        <span class="row no-wrap items-center" style="text-align: center; line-height: 36px">
                          <q-icon v-if="isPersonalModel(prop.node)" name="o_snippet_folder" size="18px" class="q-ml-sm" />
                          <q-btn-dropdown v-if="isPersonalModel(prop.node)" flat rounded dropdown-icon="more_vert"
                            style="padding-left: 8px; padding-right: 8px" clickable ref="menuRef"
                            @click.stop="onMenuClick">
                            <q-list dense>
                              <q-item clickable v-close-popup @click="goToPersonalModelProject(prop.node)">
                                <q-item-section>
                                  <q-item-label>Aller au dossier</q-item-label>
                                </q-item-section>
                              </q-item>
                              <q-item clickable v-close-popup @click="removeFromPersonalModels(prop.node)">
                                <q-item-section>
                                  <q-item-label>Supprimer des modèles personnels</q-item-label>
                                </q-item-section>
                              </q-item>

                            </q-list>
                          </q-btn-dropdown>
                        </span>
                      </div>


                    </div>
                  </div>
                </template>
              </q-tree>
              <!-- </div> -->
              <!-- <div class="col" style="background-color: white"></div> -->
              <!-- </div> -->
            </q-scroll-area>
          </div>
        </div>
      </div>
      <div class="col" style="background-color: white"></div>
    </div>
    <!-- search -->

    <q-separator v-if="ticked.length > 0" style="margin-top: 8px" />

    <div class="" ref="projectsToCreate" id="projectsToCreate">
      <div class="col-auto" style="margin-left: 16px; margin-right: 16px" v-if="ticked.length > 0">
        <div v-if="mode != 'createBasicDocuments'" class="page-content-header">Projets juridiques à créer :</div>

        <div v-if="mode == 'createBasicDocuments'" class="page-content-header">Documents à créer :</div>

        <!-- <ul class="ul">
              <li v-for="tickedProject in ticked">
                {{ getCompleteProjectPath(tickedProject) }}
              </li>
            </ul> -->

        <q-list dense bordered separator>
          <q-item v-for="tickedProject in ticked">
            <q-item-section>{{ getCompleteProjectPath(tickedProject) }}</q-item-section>
            <q-item-section side>
              <q-btn rounded dense flat icon="clear" @click="removeProject(tickedProject)"></q-btn>
            </q-item-section>
          </q-item>
        </q-list>
      </div>
    </div>
    <!-- projects to create -->

    <div class="row" style="margin-top: 16px">
      <div class="col"></div>
      <div class="col-auto">
        <q-btn label="Valider" :disable="ticked.length == 0" style="" color="primary" @click="createElements" />
      </div>
    </div>
    <!-- row button -->
  </div>
  <!-- main div -->
</template>

<style lang="scss" scoped>
.ul {
  list-style-type: disc;
}

:deep(.q-checkbox__inner) {
  color: rgb(158, 158, 158);

  margin-right: 5px;
  font-size: 34px; // influe sur la taille de la checkbox
}

// Hide tree root node
:deep(.q-tree > .q-tree__node > .q-tree__node-header) {
  height: 0px;
  width: 0px;
  overflow: hidden;
}

// change tree checkbox style
:deep(.q-checkbox__bg) {
  border: 1.3px solid currentColor;
}

.search-input {
  width: 500px;
  margin-left: 0px;
  margin-bottom: 16px;
  margin-top: 16px;
}

.subtitle {
  font-size: 18px;
}

.category-header {
  background-color: #227a68;
  color: white;
  font-size: 18px;
  font-weight: 700;
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  // cursor: pointer;
}

.category-subheader {
  background-color: #2a5d97;
  color: white;
  font-size: 16px;
  font-weight: 700;
  margin-left: 30px;
  margin-top: 10px;
  padding-left: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  // cursor: pointer;
}

.project-category {
  color: rgb(61, 61, 63);
  font-size: 16px;
  font-weight: 700;
  padding-left: 10px;
}

.available-project {
  cursor: pointer;
  font-weight: 500;
}

.page-content-header {
  // margin-top: 16px;
  font-size: 16px;
  font-weight: 500;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
