<script setup lang="ts">
import { ref, Ref, provide, onMounted, watch, inject } from 'vue'
import { ProjectKey, ProjectAccountableOptionsKey } from '@/models/symbols'
import { useRoute, useRouter } from 'vue-router'
import { Project } from '@/models/projects/Project'
import { Task } from '@/models/projects/Task'
import { TaskFilters } from '@/models/projects/TaskFilters'
import { useStore } from '@/store/store'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import ProjectTaskDetails from './ProjectTaskDetails.vue'
import TitleBar from './TitleBar.vue'
import ProjectExecutionOptions from '@/components/projects/execution/ProjectExecutionOptions.vue'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import { UserAccountableOptions } from '@/models/projects/UserAccountableOptions'
import Documents from './documents/Documents.vue'
import SubProjects from './subprojects/SubProjects.vue'
import { scroll } from 'quasar'
import DocumentEditor from '@/pages/platform/v2/DocumentEditor.vue'
const { getScrollTarget, setVerticalScrollPosition } = scroll
import { isInViewport } from '@/utils/domUtils'

interface Props {
  projectId: string
}

const props = withDefaults(defineProps<Props>(), {
  projectId: '',
})

const documentId = inject('documentId')
const taskId = inject('taskId')

const store = useStore()
const route = useRoute()
const router = useRouter()
const userApi: UserApi = useApi()

store.commit('setPageTitleComponent', 'Exécution de projet')

const project = ref<Project>()
const splitterModel = ref(70)
const scrollAreaRef = ref(null)
const isPurchaseDialogVisible = ref(false)

const documentTreeNodes = ref([
  {
    id: 0,
    type: 0,
    children: [],
  },
])
provide('documentTreeNodes', documentTreeNodes)

const selectedNode = ref(null)
provide('documentTreeSelectedNode', selectedNode)

provide('isPurchaseDialogVisible', isPurchaseDialogVisible)
const usersFilter: Ref<Array<UserAccountableOptions>> = ref([])
provide('usersFilter', usersFilter)
const progressionsFilter: Ref<Array<any>> = ref([])
provide('progressionsFilter', progressionsFilter)
const filters: Ref<TaskFilters> = ref({
  hasFiles: false,
  isDeadlineOnly: false,
  showInformationTasks: false,
  assignees: [],
  status: [],
})
provide('globalFilters', filters)
const filter = ref('')

const authenticatedUser = useAuthenticatedUser()

project.value = await userApi.getProjectDetails(props.projectId)

project.value.isReadOnly = false

console.log('project', project.value)

///////////////////////////////////
// Project parameters
///////////////////////////////////

let documentsDefaultView = 'tree'
console.log('project', project.value.documentsDefaultView)
if (project.value.documentsDefaultView === 1) {
  documentsDefaultView = 'tree'
} else if (project.value.documentsDefaultView === 2) {
  documentsDefaultView = 'flat'
}

console.log('ProjectExecution', project.value.showDocuments, project.value.showSubProjects, documentsDefaultView)

// For no-code projects
if (project.value.showDocuments == undefined) {
  project.value.showDocuments = true
}
if (project.value.showSubProjects == undefined) {
  project.value.showSubProjects = true
}

project.value.parameters = {
  showDocuments: ref(project.value.showDocuments),
  showSubProjects: ref(project.value.showSubProjects),
  documentsDefaultView: ref(documentsDefaultView),
}

let compositeProject = null
project.value.subProjects.forEach((subProject) => {
  console.log("isComposite", subProject)
  if (subProject.legacyProject.composite) {
    compositeProject = subProject
  }
  subProject.disabled = false
})

if (compositeProject) {
  compositeProject.subProjects = []
  const projectsToRemove = []
  project.value.subProjects.forEach((subProject) => {
    console.log("isComposable", subProject)
    if (subProject.legacyProject.composable && subProject.legacyProject.compositeProjectId === compositeProject.legacyProject.id) {
      compositeProject.subProjects.push(subProject)
      projectsToRemove.push(subProject)
    }
  })

  projectsToRemove.forEach((projectToRemove) => {
    console.log("composite remove", projectToRemove)
    // project.value.subProjects.splice(project.value.subProjects.indexOf(projectToRemove), 1)
    projectToRemove.disabled = true
  })

  console.log("compositeProject", compositeProject)
}


///////////////////////////////////
// End project parameters
///////////////////////////////////

const documentTreeUpdateCounter = ref(0)
provide('documentTreeUpdateCounter', documentTreeUpdateCounter)

const documentSelected = ref(undefined)
provide('documentSelected', documentSelected)

const thumbStyle = {
  right: '1px',
  borderRadius: '5px',
  width: '5px',
  color: 'rgb(71, 71, 71)',
  opacity: '0.2',
  zIndex: 10,
  // backgroundColor: '#027be3',
}

// Set read only for ayming users
if (authenticatedUser.email.includes('ayming')) {
  project.value.isReadOnly = false
}

const accountableOptions = ref([])

accountableOptions.value = await userApi.getUsersWithProjectPermissions(props.projectId)

provide(ProjectKey, project)
provide(ProjectAccountableOptionsKey, accountableOptions)

const collapseTrees = ref(false)
provide('collapseTrees', collapseTrees)

const taskDetailsSelected = ref<Task>()
provide('taskDetailsSelected', taskDetailsSelected)

function onToggleShowInformationTasks(data) {
  console.log("onToggleShowInformationTasks", data)
  filters.value.showInformationTasks = data
}

function onToggleCollapse(data) {
  collapseTrees.value = data
}

function onToggleOnlyShowDocuments(data) {
  filters.value.hasFiles = data
}

function onToggleOnlyDeadline(data) {
  filters.value.isDeadlineOnly = data
}

watch(usersFilter, () => {
  if (usersFilter.value === null) {
    usersFilter.value = []
  }
  if (Array.isArray(usersFilter.value)) {
    filters.value.assignees = usersFilter.value.map((user) => {
      return user.value?.fullName
    })
  }
})

watch(progressionsFilter, () => {
  if (progressionsFilter.value === null) {
    progressionsFilter.value = []
  }
  filters.value.status = progressionsFilter.value.map((progression) => {
    return progression.value
  })
})

watch(filters.value, () => {
  filter.value = Math.random().toString()
})

const updateNb = ref(0)
const lastEvent = ref()
provide('lastEvent', lastEvent)

function onProjectNameClicked() {
  taskDetailsSelected.value = undefined
  if (store.state.user.portal === 'CLIENT') {
    router.push({
      name: 'projects/project',
      params: {
        projectId: route.params.projectId.toString(),
      },
    })
  } else {
    router.push({
      name: 'clients/client/projects/project',
      params: {
        clientId: route.params.clientId.toString(),
        projectId: route.params.projectId.toString(),
      },
    })
  }
}

let subProjects = project.value.subProjects.sort((a, b) => (a.order > b.order ? 1 : -1))

if (project.value.sections.length !== 0) {
  subProjects.push({
    id: project.value.legacyId,
    name: project.value.name,
    sections: project.value.sections,
    user_project_id: project.value.id,
    legacyId: project.value.legacyId,
  })
}

console.log("subProjects with composite", subProjects)

function onSubProjectTaskModified(event) {
  updateNb.value = updateNb.value + 1
  lastEvent.value = event
}

const projectsRef = ref(null)
provide('projectsRef', projectsRef)

const documentsRef = ref(null)

function scrollHandler(event) {
  console.log('scrollevent', event)
}

function scrollToTaskElement(target, el) {
  // const target = getScrollTarget(el)
  let offset = el.offsetTop

  while (el != target) {
    el = el.parentNode
    if (el.classList.contains('relative-position')) {
      offset += el.offsetTop
    }

    console.log('offset', offset, el.offsetTop, el)
  }

  if (documentsRef.value == null) {
    console.log('documentsRef is null')
  } else {
    console.log('documentsRef', documentsRef.value, documentsRef.value.$el)
  }

  offset += documentsRef.value.offsetHeight

  console.log('target', target, offset)
  const duration = 200
  setVerticalScrollPosition(target, offset, duration)
}

const projectExecutionScrollAreaRef = ref(null)
provide('projectExecutionScrollAreaRef', projectExecutionScrollAreaRef)

watch(projectExecutionScrollAreaRef, () => {
  console.log('projectExecutionScrollAreaRef', projectExecutionScrollAreaRef.value)
})

onMounted(() => {
  console.log('ProjectExecution - onMounted')
  // if (scrollAreaRef.value !== null && taskDetailsSelected.value !== undefined) {
  //   // const offset = document.getElementById(taskDetailsSelected.value.id);
  //   // const rect = offset.getBoundingClientRect();
  //   // scrollAreaRef.value.setScrollPosition("vertical", rect.y - 200, 300);
  // }

  if (taskId.value) {
    console.log('task selected - scrolling to it')
    const taskElement = document.getElementById(taskId.value)
    // const scrollTargetParent = document.getElementById("project-execution-scroll-area")
    const scrollTargetParent = projectExecutionScrollAreaRef.value.$el
    const scrollTarget = scrollTargetParent.querySelector('.q-scrollarea__container')
    console.log('scrollTarget', scrollTargetParent, scrollTarget, 'taskElement', taskElement)

    if (!isInViewport(taskElement)) {
      scrollToTaskElement(scrollTarget, taskElement)
    }
  }
})

function needPassword() {
  if (project.value.adminUserId) {
    const projectLocalStorage = localStorage.getItem(project.value.id)
    if (projectLocalStorage === null) {
      return true
    }
    const projectLocalStorageJson = JSON.parse(projectLocalStorage)
    if (projectLocalStorageJson.password === project.value.password) {

      console.log("timestamp", projectLocalStorageJson.timestamp, Date.now())
      if (projectLocalStorageJson.timestamp + (15 * 60 * 1000) < Date.now()) {
        localStorage.removeItem(project.value.id)
        return true
      }

      localStorage.setItem(project.value.id, JSON.stringify({
        password: project.value.password,
        timestamp: Date.now(),
      }))

      return false
    }

    return true
  }
  return false
}

const projectPassword = ref('')
const badPassword = ref(false)

function onValidatePassword() {
  if (projectPassword.value === project.value.password) {
    console.log('good password')
    localStorage.setItem(project.value.id, JSON.stringify({
      password: projectPassword.value,
      timestamp: Date.now(),
    }))
    window.location.reload()
  } else {
    console.log('wrong password')
    badPassword.value = true
  }
}

const recentProjects = inject("recentProjects")

onMounted(async () => {
  recentProjects.value = await userApi.getRecentlyViewedProjects(1, '', 5)
})

async function onPermissionsChanged() {
  console.log('onPermissionsChanged')
  accountableOptions.value = await userApi.getUsersWithProjectPermissions(props.projectId)
  console.log('accountableOptions', accountableOptions.value)
  // updateNb.value = updateNb.value + 1
}
</script>

<template>
  <div class="no-wrap">
    <Teleport to="#pageTitleComponent">
      <TitleBar @toggle-collapse="onToggleCollapse" @toggle-only-show-documents="onToggleOnlyShowDocuments"
        @toggle-only-deadline="onToggleOnlyDeadline" @project-name-clicked="onProjectNameClicked"
        @permissions-changed="onPermissionsChanged">
      </TitleBar>
    </Teleport>

    <div v-if="needPassword()" style="margin: 16px;">
      <h4 style="font-size: 24px;">Dossier protégé par mot de passe</h4>
      <div>
        Veuillez entrer le mot de passe pour accéder au dossier
      </div>
      <q-input dense outlined v-model="projectPassword" label="Mot de passe" type="password"
        style="width: 350px; margin-top: 16px;" />
      <div v-if="badPassword" style="color: red;">
        Mauvais mot de passe
      </div>
      <q-btn dense color="primary" label="Valider" @click="onValidatePassword"
        style="display: inline-block; margin-top: 16px; margin-left: 290px;" />
    </div>


    <q-splitter v-if="!needPassword()" v-model="splitterModel" :limits="[20, 100]" class="splitter"
      id="page-scroll-container-2">
      <template v-slot:before>
        <!-- <div class="scroll overflow-hidden"> -->
        <q-scroll-area style="height: 100%; background-color: white" :thumb-style="thumbStyle"
          class="scroll overflow-hidden" id="project-execution-scroll-area" ref="projectExecutionScrollAreaRef">
          <div v-if="!project?.parameters?.showDocuments && !project?.parameters?.showSubProjects">
            <div class="row" style="height: 100px; padding-top: 12px">
              <div class="col" style=""></div>
              <span class="col-auto vertical-middle" style="font-size: 16px; padding-top: 25px">
                Les zones Documents et Projets juridiques ont toutes les deux été désactivées dans les paramètres du
                dossier.
              </span>
              <div class="col" style=""></div>
            </div>
          </div>

          <div v-if="project?.parameters?.showDocuments" ref="documentsRef">
            <Suspense>
              <Documents v-if="project?.id.length === 36"></Documents>
              <template #fallback>
                <div>
                  <!-- need a single root node for suspense slots -->
                  <div class="sticky-content">
                    <div style="margin-left: 8px; margin-right: 8px; padding-top: 4px; padding-bottom: 4px"
                      class="row no-wrap items-center">
                      <span class="col-auto"
                        style="font-size: 19px; font-weight: 700; color: rgb(64, 104, 200); padding-right: 8px">Documents</span>
                    </div>
                  </div>
                  <div class="row" style="height: 100px; padding-top: 12px">
                    <div class="col" style=""></div>
                    <span class="col-auto vertical-middle" style="font-size: 16px; padding-top: 25px">
                      <q-spinner color="primary" size="2em" style="margin-right: 24px" />Chargement des
                      documents...</span>
                    <div class="col" style=""></div>
                  </div>
                </div>
              </template>
            </Suspense>
          </div>

          <div v-if="project?.parameters?.showDocuments" class="page-section-separator">
            <q-separator></q-separator>
          </div>

          <div v-if="project?.parameters?.showSubProjects" class="sub-projects-content">
            <ProjectExecutionOptions @toggle-show-information-tasks="onToggleShowInformationTasks"
              @toggle-collapse="onToggleCollapse" @toggle-only-show-documents="onToggleOnlyShowDocuments"
              @toggle-only-deadline="onToggleOnlyDeadline" @project-name-clicked="onProjectNameClicked"
              ref="projectsRef">
            </ProjectExecutionOptions>

            <div v-if="subProjects.length == 0" class="row" style="height: 100px; padding-top: 12px">
              <div class="col"></div>
              <span class="col-auto vertical-middle" style="font-size: 16px; padding-top: 25px">Aucun projet
                juridique</span>
              <div class="col"></div>
            </div>

            <SubProjects :sub-projects="subProjects" @subProjectTaskModified="onSubProjectTaskModified($event)">
            </SubProjects>

            <!-- <div class="content-filler"></div> -->
          </div>
          <!-- </div> -->
        </q-scroll-area>
      </template>

      <template v-slot:after>
        <q-scroll-area style="height: 100%" :thumb-style="thumbStyle">
          <div class="col-4">
            <ProjectTaskDetails :task="taskDetailsSelected" :updateNb="updateNb" :documentId="documentId">
            </ProjectTaskDetails>
          </div>
        </q-scroll-area>
      </template>
    </q-splitter>
  </div>
</template>

<style lang="scss">
@import '@/styles/app.variables.scss';

.splitter {
  height: calc(100vh - $header_height - 3px);
}

.thumb {
  right: 1px;
  // borderRadius: 5px;
  width: 5px;
  color: rgb(71, 71, 71);
  opacity: 0.2;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.page-section-separator {
  background-color: $page_background_color;
  margin-top: 0px;
  padding-bottom: 8px;
}

.sub-projects-content {
  background-color: $page_content_background_color;
  padding-bottom: 16px;
}

.content-filler {
  height: calc(100% - 2px);
  background-color: white;
}

// Needed because q-scroll-area interfer in tree: horizontal scrollbar appears
#project-execution-scroll-area>div:first-child>div:first-child {
  position: relative !important;
}

.sticky-content {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 2;
  background-color: $page_background_color;
}
</style>
