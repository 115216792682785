<script lang="ts" setup>
import { ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useQuasar, Dialog, Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import { useStore } from '@/store/store'
import UserApi from '@/services/api/core/UserApi'
import { Survey } from '@/models/survey/Survey'
import { Project } from '@/models/projects/Project'
import { Question } from '@/models/survey/Question'
import { DependantQuestion } from '@/models/survey/DependantQuestion'
import BaseFooter from '@/components/base/BaseFooter.vue'
import SurveySummaryInput from '@/components/survey/SurveySummaryInput.vue'
import SurveyDocumentGenerationDialog from '@/components/survey/dialog/SurveyDocumentGenerationDialog.vue'

const $q = useQuasar()
const router = useRouter()
const store = useStore()
const userApi: UserApi = useApi()
const route = useRoute()
const survey = ref<Survey>()
const isChoicesEmpty = ref(false)
const isErrorOnSurvey = ref(false)
const showGlobalVariables = ref(true)

// const project = ref()
// project.value = await userApi.getProjectDetails(route.params.projectId)

if (store.state.pageTitle === '' && route.params.projectId) {
  store.commit('setPageTitleLoading', true)
  const project: Project = await userApi.getProject(route.params.projectId.toString())
  store.commit('setPageTitle', `Dossier : ${project.name}`)
  store.commit('setPageTitleLoading', false)
} else {
  store.commit('setPageTitle', `Récapitulatif`)
}
survey.value = await userApi.getSurvey(route.params.documentId)
const isAllQuestionHidden = computed(() => {
  const result = survey.value?.pages.find((page) => {
    return page.questions.some((question) => {
      return !question.isGlobal || !question.isGlobalValue
    })
  })
  return !result
})

function editSurvey(index) {
  router.push({
    path: route.path.replace('/summary', ''),
    query: { page: index },
  })
}

function isQuestionVisible(question: Question): boolean {
  let isVisible: boolean | undefined = true
  let isVisibleArray: Array<boolean> = []

  if (question.label === "embedded") {
    return false
  }

  if (question.dependantQuestions.length === 0) {
    return true
  }
  for (let i = 0; i < question.dependantQuestions.length; i++) {
    const dependantQuestion = question.dependantQuestions[i]
    const parentQuestion = findDependantQuestion(dependantQuestion)
    if (parentQuestion === undefined) {
      return true
    }
    if (!isQuestionVisible(parentQuestion)) {
      return false
    }
    let dependantQuestionTest = getDependantValue(dependantQuestion, parentQuestion)
    isVisibleArray.push(dependantQuestionTest)
  }

  isVisible = isVisibleArray.every((iva) => {
    return iva
  })
  return isVisible
}

function findDependantQuestion(dependantQuestion: DependantQuestion): Question | undefined {
  const parentQuestionPage = survey.value?.pages.find((page) => {
    return page.questions.find((question) => {
      return question.id === dependantQuestion.id
    })
  })
  if (parentQuestionPage === undefined) {
    return undefined
  }
  const parentQuestion = parentQuestionPage.questions.find((parentQuestion) => {
    if (parentQuestion.id === dependantQuestion.id) {
      return parentQuestion
    }
  })
  return parentQuestion
}

function getDependantValue(dependantQuestion, parentQuestion): boolean {
  let isVisibleArray: Array<boolean> = []
  if (typeof dependantQuestion.value === 'boolean' || typeof parentQuestion.answer.value === 'boolean') {
    isVisibleArray.push(dependantQuestion.value.toString() === parentQuestion.answer.value.toString())
  } else if (Array.isArray(parentQuestion.answer.value)) {
    isVisibleArray.push(parentQuestion.answer.value.some((v) => v === dependantQuestion.value))
  } else {
    isVisibleArray.push(dependantQuestion.value === parentQuestion.answer.value)
  }
  return isVisibleArray[0]
}

function isError(question: Question) {
  // console.log("isError", question)
  if (question.type === 'text' || question.type === 'number' || question.type === 'date') {
    if ((question.answer.value === '') || (question.answer.value === null)) {
      isErrorOnSurvey.value = true
      return true
    }
    return false
  } else if (question.type === 'choice') {
    if ((question.answer.value === '') || (question.answer.value === null)) {
      isChoicesEmpty.value = true
      isErrorOnSurvey.value = true
      return true
    }
    return false
  } else if (question.type === 'multi') {
    if ((question.answer.value.length === 0) || (question.answer.value === null)) {
      isChoicesEmpty.value = true
      isErrorOnSurvey.value = true
      return true
    }
    return false
  } else if (question.type === 'object') {
    let error = false
    question.options.map((option) => {
      if (isError(option)) {
        isErrorOnSurvey.value = true
        error = true
      }
    })
    return error
  } else if (question.type === 'unisign') {
    const isError =
      question.answer.value.email === '' ||
      question.answer.value.firstName === '' ||
      question.answer.value.lastName === '' ||
      question.answer.value.email === null ||
      question.answer.value.firstName === null ||
      question.answer.value.lastName === null
    if (isError) {
      isErrorOnSurvey.value = true
      isChoicesEmpty.value = true
    }
    return isError
  } else if (question.type === 'array') {
    if ((question.answer.value.length === 0) || (question.answer.value === null)) {
      isErrorOnSurvey.value = true
      return true
    }
    return false
  } else if (question.type === 'multisign') {
    if ((question.answer.value.length === 0) || (question.answer.value[0].email === null)) {
      isErrorOnSurvey.value = true
      isChoicesEmpty.value = true
      return true
    }
    if (question.answer.value.length === 1) {
      if (
        question.answer.value[0].email === '' ||
        question.answer.value[0].firstName === '' ||
        question.answer.value[0].lastName === ''
      ) {
        isErrorOnSurvey.value = true
        return true
      }
      return false
    }
  } else if (question.type === 'multiarray') {
    if ((question.answer.value.length === 0) || (question.answer.value === null)) {
      isErrorOnSurvey.value = true
      return true
    }
    return false
  }
}

function showLoading() {
  $q.loading.show({
    message: 'Nous générons votre document.\nMerci de patienter quelques instants.',
    boxClass: 'bg-grey-2 text-grey-9',
    spinnerColor: 'primary',
  })
}

function showVariableGlobalIfNotEmpty(question: Question) {
  if (showGlobalVariables.value) {
    return true
  }
  if (question.isGlobal && !showGlobalVariables.value) {
    return question.isGlobal && !question.isGlobalValue
  }
}

function endSurvey() {
  if (isErrorOnSurvey.value || isChoicesEmpty.value) {
    const dialog = Dialog.create({
      component: SurveyDocumentGenerationDialog,
      componentProps: {
        isErrorOnSurvey: isErrorOnSurvey.value,
        isChoicesEmpty: isChoicesEmpty.value,
      },
    })
      .onOk(() => {
        generateDocument()
        dialog.hide()
      })
      .onCancel(() => {
        dialog.hide()
      })
  } else {
    generateDocument()
  }
}

function generateDocument() {
  showLoading()
  userApi
    .generateDocument(route.params.documentId, route.params.clientId, route.params.projectId, route.params.taskId)
    .then((result) => {
      $q.loading.hide()
      if (route.params.clientId && route.params.projectId) {
        router.push({
          name: 'clients/client/projects/project',
          params: {
            clientId: route.params.clientId.toString(),
            projectId: route.params.projectId.toString(),
          },
          query: { taskId: route.params.taskId },
        })
      } else if (store.state.user.portal === 'CLIENT') {
        router.push({
          name: 'projects/project',
          params: {
            projectId: route.params.projectId.toString(),
          },
          query: { taskId: route.params.taskId },
        })
      } else {
        router.push({
          name: 'dashboard/main',
        })
      }
    })
    .catch((e) => {
      $q.loading.hide()
      Notify.create({
        message: 'Une erreur est survenue lors de la génération du document',
        type: 'negative',
      })
    })
}

function onBack() {
  if (route.params.clientId && route.params.projectId) {
    router.push({
      name: 'clients/client/projects/project',
      params: {
        clientId: route.params.clientId.toString(),
        projectId: route.params.projectId.toString(),
      },
      query: { taskId: route.params.taskId },
    })
  } else if (store.state.user.portal === 'CLIENT') {
    router.push({
      name: 'projects/project',
      params: {
        projectId: route.params.projectId.toString(),
      },
      query: { taskId: route.params.taskId },
    })
  } else {
    router.push({
      name: 'dashboard/main',
    })
  }
}

function getEditButtonColor(question: Question): string {
  if (question.type !== 'choice' && question.type !== 'multi') {
    return 'warning'
  } else {
    return 'negative'
  }
}

function getErroColor(question: Question): string {
  if (question.type !== 'choice' && question.type !== 'multi' && question.type !== 'unisign' && question.type !== 'multisign') {
    return 'orange-background'
  } else {
    return 'bg-red-1'
  }
}

function hasCompositeLink(question) {
  console.log("hasCompositeLink", question, survey.value)
  if (survey.value.isCompositeProject == true) {
    return false
  }

  if (question.hasOwnProperty('compositeLink') == false) {
    return false
  }

  if (question.compositeLink !== null && question.compositeLink !== "") {
    return true
  }
  return false
}

</script>
<template>
  <div>
    <q-btn outline icon="arrow_back" class="q-mt-md q-mb-md" style="width: fit-content" label="Retour" @click="onBack" />
    <div class="row justify-between">
      <!-- <q-toggle
          v-model="showGlobalVariables"
          label="Voir les questions communes"
          left-label
          color="primary"
          keep-color
          checked-icon="visibility"
          unchecked-icon="visibility_off"
          size="lg"
        />-->
    </div>

    <q-list bordered separator padding class="bg-white q-pb-none">
      <div v-for="(page, key) in survey?.pages">
        <q-item-label clickable v-ripple header>{{ page.name }}</q-item-label>

        <div v-for="question in page.questions">
          <q-item v-if="isQuestionVisible(question) && showVariableGlobalIfNotEmpty(question)" clickable v-ripple
            :key="question.id" :class="isError(question) ? getErroColor(question) : ''">
            <q-item-section :id="question.id">
              <q-item-label>{{ question.label }}</q-item-label>
              <q-item-label caption>
                <SurveySummaryInput :question="question"></SurveySummaryInput>
              </q-item-label>
            </q-item-section>

            <q-item-section side>
              <q-btn v-if="!hasCompositeLink(question)" outline label="Modifier la réponse"
                :color="isError(question) ? getEditButtonColor(question) : ''"
                :class="isError(question) ? 'bg-white' : ''" @click="editSurvey(key + 1)"></q-btn>
              <q-btn v-if="hasCompositeLink(question)" outline label="Réponse héritée du projet parent"
                :color="isError(question) ? getEditButtonColor(question) : ''"
                :class="isError(question) ? 'bg-white' : ''" @click="editSurvey(key + 1)"></q-btn>
            </q-item-section>
          </q-item>
        </div>

        <q-separator spaced v-if="key !== survey?.pages.length - 1" />
      </div>
      <div class="column items-center q-pt-md q-pb-lg" v-if="!showGlobalVariables && isAllQuestionHidden">
        <q-toggle v-model="showGlobalVariables" left-label color="primary" keep-color checked-icon="visibility"
          unchecked-icon="visibility_off" size="80px" />
        <span class="empty-sentence">
          Le récapitulatif n'est pas réellement vide, vous pouvez afficher les questions ayant déjà une réponse.
        </span>
      </div>
    </q-list>

    <BaseFooter>
      <template #content>
        <q-btn flat color="white" label="Modifier mes réponses" icon="navigate_before" @click="editSurvey(1)"
          size="22px" />

        <q-btn flat color="white" label="Générer le document" icon-right="done" size="22px" @click="endSurvey"
          name="generate-document" />
      </template>
    </BaseFooter>
  </div>
</template>
<style lang="scss" scoped>
.page-title {
  margin-top: 20px;
  font-size: 24px;
  font-weight: 500;
}

.empty-sentence {
  color: gray;
  font-size: 26px;
  font-weight: 400;
}

.orange-background {
  background-color: rgba(255, 204, 0, 0.1);
}

.q-separator {
  margin-top: 0 !important;
}
</style>
