<script lang="ts" async setup>
import { provide, ref, Ref } from 'vue'
import { useDialogPluginComponent } from 'quasar'
import BaseFileInput from '@/components/base/BaseFileInput.vue'
import BaseDialog from '@/components/base/BaseDialog.vue'
import ConcatPdf from '@/pages/platform/v2/ConcatPdf.vue'

interface Props {
  isLoading: boolean
}
withDefaults(defineProps<Props>(), {
  isLoading: false,
})

const emit = defineEmits([...useDialogPluginComponent.emits, 'onUpdate'])

const filesToMerge: Ref<Array<File>> = ref([])
const step = ref(1)
const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()
const filesToUpload: Ref<Array<File>> = ref([])
provide('filesToUpload', filesToUpload)

const generatedFiles: Ref<Array<any>> = ref([])

async function uploadFile() {
  // await updateDocument(generatedFiles.value);
  emit('ok', generatedFiles.value)
  // step.value = 2;
}

function beforeHide() {
  step.value = 1
}

async function updateDocument(data) {
  if (project && generatedFiles.value && selectedFile.value) {
    try {
      props.isLoading = true
      await userApi.uploadTaskDocument(selectedFile.value.id, data)
      isLoading.value = false
      Notify.create({
        message: 'Document mis à jour',
        type: 'primary',
      })
      updateFileList()
    } catch (e) {
      Notify.create({
        message: "Erreur lors de l'upload du document",
        type: 'negative',
      })
    }
  }
}
</script>

<template>
  <q-dialog ref="dialogRef">
    <BaseDialog
      title="Charger le document définitif"
      :is-dialog-loading="isLoading"
      @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()"
    >
      <template #body>
        <BaseFileInput
          v-model="generatedFiles"
          :multiple="false"
          @uploadFile="uploadFile"
          label="Sélectionnez le fichier définitif."
          class="q-ma-sm"
        ></BaseFileInput>
      </template>
      <template #actions> </template>
    </BaseDialog>
  </q-dialog>
  <!-- <BaseDialog
    :show-dialog="updateDocumentDialog"
    :is-dialog-loading="isLoading"
    :show-cancel-button="false"
    @before-hide="beforeHide"
  >
    <template #title>Gérer le document définitif</template>
    <template #body>
      <q-stepper flat v-model="step" ref="stepper" color="primary" animated>
        <q-step
          :name="1"
          title="Quel document utiliser ?"
          icon="settings"
          :done="step > 1"
        >
          <div class="column justify-between">
            <q-btn
              outlined
              color="primary"
              class="q-ma-sm"
              label="Ajouter des annexes au document généré"
              @click="$refs.stepper.next()"
              :disable="isLoading"
            />
            <div class="row justify-around q-mt-md q-mb-md">
              <q-separator inset class="q-ma-sm col" />
              <div class="or">Ou</div>
              <q-separator inset class="q-ma-sm col" />
            </div>
            <BaseFileInput
              v-model="generatedFiles"
              :multiple="false"
              @uploadFile="uploadFile"
              label="Importer le fichier définitif."
              class="q-ma-sm"
            >
            </BaseFileInput>
          </div>
        </q-step>

        <q-step
          :name="2"
          title="Fusionner des annexes ?"
          icon="o_description"
          :done="step > 2"
        >
          <div class="q-mb-md">
            <ConcatPdf @step-back="$refs.stepper.previous()"></ConcatPdf>
          </div>
        </q-step>

        <template #navigation>
          <q-stepper-navigation>
            <q-btn
              v-if="step > 1"
              flat
              color="primary"
              @click="$refs.stepper.previous()"
              label="Retour"
              class="q-ml-sm"
            />
            <q-btn
              class="float-right"
              v-if="step === 2 && (filesToUpload?.length === 0 || filesToUpload === null)"
              @click="$refs.stepper.next()"
              color="primary"
              label="Générer sans annexes"
            />
            <q-btn
              class="float-right"
              v-if="step === 2 && filesToUpload?.length > 0"
              @click="$refs.stepper.next()"
              color="primary"
              label="Générer avec les annexes"
            />
          </q-stepper-navigation>
        </template>
      </q-stepper>
    </template>
    <template #actions></template>
  </BaseDialog> -->
</template>

<style lang="scss" scoped></style>
