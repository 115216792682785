<script lang="ts" setup>
import { ref, Ref } from 'vue'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { useStore } from '@/store/store'
import { Project } from '@/models/projects/Project'
import { Notify } from 'quasar'

interface Props {
  project: Project
}
const props = defineProps<Props>()

const userApi: UserApi = useApi()
const store = useStore()
const isLoading: Ref<boolean> = ref(false)

async function onShowDocumentsChanged() {
  await userApi.updatePartialProject(store.state.user.id, props.project.value.id, {
    showDocuments: props.project.value.parameters.showDocuments,
  })
}

async function onShowSubProjectsChanged() {
  await userApi.updatePartialProject(store.state.user.id, props.project.value.id, {
    showSubProjects: props.project.value.parameters.showSubProjects,
  })
}

function saveAsProjectTemplate() {

}

async function renameSubProject() {
  await userApi.updateProject(props.project?.value.id, {
    name: props.project?.value.name,
  })
  Notify.create({
    message: 'Dossier renommé avec succès',
    type: 'primary',
  })
}
</script>

<template>
  <!-- <div style="color: #bb7700; margin-bottom: 8px;">
        <q-icon name="warning" size="md" color="alert" />
        Les paramètres suivants seront bientôt disponibles
    </div> -->

  <div>
    <!-- <q-item-label header>Affichage</q-item-label> -->

    <q-item-label style="font-weight: 500">Nom du dossier</q-item-label>
    <q-item dense>
      <div class="row" style="text-align: middle;">
        <q-input class="col" size="42" outlined dense v-model="project.value.name" />
        <q-btn class="col-auto" style="margin-left: 8px; padding-left: 8px; padding-right: 8px" dense label="Renommer"
          @click="renameSubProject()" color="primary" />
      </div>
    </q-item>


    <q-item-label style="font-weight: 500; margin-top: 16px;">Affichage</q-item-label>
    <q-item dense>
      <q-item-section side top>
        <q-checkbox v-model="project.value.parameters.showDocuments" @update:model-value="onShowDocumentsChanged" />
      </q-item-section>

      <q-item-section>
        <q-item-label>Afficher les documents</q-item-label>
        <!-- <q-item-label caption>
                        Notify me about updates to apps or games that I downloaded
                    </q-item-label> -->
      </q-item-section>
    </q-item>

    <q-item dense>
      <q-item-section side top>
        <q-checkbox v-model="project.value.parameters.showSubProjects" @update:model-value="onShowSubProjectsChanged" />
      </q-item-section>

      <q-item-section>
        <q-item-label>Afficher les projets juridiques</q-item-label>
        <!-- <q-item-label caption>
                        Auto-update apps at anytime. Data charges may apply
                    </q-item-label> -->
      </q-item-section>
    </q-item>

    <!-- <q-separator spaced />

    <q-item-label style="font-weight: 500">Modèle</q-item-label>
    <q-item dense>
      <q-item-section>
        <q-item-label>
          <a href="#" @click="saveAsProjectTemplate()"
            class="col-auto button-link import-from-lop-button-link">Enregistrer comme modèle de dossier</a>
        </q-item-label>

      </q-item-section>
    </q-item> -->
  </div>
</template>

<style lang="scss" scoped></style>
