<script lang="ts" setup>
</script>

<template>
    <q-separator style=""></q-separator>
    <div class="title">Projets juridiques</div>
</template>

<style lang="scss" scoped>
.title {
    font-size: 1rem;
    font-weight: bold;
    // margin-top: 16px;
    // margin-bottom: 1rem;
}
</style>
