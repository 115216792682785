<script setup lang="ts">
import { watch, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/store/store'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import { useBrowserLocation } from '@vueuse/core'
import { AuthenticatedUser, getRoutes } from '@/models/core/AuthenticatedUser'
import BackOfficeApi from '@/services/api/BackOfficeApi'

const store = useStore()
const authenticatedUser = useAuthenticatedUser()
const isLoading = ref(false)
const location = useBrowserLocation()

let environments = []
let alreadyHasUserMainEnvironment = false
if ('environments' in authenticatedUser) {
  environments = authenticatedUser.environments
  authenticatedUser.environments.forEach((environment) => {
    if (environment.id == authenticatedUser.environment.id) {
      alreadyHasUserMainEnvironment = true
    }
  })
}

if (!alreadyHasUserMainEnvironment) {
  environments.push(authenticatedUser.environment)
}

environments.sort((a, b) => {
  if (a.name < b.name) {
    return -1
  }
  if (a.name > b.name) {
    return 1
  }
  return 0
})

let model = ref(environments[0])
const options = ref(environments)

environments.forEach((environment) => {
  console.log('environment', environment, authenticatedUser)
  if (environment.id == authenticatedUser.environment.id) {
    model.value = environment
  }
})

watch(model, () => {
  isLoading.value = true

  authenticatedUser.portal = model.value.portalType
  if (model.value.portalType == 'CLIENT') {
    authenticatedUser.clientId = model.value.client.id
    authenticatedUser.client = model.value.client
  } else {
    authenticatedUser.clientId = null
    authenticatedUser.client = null
  }

  authenticatedUser.environment = model.value

  store.commit('setUser', authenticatedUser)

  isLoading.value = false

  window.location = location.value.origin
})
</script>

<template>
  <span class="absolute-top flex flex-center" style="margin-top: 0px; padding-top: 0px">
    <q-select dense borderless v-model="model" :options="options" option-label="name" dropdown-icon="unfold_more" />
  </span>
</template>


<style lang="scss" scoped>
@import '@/styles/app.variables.scss';

:deep(.q-field__native) {
  font-size: 18px;
  font-weight: 500;
}

:deep(.q-icon) {
  font-size: 20px;
}
</style>

