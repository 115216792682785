<script lang="ts" setup>
import { ref, Ref } from 'vue'
import { useApi } from '@/store/useAppStore'
import { useStore } from '@/store/store'
import UserApi from '@/services/api/core/UserApi'
import { Project } from '@/models/projects/Project'

interface Props {
  project: Project
}
const props = defineProps<Props>()

const userApi: UserApi = useApi()
const store = useStore()

const isLoading: Ref<boolean> = ref(false)

const viewTypeOptions = [
  {
    label: 'Arborescence',
    value: 'tree',
    icon: 'img:/file-tree-outline-custom.svg', // "mdi-file-tree-outline" // o_segment
  },
  {
    label: 'A plat',
    value: 'flat',
    icon: 'mdi-format-list-bulleted', // headline o_view_list
  },
  // {
  //     "label": "Répertoire",
  //     "value": "directory",
  //     "icon": "o_folder" // agenda // segment // folder_open //
  // },
  // {
  //   "label": "Arborescence et répertoire",
  //   "value": "tree_and_directory",
  //   "icon": "mdi-view-split-vertical"
  // }
]

console.log('view type', props.project.value.parameters.documentsDefaultView)

let view = viewTypeOptions[0]
if (props.project.value.parameters.documentsDefaultView == 'flat') {
  view = viewTypeOptions[1]
}

const viewType = ref(view)

async function onViewTypeChanged() {
  console.log('on default view type changed', viewType.value)
  let newValue = 1
  if (viewType.value.value == 'tree') {
    newValue = 1
  } else if (viewType.value.value == 'flat') {
    newValue = 2
  }
  console.log('new value', newValue)
  await userApi.updatePartialProject(store.state.user.id, props.project.value.id, {
    documentsDefaultView: newValue,
  })
  props.project.value.parameters.documentsDefaultView = viewType.value.value
}
</script>

<template>
  <!-- <div style="color: #bb7700; margin-bottom: 16px;">
        <q-icon name="warning" size="md" color="alert" />
        Les paramètres suivants seront bientôt disponibles
    </div> -->

  <div>
    <!-- <q-item-label header>Affichage</q-item-label> -->

    <div>
      <q-item-section>
        <q-item-label style="font-weight: 500">Vue par défaut</q-item-label>
        <!-- <q-item-label caption>
                        Notify me about updates to apps or games that I downloaded
                    </q-item-label> -->

        <q-select
          style="margin-top: 8px; width: 250px"
          rounded
          outlined
          dense
          options-dense
          v-model="viewType"
          :options="viewTypeOptions"
          class="view-type-select"
          @update:model-value="onViewTypeChanged"
        >
          <template v-slot:option="scope">
            <!-- <div v-bind="scope.itemProps">
              <q-icon :name="scope.opt.icon" />
            </div> -->
            <q-item v-bind="scope.itemProps">
              <q-item-section avatar style="min-width: 0px">
                <q-icon :name="scope.opt.icon" size="20px" />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ scope.opt.label }}</q-item-label>
                <!-- <q-item-label caption>{{ scope.opt.description }}</q-item-label> -->
              </q-item-section>
            </q-item>
          </template>
          <template v-slot:selected-item="scope">
            <!-- <q-icon text-color="white" :name="scope.opt.icon" size="16px" /> -->
            <q-icon :name="scope.opt.icon" size="20px" style="margin-right: 16px" />
            {{ scope.opt.label }}
          </template>
        </q-select>
      </q-item-section>

      <!-- <q-item-section top>

            </q-item-section> -->
    </div>

    <!-- <q-separator spaced /> -->
  </div>
</template>

<style lang="scss" scoped></style>
