<script setup lang="ts">
import { ref, onBeforeUnmount } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useQuasar } from 'quasar'
import { useStore } from '@/store/store'
import { Dialog } from 'quasar'
import SelectSubProjectsComponent from '@/pages/common/projects/SelectSubProjectsComponent.vue'
import SelectSubProjectsTreeComponent from '@/pages/common/projects/SelectSubProjectsTreeComponent.vue'
import CreateProjectFromLOPDialog from './CreateProjectFromLOPDialog.vue'

const store = useStore()
store.commit('setPageTitle', 'Créer un dossier')

const router = useRouter()
const route = useRoute()
const $q = useQuasar()
const userApi = store.state.api.getUserApi()

const step = ref(1)

const projectName = ref('')

const selectSubProjects = ref(false)

const lopProject = ref(undefined)

onBeforeUnmount(() => {
  $q.loading.hide()
})

const showLoading = function () {
  $q.loading.show({
    message: "Nous préparons votre dossier.\nMerci de patienter, cela peut prendre jusqu'à 1 minute.",
    boxClass: 'bg-grey-2 text-grey-9',
    spinnerColor: 'primary',
  })
}

async function importMatter(project, matter) {
  $q.loading.show({
    message: "Nous importons les documents depuis LOP.\nMerci de patienter, cela peut prendre jusqu'à 1 minute.",
    boxClass: 'bg-grey-2 text-grey-9',
    spinnerColor: 'primary',
  })

  const result = await userApi.documentTreeImportLopMatter(project.id, matter.id)

  $q.loading.hide()
}

async function createProject(subProjectTemplateIds) {
  showLoading()

  let subProjectTemplateIdsArray = []
  if (subProjectTemplateIds != '') {
    subProjectTemplateIdsArray = subProjectTemplateIds.split(',')
  }

  const project = await userApi.createProject({
    name: projectName.value,
    productId: '307fdeb7-a804-4b8c-8c0f-3ea8f4ab6ea1',
    projectTemplateIds: subProjectTemplateIdsArray,
  })

  $q.loading.hide()

  if (lopProject.value !== undefined) {
    console.log('importing lop matter', project, lopProject.value)
    await importMatter(project, lopProject.value)
  }

  if (store.state.user.portal == 'CLIENT') {
    router.push({
      name: 'projects/project',
      params: { projectId: project.id },
    })
  } else {
    router.push({
      name: 'clients/client/projects/project',
      params: { projectId: project.id },
    })
  }
}

function createProjectClicked() {
  if (selectSubProjects.value) {
    step.value = 2
  } else {
    createProject('')
  }
}

const createProjectFromLOP = function (subProjectTemplateIds) {
  showLoading()
  const userApi = store.state.api.getUserApi()

  let subProjectTemplateIdsArray = []
  if (subProjectTemplateIds != '') {
    subProjectTemplateIdsArray = subProjectTemplateIds.split(',')
  }

  userApi
    .createProject({
      name: projectName.value,
      productId: '307fdeb7-a804-4b8c-8c0f-3ea8f4ab6ea1',
      projectTemplateIds: subProjectTemplateIdsArray,
    })
    .then((project) => {
      if (lopProject.value !== undefined) {
        console.log('importing lop matter')
      }

      $q.loading.hide()
      if (store.state.user.portal == 'CLIENT') {
        router.push({
          name: 'projects/project',
          params: { projectId: project.id },
        })
      } else {
        router.push({
          name: 'clients/client/projects/project',
          params: { projectId: project.id },
        })
      }
    })
    .catch((e) => {
      $q.loading.hide()
    })
}

function showImportFromLOPDialog() {
  const dialog = Dialog.create({
    component: CreateProjectFromLOPDialog,
    componentProps: {
      project: undefined,
    },
  })
    .onOk((infos) => {
      dialog.hide()
      console.log('infos', infos)
      projectName.value = infos.title
      lopProject.value = infos
      // refreshFiles(userApi, project, documentTreeNodes);
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function myTweak(offset, height) {
  // console.log("myTweak2", offset, height);
  return { minHeight: offset ? `calc(100vh - ${offset}px - 16px - 40px)` : '100vh' }
}

function allowLopImport() {
  return true
  if (store.state.user.portal == 'ENVIRONMENT' && store.state.user.environment.id == '2c29fa44-fb99-4b0d-affd-b1800b379efa') {
    return true
  }
  return false
}
</script>

<template>
  <q-page padding class="page" :style-fn="myTweak">
    <div v-if="step == 1" class="q-pa-md page-content q-col-gutter-md" style="max-width: 800px">
      <!-- <div class="row"> -->
      <!-- <div class="col"></div> -->
      <!-- <div class="col-auto" style=""> -->
      <q-form class="q-gutter-md">
        <p>Quel nom voulez-vous donner au dossier ?</p>

        <div class="row">
          <div class="col">
            <q-input class="col-md-6" filled v-model="projectName" label="Nom du dossier *" lazy-rules
              :rules="[(val) => (val && val.length > 0) || 'Le nom du dossier est requis']" />
          </div>
          <div v-if="allowLopImport()" class="col">
            <a href="#" @click="showImportFromLOPDialog()" class="import-from-lop-button-link">Importer depuis LOP</a>
          </div>
        </div>

        <div v-if="lopProject !== undefined" class="row" style="margin-top: 0px">Dossier Lop : {{ lopProject.id }}</div>

        <div class="row">
          <q-checkbox v-model="selectSubProjects" label="Sélectionner des projets à inclure dans le dossier" />
        </div>

        <div class="row">
          <q-btn @click="createProjectClicked" class="col-md-6" color="primary">Créer le dossier</q-btn>
        </div>
      </q-form>
      <!-- </div> -->
      <!-- <div class="col"></div> -->
      <!-- </div> -->
    </div>

    <div v-if="step == 2">
      <!-- <SelectSubProjectsTreeComponent @createSubProjects="createProject"></SelectSubProjectsTreeComponent> -->
      <SelectSubProjectsComponent @createProject="createProject"></SelectSubProjectsComponent>
    </div>
  </q-page>
</template>

<style lang="scss" scoped>
p {
  font-size: 16px;
}

ul {
  list-style-type: none;
}

li {
  cursor: pointer;
}

.import-from-lop-button-link {
  padding-top: 10px;
  padding-left: 10px;
  margin-top: 20px;
  line-height: 4em;
}
</style>
