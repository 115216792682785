<script lang="ts" async setup>
import { ref, Ref, onMounted } from 'vue'
import { QTableProps } from 'quasar'
import { Project } from '@/models/projects/Project'
import { Task } from '@/models/projects/Task'
import { Section } from '@/models/projects/Section'
import { useApi } from '@/store/useAppStore'
import BaseCheckbox from '@/components/base/BaseCheckbox.vue'
import UserApi from '@/services/api/core/UserApi'

interface Props {
  projectId: string
  clientId: string
  user: any
  group: any
  entityType: string
}
const props = withDefaults(defineProps<Props>(), {})

const userApi: UserApi = useApi()
const project = ref<Project>()
const sectionsRows: Ref<Array<any>> = ref([])
const isLoading: Ref<boolean> = ref(false)
const expandedSections: Ref<Array<string>> = ref([])

const tasksHashMap = {}
const sectionsHashMap = {}

let tasksPermissions = []
let sectionsPermissions = []

// onMounted(async () => {
isLoading.value = true
project.value = await userApi.getClientProjectDetails(props.projectId, props.clientId)
project.value.sections = project.value.subProjects[0].sections
console.log("Project", project.value)

createSectionsAndTasksHashMaps()

sectionsRows.value = [
  {
    id: "1",
    name: "Correspondances",
    tasks: [],
    subSections: [],
    see: true,
    edit: false,
  },
  {
    id: "2",
    name: "Courries",
    tasks: [],
    subSections: [],
    see: true,
    edit: false,
  },
  {
    id: "3",
    name: "Pièces",
    tasks: [],
    subSections: [],
    see: true,
    edit: false,
  },
  {
    id: "4",
    name: "Procédure",
    tasks: [],
    subSections: [],
    see: true,
    edit: false,
  }
]

// expandedSections.value = sectionsRows.value.map((section) => {
//   return section.name;
// });

expandedSections.value = Object.values(sectionsHashMap).map((section) => {
  return section.name
})

isLoading.value = false
// }); // fin onmounted

function createSectionsAndTasksHashMaps() {
  project.value.sections.forEach((section, sectionKey) => {
    sectionsHashMap[section.id] = section

    section?.sections?.forEach((childSection, childSectionKey) => {
      sectionsHashMap[childSection.id] = childSection
      childSection.tasks.forEach((childTask, childTaskKey) => {
        tasksHashMap[childTask.id] = childTask
      })
    })

    section.tasks.forEach((task, taskKey) => {
      tasksHashMap[task.id] = task
    })
  })
}

function addPermissionsToProject() {
  sectionsPermissions.results.forEach((sectionPermissions) => {
    const section = sectionsHashMap[sectionPermissions.sectionId]
    section.permissions = sectionPermissions.permissions
    section.see = section.permissions.includes('view_legacysection')
  })
  tasksPermissions.results.forEach((taskPermissions) => {
    const task = tasksHashMap[taskPermissions.taskId]
    task.permissions = taskPermissions.permissions
    task.see = task.permissions.includes('view_legacytask')
    task.edit = task.permissions.includes('execute_legacytask')
  })
}

const sectionsColumns: QTableProps['columns'] = [
  {
    name: 'name',
    label: 'Section',
    field: 'name',
    sortable: true,
    align: 'left',
    style: 'width:380px; overflow:hidden; text-overflow:ellipsis',
  },
  {
    name: 'see',
    label: 'Voir',
    field: 'see',
    sortable: true,
    align: 'center',
  },
  {
    name: 'edit',
    label: 'Modifier',
    field: 'edit',
    sortable: true,
    align: 'center',
  },
]

function getSubSections(parentSection) {
  const subSectionRef = ref()
  subSectionRef.value = parentSection.sections.map((section) => {
    return {
      ...section,
      parent: parentSection,
      // see: true,
    }
  })
  return subSectionRef.value
}

function getSubSectionsExpandedRows(parentSection) {
  const subSectionRef = ref([])
  parentSection.sections.forEach((section) => {
    subSectionRef.value.push(section.name)
  })
  return subSectionRef
}

function getTasks(parentSection) {
  const taskRef = ref()
  taskRef.value = parentSection.tasks.map((task) => {
    return {
      ...task,
      // see: true,
      // edit: true,
    }
  })
  return taskRef.value
}

function updateViewSectionPermission(sectionRow) {
  if (props.entityType === 'user') {
    if (sectionRow.see == true) {
      // sectionRow.permissions.push("view_legacysection");
      userApi.updateSectionPermissions(sectionRow.id, [props.user.id], ['view_legacysection'], [], [], [], [])
    } else {
      // sectionRow.permissions.remove("view_legacysection");
      userApi.updateSectionPermissions(sectionRow.id, [props.user.id], [], ['view_legacysection'], [], [], [])
    }
  } else {
    // entityType === "group"
    if (sectionRow.see == true) {
      // sectionRow.permissions.push("view_legacysection");
      userApi.updateSectionPermissions(sectionRow.id, [], [], [], [props.group.id], ['view_legacysection'], [])
    } else {
      // sectionRow.permissions.remove("view_legacysection");
      userApi.updateSectionPermissions(sectionRow.id, [], [], [], [props.group.id], [], ['view_legacysection'])
    }
  }
}

function updateViewTaskPermission(taskRow) {
  if (props.entityType === 'user') {
    if (taskRow.see == true) {
      // sectionRow.permissions.push("view_legacysection");
      userApi.updateTaskPermissions(taskRow.id, [props.user.id], ['view_legacytask'], [], [], [], [])
    } else {
      // sectionRow.permissions.remove("view_legacysection");
      userApi.updateTaskPermissions(taskRow.id, [props.user.id], [], ['view_legacytask'], [], [], [])
    }
  } else {
    // entityType === "group"
    if (taskRow.see == true) {
      // sectionRow.permissions.push("view_legacysection");
      userApi.updateTaskPermissions(taskRow.id, [], [], [], [props.group.id], ['view_legacytask'], [])
    } else {
      // sectionRow.permissions.remove("view_legacysection");
      userApi.updateTaskPermissions(taskRow.id, [], [], [], [props.group.id], [], ['view_legacytask'])
    }
  }
}

function updateExecuteTaskPermission(taskRow) {
  if (props.entityType === 'user') {
    if (taskRow.edit == true) {
      // sectionRow.permissions.push("view_legacysection");
      userApi.updateTaskPermissions(taskRow.id, [props.user.id], ['execute_legacytask'], [], [], [], [])
    } else {
      // sectionRow.permissions.remove("view_legacysection");
      userApi.updateTaskPermissions(taskRow.id, [props.user.id], [], ['execute_legacytask'], [], [], [])
    }
  } else {
    // entityType === "group"
    if (taskRow.edit == true) {
      // sectionRow.permissions.push("view_legacysection");
      userApi.updateTaskPermissions(taskRow.id, [], [], [], [props.group.id], ['execute_legacytask'], [])
    } else {
      // sectionRow.permissions.remove("view_legacysection");
      userApi.updateTaskPermissions(taskRow.id, [], [], [], [props.group.id], [], ['execute_legacytask'])
    }
  }
}
</script>
<template>
  <q-table flat bordered virtual-scroll row-key="name" :rows="sectionsRows" :columns="sectionsColumns"
    :loading="isLoading" :hide-bottom="true" :rows-per-page-options="[0]" v-model:expanded="expandedSections">
    <template v-slot:loading>
      <q-inner-loading showing color="primary" />
    </template>
    <template v-slot:header="props">
      <!-- <q-tr :props="props">
          <q-th auto-width />
          <q-th v-for="col in props.cols" :key="col.name" :props="props">
            {{ col.label }}
          </q-th>
        </q-tr> -->
    </template>

    <template v-slot:body="props">
      <q-tr :props="props">
        <q-td auto-width>
          <q-btn size="sm" color="primary" round outline dense @click="props.expand = !props.expand"
            :icon="props.expand ? 'remove' : 'add'" />
        </q-td>
        <q-td key="name" :props="props">
          {{ props.row.name }}
        </q-td>
        <q-td key="see" :props="props">
          <div>Voir</div>
          <BaseCheckbox v-model="props.row.see" dense @click="updateViewSectionPermission(props.row)"></BaseCheckbox>
        </q-td>
        <q-td key="edit" :props="props">
          <div>Modifier</div>
          <BaseCheckbox v-model="props.row.edit" dense></BaseCheckbox>
        </q-td>
        <q-td key="delete" :props="props">
          <BaseCheckbox v-model="props.row.delete" dense></BaseCheckbox>
        </q-td>
      </q-tr>
      <q-tr v-show="props.expand" :props="props">
        <q-td colspan="100%">
        </q-td>
      </q-tr>
    </template>
  </q-table>
</template>
<style lang="scss" scoped></style>
