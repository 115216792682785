<script lang="ts" setup>
import { ref, Ref, watch, provide, inject } from 'vue'
import { Notify, Dialog, useQuasar } from 'quasar'
import { useRoute } from 'vue-router'
import { ProjectKey } from '@/models/symbols'
import { Task } from '@/models/projects/Task'
import { UploadedFile } from '@/models/files/UploadedFile'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import FileSaver from 'file-saver'
import ProjectsServices from '@/services/api/projects/projects.services'
import BaseFileInput from '@/components/base/BaseFileInput.vue'
import DeleteDocumentDialog from './dialog/DeleteDocumentDialog.vue'
import PdfPreviewDialog from '../../task_actions/document_generation/dialog/PdfPreviewDialog.vue'
import PdfPreviewDialogWithIframe from '../../task_actions/document_generation/dialog/PdfPreviewDialogWithIframe.vue'
import RenameDocumentDialog from './dialog/RenameDocumentDialog.vue'
import MoveDocumentDialog from './dialog/MoveDocumentDialog.vue'
import DocumentCard from '../document_generation/DocumentCard.vue'
import ConfirmActionDialog from '@/components/common/dialog/ConfirmActionDialog.vue'

interface Props {
  task: Task
}

const props = defineProps<Props>()

const route = useRoute()
const $q = useQuasar()

const userApi: UserApi = useApi()

const project = inject(ProjectKey)
const files: any = ref([])
const filesToUpload: Ref<Array<File>> = ref([])
const isLoading = ref(false)

function isTaskReadOnly() {
  // return !props.task.permissions.includes("execute_legacytask");
  return false
}

const documentTreeUpdateCounter = inject('documentTreeUpdateCounter')

watch(props, async (newValue, oldValue) => {
  updateFileList()
})

updateFileList()

async function updateFileList() {
  isLoading.value = true
  const result = await userApi.getUploadTaskFiles(props.task.id)
  files.value = result
  isLoading.value = false
}

async function updateTaskStatus() {
  console.log('updateTaskStatus')
  if (props.task.status == 'not_started') {
    console.log('Set task as started')
    props.task.status = 'started'
    await userApi.putProjectTask(project?.value.id, props.task?.id, {
      status: 'started',
      client: route.params.clientId,
    })

    const new_event = await userApi.createProjectEvent(project?.value.id, {
      type: 1,
      user_project_id: project?.value.id,
      legacy_task_id: props.task?.id,
    })
    // emit("updateEvents", new_event);
  }
}

async function uploadFiles() {
  if (project && filesToUpload.value) {
    isLoading.value = true
    files.value = await userApi.uploadTaskUploadFiles(project.value.id, props.task.id, filesToUpload.value)
    filesToUpload.value = []
  }
  isLoading.value = false
  updateFileList()
  updateTaskStatus()
}

async function downloadFile(file: UploadedFile) {
  try {
    const doc = await userApi.downloadUploadTaskFile(props.task.id, file.id)

    var reader = new FileReader()
    reader.readAsArrayBuffer(new Blob([doc]))
    reader.onload = function () {
      const newFile = new File([new Blob([reader.result])], 'file', {
        type: 'application/octet-stream',
      })

      FileSaver(newFile, file.name)
    }
    reader.onerror = function (error) {
      // setloadingSaveDoc("error");
      // setTimeout(() => {
      //   setloadingSaveDoc(false);
      // }, 1500);
    }
  } catch (e) {
    console.error(e)
    Notify.create({
      message: 'Erreur lors du téléchargement du document',
      type: 'negative',
    })
  }
}

function showDeleteFileModal(file: UploadedFile) {
  const dialog = Dialog.create({
    component: DeleteDocumentDialog,
    componentProps: {
      task: props.task,
      selectedFile: file,
    },
  })
    .onOk(async () => {
      await updateFileList()
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function showRenameFileModal(file: UploadedFile) {
  const dialog = Dialog.create({
    component: RenameDocumentDialog,
    componentProps: {
      task: props.task,
      selectedFile: file,
    },
  })
    .onOk(async () => {
      await updateFileList()
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function showMoveToOtherUploadTaskDialog(file: UploadedFile) {
  const dialog = Dialog.create({
    component: MoveDocumentDialog,
    componentProps: {
      task: props.task,
      selectedFile: file,
      project: project,
    },
  })
    .onOk(async () => {
      await updateFileList()
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

async function showMoveToProjectDocumentsDialog(file: UploadedFile) {
  const dialog = Dialog.create({
    component: ConfirmActionDialog,
    componentProps: {
      message: 'Voulez-vous vraiment déplacer ce fichier dans les documents du dossier ?',
    },
  })
    .onOk(async () => {
      await userApi.moveUploadTaskFileToProjectDocuments(project.value.id, props.task.id, file.id, file.name)
      documentTreeUpdateCounter.value = documentTreeUpdateCounter.value + 1
      await updateFileList()

      dialog.hide()
      Notify.create({
        message: 'Fichier déplacé',
        type: 'primary',
      })
    })
    .onCancel(() => {
      dialog.hide()
    })
}

async function showPdfPreview(file: UploadedFile) {
  const dialog = Dialog.create({
    component: PdfPreviewDialogWithIframe,
    componentProps: {
      file: file,
      documentName: file.name,
      taskId: props.task.id,
    },
  })
    .onOk(async (data) => {
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function openFileHelper(newWindow, file) {
  var blob = new Blob([file], { type: `application/pdf` })
  newWindow.location = URL.createObjectURL(blob)
}

async function previewFile(file: UploadedFile) {
  $q.loading.show({
    message: 'Veuillez patienter pendant la génération de la prévisualisation PDF...',
    boxClass: 'bg-grey-2 text-grey-9',
    spinnerColor: 'primary',
    delay: 200, // ms
  })

  try {
    let doc = undefined
    if (file.name.endsWith('.docx')) {
      doc = await userApi.convertDocxToPdf(file.id)
    } else {
      doc = await userApi.downloadUploadTaskFile(props.task.id, file.id)
    }

    let newWindow = window.open('')
    if (newWindow.document.readyState === 'complete') {
      openFileHelper(newWindow, doc)
    } else {
      newWindow.onload = () => {
        openFileHelper(newWindow, doc)
      }
    }
    $q.loading.hide()
  } catch (e) {
    console.error(e)
    $q.loading.hide()
    Notify.create({
      message: 'Erreur lors de la prévisualisation du document',
      type: 'negative',
    })
  }
}
</script>

<template>
  <div class="field-content col-12">
    <div class="field-title col-3">
      <p>Action</p>
    </div>
    <BaseFileInput
      label="Sélectionnez les fichiers à importer."
      v-model="filesToUpload"
      :multiple="true"
      @uploadFile="uploadFiles"
      :disable="project.isReadOnly || isTaskReadOnly()"
    ></BaseFileInput>
    <div v-if="files?.length > 0" class="q-mt-xl">
      <DocumentCard v-for="file in files" :key="file.id" :loading="isLoading" clickable>
        <template #name>
          <div @click="showPdfPreview(file)">
            {{ file.name }}
          </div>
        </template>
        <template #actions>
          <q-btn-dropdown :disable="isLoading" flat rounded dropdown-icon="more_vert">
            <q-list>
              <q-item
                clickable
                v-close-popup
                :disable="!(file.name.endsWith('.pdf') || file.name.endsWith('.docx'))"
                @click="showPdfPreview(file)"
              >
                <q-item-section>
                  <q-item-label>Prévisualiser</q-item-label>
                </q-item-section>
              </q-item>
              <q-item clickable v-close-popup @click="showPdfPreviewInNewTab(file)">
                <q-item-section>
                  <q-item-label>Prévisualiser (Nouvel onglet)</q-item-label>
                </q-item-section>
              </q-item>
              <q-item clickable v-close-popup @click="downloadFile(file)">
                <q-item-section>
                  <q-item-label>Télécharger</q-item-label>
                </q-item-section>
              </q-item>
              <q-item
                clickable
                v-close-popup
                :disable="project.isReadOnly || isTaskReadOnly()"
                @click="showRenameFileModal(file)"
              >
                <q-item-section>
                  <q-item-label>Renommer</q-item-label>
                </q-item-section>
              </q-item>
              <q-item
                clickable
                v-close-popup
                :disable="project.isReadOnly || isTaskReadOnly()"
                @click="showMoveToOtherUploadTaskDialog(file)"
              >
                <q-item-section>
                  <q-item-label>Déplacer dans une autre tâche</q-item-label>
                </q-item-section>
              </q-item>
              <q-item
                clickable
                v-close-popup
                :disable="project.isReadOnly || isTaskReadOnly()"
                @click="showMoveToProjectDocumentsDialog(file)"
              >
                <q-item-section>
                  <q-item-label>Déplacer dans les documents du dossier</q-item-label>
                </q-item-section>
              </q-item>
              <q-item
                clickable
                v-close-popup
                class="text-red"
                :disable="project.isReadOnly || isTaskReadOnly()"
                @click="showDeleteFileModal(file)"
              >
                <q-item-section>
                  <q-item-label text-color="negative">Supprimer</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </template>
      </DocumentCard>
    </div>
    <div v-if="isLoading && files?.length === 0" class="row justify-center q-mt-xl">
      <q-spinner color="primary" size="3em" />
    </div>
  </div>
</template>
