<script lang="ts" setup>
import { ref, Ref, inject } from 'vue'
import { useDialogPluginComponent, Notify, Dialog, useQuasar } from 'quasar'
import { GeneratedDocument } from '@/models/files/GeneratedDocument'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseInput from '@/components/base/BaseInput.vue'
import BaseDialog from '@/components/base/BaseDialog.vue'
import CopySubProjectToProjectDialog from '@/components/projects/execution/subprojects/CopySubProjectToProjectDialog.vue'

interface Props {
  subProject: any
  projectNodes: any
}
const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const $q = useQuasar()
const userApi: UserApi = useApi()
const newDocumentName: Ref<string> = ref('')
const isLoading: Ref<boolean> = ref(false)
const treeRef = ref(null)
const name = ref(props.subProject.value.name)
const tab = ref('general')

const subProjectPersonalModels = inject("subProjectPersonalModels")

async function exportProjectToDocuments() {
  emit('ok')
}

async function renameSubProject() {
  await userApi.renameSubProject(props.subProject.value.legacyId, name.value)
  props.subProject.value.name = name.value
  Notify.create({
    message: 'Projet juridique renommé avec succès',
    type: 'primary',
  })
}

console.log("subProject", props.subProject.value)

function isPersonalModel() {
  return props.subProject.value.isPersonalModel
}

async function addToPersonalModels() {
  const newModel = await userApi.addSubProjectToPersonalModels(props.subProject.value.legacyId)
  props.subProject.value.isPersonalModel = true
  Notify.create({
    message: `Projet juridique ajouté aux modèles personnels`,
    type: 'primary',
  })
}

async function removeFromPersonalModels() {
  await userApi.removeSubProjectFromPersonalModels(props.subProject.value.legacyId)
  props.subProject.value.isPersonalModel = false
  Notify.create({
    message: `Projet juridique supprimé des modèles personnels`,
    type: 'primary',
  })
}

async function copyToProject(destinationProject) {
  console.log('destinationProject', destinationProject, props.subProject.value)
  $q.loading.show({
    message: "Nous copions votre projet juridique.\nMerci de patienter, cela peut prendre jusqu'à 1 minute.",
    boxClass: 'bg-grey-2 text-grey-9',
    spinnerColor: 'primary',
  })
  await userApi
    .createSubProjects({
      userProjectId: destinationProject.id,
      projectTemplateIds: [props.subProject.value.id],
    })
  $q.loading.hide()
}

function showCopyToProjectDialog() {
  const dialog = Dialog.create({
    component: CopySubProjectToProjectDialog,
    componentProps: {
      subProject: props.subProject,
      action: 'copy',
    },
  })
    .onOk(async (destinationProject) => {
      dialog.hide()

      await copyToProject(destinationProject)

      Notify.create({
        message: 'Projet juridique copié avec succès',
        type: 'primary',
      })
      // emit("cancel")
      dialogRef.value.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
} 
</script>

<template>
  <q-dialog ref="dialogRef">
    <BaseDialog title="Paramètres du projet juridique" :is-full-content="true" class="" :show-cancel-button="false"
      :is-dialog-loading="isLoading" @on-dialog-cancel="onDialogCancel()" @hide="onDialogHide()">
      <template #full-content>
        <div>
          <q-splitter v-model="splitterModel" style="height: 100%">
            <template v-slot:before>
              <q-tabs v-model="tab" vertical no-caps active-color="primary">
                <q-tab class="tab-header" name="general" label="Général" />
                <!-- <q-tab class="tab-header" name="export" label="Export" /> -->
                <q-tab class="tab-header" name="permissions" label="Permissions" />
              </q-tabs>
            </template>

            <template v-slot:after>
              <q-tab-panels v-model="tab" animated swipeable vertical transition-prev="jump-up" transition-next="jump-up">
                <q-tab-panel name="general">
                  <div style="margin-left: 16px;">
                    <!-- Nom : {{ subProject.value.name }} -->
                    <div class="row" style="text-align: middle;">
                      <!-- <span class="col-auto" style="margin-right: 8px">Nom : </span> -->
                      <q-input class="col" outlined dense v-model="name" label="Nom" />
                      <q-btn class="col-auto" style="margin-left: 8px; padding-left: 8px; padding-right: 8px" dense
                        label="Renommer" @click="renameSubProject()" color="primary" />
                    </div>
                  </div>
                  <div style="margin-left: 16px; margin-top: 16px;">
                    <a v-if="!isPersonalModel()" href="#" class="button-link" @click="addToPersonalModels">Ajouter
                      aux modèles personnels</a>
                    <a v-if="isPersonalModel()" href="#" class="button-link" @click="removeFromPersonalModels">Supprimer
                      des
                      modèles personnels</a>
                  </div>
                  <div style="margin-left: 16px; margin-top: 16px;">
                    <a href="#" class="button-link" @click="showCopyToProjectDialog">Copier
                      le projet juridique dans un autre dossier</a>
                  </div>
                  <div style="margin-left: 16px; margin-top: 16px;">
                    <a href="#" class="button-link" @click="exportProjectToDocuments">Exporter
                      le projet
                      juridique dans
                      les documents du dossier</a>
                  </div>

                </q-tab-panel>
                <q-tab-panel name="permissions">
                  <div style="margin: 20px;">
                    La gestion des permissions sera bientôt disponible.
                  </div>
                </q-tab-panel>
              </q-tab-panels>
            </template>
          </q-splitter>
        </div>
      </template>

      <template #actions>
        <!-- <q-btn flat label="Enregistrer" @click="save()" color="primary" /> -->
      </template>
    </BaseDialog>
  </q-dialog>
</template>

<style lang="scss" scoped>
.import-from-lop-button-link {
  padding-top: 10px;
  padding-left: 10px;
  margin-top: 20px;
  line-height: 4em;
}
</style>
