<script lang="ts" setup>
import draggable from 'vuedraggable'
import FileNode from './FileNode.vue'
import { Notify, Dialog, useQuasar } from 'quasar'
import RenameNodeDialog from './RenameNodeDialog.vue'
import DeleteNodeDialog from './DeleteNodeDialog.vue'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { ProjectKey } from '@/models/symbols'
import { ref, inject, watch, onMounted } from 'vue'
import { refreshFiles, updateLabel, orderParent, canMoveTo, moveNode, refreshFlatTree } from './DocumentsCommon'

interface Props {
  element: any
  treeRef: any
}

const props = withDefaults(defineProps<Props>(), {})

const userApi: UserApi = useApi()

const $q = useQuasar()

const project = inject(ProjectKey)
const documentTreeNodes = inject('documentTreeNodes')

// const dataTransferItems = inject("dataTransferItems");

const ticked = inject('ticked')
const documentTreeSelectedNode = inject('documentTreeSelectedNode')
const expandedNodes = inject('expandedNodes')
const viewType = inject('viewType')
const flatViewNodes = inject('flatViewNodes')
const currentDocumentTreeNodes = inject('currentDocumentTreeNodes')

const contextualMenuRef = ref(null)
const menuRef = ref(null)

async function renameNode(node, newName) {
  await userApi.renameDocumentTreeNode(project?.value.id, node.id, newName)
  updateLabel(node)
  orderParent(documentTreeNodes, node)
  refreshFlatTree(documentTreeNodes, flatViewNodes, viewType, currentDocumentTreeNodes)
  // refreshFiles(userApi, project, documentTreeNodes);
}

function showRenameDirectoryDialog(node) {
  const dialog = Dialog.create({
    component: RenameNodeDialog,
    componentProps: {
      node: node,
    },
  })
    .onOk((newName) => {
      node.name = newName
      renameNode(node, newName)
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

async function deleteDirectory(node) {
  await userApi.deleteDocumentTreeNode(project?.value.id, node.id)
  console.log('removing node from tree')
  if (node.parent) {
    const index = node.parent.children.indexOf(node)
    node.parent.children.splice(index, 1)
  } else {
    const index = documentTreeNodes.value[0].children.indexOf(node)
    documentTreeNodes.value[0].children.splice(index, 1)
  }

  console.log('resetting selected node')
  documentTreeSelectedNode.value = null
  refreshFlatTree(documentTreeNodes, flatViewNodes, viewType, currentDocumentTreeNodes)
  // refreshFiles(userApi, project, documentTreeNodes);
}

function showDeleteDirectoryModal(node) {
  const dialog = Dialog.create({
    component: DeleteNodeDialog,
    componentProps: {
      node: node,
    },
  })
    .onOk(() => {
      deleteDirectory(node)
      // onNodeSelected(undefined);
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

const group = ref('toto')
const drag = ref(false)
function updateValue() { }

function startDrag(evt, item) {
  evt.dataTransfer.dropEffect = 'move'
  evt.dataTransfer.effectAllowed = 'move'
  evt.dataTransfer.setData('itemId', item.id)
}

function getNode(nodes, nodeId) {
  for (let i = 0; i < nodes.length; ++i) {
    const currentNode = nodes[i]
    if (currentNode.id === nodeId) {
      return currentNode
    }
    const childNode = getNode(currentNode.children, nodeId)
    if (childNode !== undefined) {
      return childNode
    }
  }
  return undefined
}

function uploadFiles(event) {
  console.log('uploading files from a drag and drop')
  event.preventDefault()

  let items = event.dataTransfer.items
  for (let i = 0; i < items.length; i++) {
    let item = items[i].webkitGetAsEntry()
    console.log('item', item)
    dataTransferItems.value.push(item)
    // if (item) {
    //   scanFiles(item, undefined);
    // }
  }
}

async function onDrop(evt) {
  console.log('onDrop', evt)

  directoryDropZoneRef.value.classList.remove('directory-drop-zone-hover')
  // if (evt.dataTransfer.items) {
  //   uploadFiles(evt);
  //   return;
  // }

  const itemId = evt.dataTransfer.getData('itemId')
  const item = getNode(documentTreeNodes.value, itemId)

  if (!canMoveTo(item, props.element)) {
    return
  }

  if (ticked.value.includes(itemId)) {
    console.log('dropping multiple files')
    await userApi.moveDocumentTreeNodes(project?.value.id, ticked.value, props.element.id)
    await refreshFiles(userApi, project, documentTreeNodes)
  } else {
    console.log('dropping single file')
    // await userApi.moveDocumentTreeNode(
    //   project?.value.id,
    //   itemId,
    //   props.element.id
    // );
    await moveNode(project, userApi, documentTreeNodes, item, props.element.id, expandedNodes)
  }

  refreshFlatTree(documentTreeNodes, flatViewNodes, viewType, currentDocumentTreeNodes)

  ticked.value = []
}

let dragEventDate = 0

function onDragOver(event) {
  if (event.timeStamp - dragEventDate > 500) {
    props.treeRef.setExpanded(props.element.id, true)
  }

  // Needed for onDrop to be fired
  event.preventDefault()
}

const directoryDropZoneRef = ref(null)

function onDragEnter(event) {
  dragEventDate = event.timeStamp
  directoryDropZoneRef.value.classList.add('directory-drop-zone-hover')

  // Needed for onDrop to be fired
  event.preventDefault()
}

function onDragLeave(event) {
  directoryDropZoneRef.value.classList.remove('directory-drop-zone-hover')
}

function onMenuClick(event) {
  console.log('on menu click', event)
  event.preventDefault()
  documentTreeSelectedNode.value = props.element.id
}

function onAuxClick(event) {
  console.log('aux click', event)

  if (event.button == 2) {
    console.log('right click')
    onContextMenu(event)
  }
}

function onContextMenu(event) {
  console.log('context menu', documentTreeSelectedNode.value, props.element)
  event.preventDefault()
  documentTreeSelectedNode.value = props.element.id
  contextualMenuRef.value.show(event)
}

onMounted(() => {
  // console.log("filenode - onMounted");
  props.element.anchorElement = menuRef.value?.$el
})

const personalModels = inject("personalModels")

function isPersonalModel(element) {
  // console.log("personalModels directory node", personalModels.value, element)
  return personalModels.value.find((model) => model.documentTreeNodeId === element.id)
}


async function addToPersonalModels(element) {
  const newModel = await userApi.addDocumentTreeNodeToPersonalModels(element.id)
  personalModels.value.push(newModel)
  Notify.create({
    message: `Dossier ajouté aux modèles personnels`,
    type: 'primary',
  })
}

async function removeFromPersonalModels(element) {
  await userApi.removeDocumentTreeNodeFromPersonalModels(element.id)
  personalModels.value = personalModels.value.filter((model) => model.documentTreeNodeId !== element.id)
  Notify.create({
    message: `Dossier supprimé des modèles personnels`,
    type: 'primary',
  })
}

function getAllChildrenIds(node) {
  const result = []

  console.log('getAllChildrenIds', node)
  node.children.forEach((child) => {
    result.push(child.id)
    result.push(...getAllChildrenIds(child))
  })

  return result
}

async function duplicate(element) {
  console.log('duplicate', element)
  $q.loading.show({
    message: 'Veuillez patienter pendant la duplication...',
    boxClass: 'bg-grey-2 text-grey-9',
    spinnerColor: 'primary',
    delay: 200, // ms
  })

  const elementsToDuplicate = [element.id]
  elementsToDuplicate.push(...getAllChildrenIds(element))

  console.log("elementsToDuplicate", elementsToDuplicate)

  await userApi.duplicateDocumentTreeNodes(project?.value.id, elementsToDuplicate)
  await refreshFiles(userApi, project, documentTreeNodes)
  refreshFlatTree(documentTreeNodes, flatViewNodes, viewType, currentDocumentTreeNodes)
  $q.loading.hide()
  Notify.create({
    message: `Duplication réalisée avec succès`,
    type: 'primary',
  })
}
</script>

<template>
  <div class="row no-wrap justify-start content-start full-width items-center drag-el drop-zone tree-node" style=""
    draggable="true" ref="directoryDropZoneRef" @dragstart="startDrag($event, element)" @drop="onDrop($event)"
    @dragenter="onDragEnter($event)" @dragleave="onDragLeave($event)" @dragover="onDragOver($event)"
    @contextmenu="onContextMenu" @auxclick="onAuxClick">
    <q-menu touch-position context-menu ref="contextualMenuRef">
      <q-list dense style="min-width: 100px">
        <q-item clickable v-close-popup @click="showRenameDirectoryDialog(element)">
          <q-item-section>
            <q-item-label>Renommer</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable v-close-popup @click="duplicate(element)">
          <q-item-section>
            <q-item-label>Dupliquer</q-item-label>
          </q-item-section>
        </q-item>
        <!-- <q-item clickable v-close-popup @click="showPermissionsDialog(element)">
          <q-item-section>
            <q-item-label>Permissions</q-item-label>
          </q-item-section>
        </q-item> -->
        <q-item clickable v-close-popup @click="showDeleteDirectoryModal(element)">
          <q-item-section>
            <q-item-label text-color="negative">Supprimer</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-menu>

    <div class="col-auto" style="overflow: hidden">
      <q-icon :name="element.icon || 'description'" size="24px" class="q-mr-sm"
        style="padding-right: 0px; color: #434440" />
    </div>
    <div class="col-shrink" style="overflow: hidden">
      <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
        {{ element.label }}

        <q-tooltip>
          {{ element.label }}
        </q-tooltip>
      </div>
    </div>

    <!-- space filler -->
    <div class="col" style="overflow: hidden"></div>

    <div class="col-auto" style="overflow: hidden">
      <span class="row no-wrap items-center" style="text-align: center; line-height: 36px">
        <q-icon v-if="isPersonalModel(element)" name="o_snippet_folder" size="18px" class="q-ml-sm" />
        <q-btn-dropdown flat rounded dropdown-icon="more_vert" style="padding-left: 8px; padding-right: 8px" clickable
          @click.stop="onMenuClick" ref="menuRef">
          <q-list dense>
            <q-item clickable v-close-popup @click="showRenameDirectoryDialog(element)">
              <q-item-section>
                <q-item-label>Renommer</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="duplicate(element)">
              <q-item-section>
                <q-item-label>Dupliquer</q-item-label>
              </q-item-section>
            </q-item>
            <q-item v-if="!isPersonalModel(element)" clickable v-close-popup @click="addToPersonalModels(element)">
              <q-item-section>
                <q-item-label>Ajouter aux modèles personnels</q-item-label>
              </q-item-section>
            </q-item>
            <q-item v-if="isPersonalModel(element)" clickable v-close-popup @click="removeFromPersonalModels(element)">
              <q-item-section>
                <q-item-label>Supprimer des modèles personnels</q-item-label>
              </q-item-section>
            </q-item>
            <!-- <q-item clickable v-close-popup @click="showPermissionsDialog(element)">
              <q-item-section>
                <q-item-label>Permissions</q-item-label>
              </q-item-section>
            </q-item> -->
            <q-item clickable v-close-popup @click="showDeleteDirectoryModal(element)">
              <q-item-section>
                <q-item-label text-color="negative">Supprimer</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.file-name {
  font-size: 16px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // border: 1px solid;
}

.file-date {
  color: #2c2c2c;
}

.drop-zone {
  // margin-bottom: 10px;
  // padding: 10px;
}

.drag-el {
  // margin-bottom: 10px;
  // padding: 5px;
}

.directory-drop-zone-hover {
  background-color: lightgrey;
}
</style>
