<script async setup lang="ts">
import { inject } from 'vue'
import { useStore } from '@/store/store'
import { useRouter } from 'vue-router'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'

// import ProjectsTable from "@/components/projects/management/ProjectsTable.vue";
// import TasksTable from "@/components/projects/management/TasksTable.vue";

const store = useStore()
const router = useRouter()
const userApi: UserApi = useApi()

store.commit('setPageTitle', 'Accueil')

const favorites = inject('favorites')

const recentProjects = await userApi.getRecentlyViewedProjects(1, '', 5)


async function goToProject(project) {
  const client = await userApi.getClientForProject(project.id)

  store.state.user.clientId = client.id
  store.state.currentClient = client.name
  router.push({
    name: 'clients/client/projects/project',
    params: {
      clientId: store.state.user.clientId,
      projectId: project.id,
    },
  })
}

async function goToClientFavorite(favorite) {
  store.state.user.clientId = favorite.objectId
  const client = await userApi.getClient(favorite.objectId)
  store.state.currentClient = client
  router.push({
    name: 'clients/client/projects',
    params: { clientId: favorite.objectId },
  })
}

async function goToUserProjectFavorite(favorite) {
  if (store.state.user.portal === 'CLIENT') {
    router.push({
      name: 'projects/project',
      params: {
        projectId: favorite.userProjectId,
      },
    })
  } else {
    // const project = await userApi.getProject(favorite.objectId);
    // console.log("project", project)
    const client = await userApi.getClientForProject(favorite.objectId)
    store.state.user.clientId = client.id
    store.state.currentClient = client
    router.push({
      name: 'clients/client/projects/project',
      params: {
        clientId: client.id,
        projectId: favorite.objectId,
      },
    })
  }
}
</script>

<template>
  <q-page class="page">
    <q-scroll-area style="height: 100vh; width: 100%; margin-left: 8px;">
      <h4>Bienvenue dans votre espace de dossiers juridiques</h4>

      <div style="padding: 8px; font-weight: 500; font-size: 18px; color: #4068c8; margin-top: 0px;">Dossiers récents
      </div>
      <div v-for="(recentProject, index) in recentProjects.results" style="cursor: pointer"
        @click="goToProject(recentProject)">
        <div class="row no-wrap quick_access_item">
          <div class="col-auto" style="margin-left: 8px; margin-bottom: 16px; font-size: 16px;">
            {{ recentProject.name }}</div>
        </div>
      </div>

      <div style="padding: 8px; font-weight: 500; font-size: 18px; color: #4068c8; margin-top: 8px;">Clients favoris</div>
      <div v-for="(clientFavorite, index) in favorites.clients" style="cursor: pointer"
        @click="goToClientFavorite(clientFavorite)">
        <div class="row no-wrap quick_access_item">
          <div class="col-auto" style="margin-left: 8px; margin-bottom: 16px; font-size: 16px;">
            {{ clientFavorite.name }}</div>
        </div>
      </div>

      <div style="padding: 8px; font-weight: 500; font-size: 18px; color: #4068c8; margin-top: 8px;">Dossiers favoris
      </div>
      <div v-for="(userProjectFavorite, index) in favorites.userProjects" style="cursor: pointer"
        @click="goToUserProjectFavorite(userProjectFavorite)">
        <div class="row no-wrap quick_access_item">
          <div class="col-auto" style="margin-left: 8px; margin-bottom: 16px; font-size: 16px;">
            {{ userProjectFavorite.name }}</div>
        </div>
      </div>
    </q-scroll-area>
  </q-page>
</template>

<style lang="scss" scoped>
h5 {
  margin-bottom: 10px;
}

h4 {
  font-size: 22px;
  margin-top: 8px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 8px;
  line-height: 1.5em;
}

h5 {
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.search-input {
  margin-left: 10px;
  margin-right: 10px;
}

.page {
  padding: 0px;
}
</style>
